import {
  lowTrafficVolumeVsCompetitors,
  negativeBrandVeloctiyVsPrevious12Months,
  negativeMarketSearchVsPrevious12Months,
  negativeMarketTrafficVsPrevious12Months
} from './insights';
import { max, sum } from 'lodash';

export const calculateFlags = ({
  brandSearchChartData,
  followersVsEngagementsChartData,
  overviewChartData,
  searchTrendsChartData,
  socialAudienceChartData,
  socialbladeScore,
  summaryData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  trafficTrendsChartData,
  trustpilotScore,
  websiteEngagementScore,
  siteOptimisationChartData
}) => {
  const flags = [];
  let currentUrl = window.location.href;
  currentUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
  const marketUrl = `market`;
  const performanceUrl = `performance`;
  const brandUrl = `brand`;
  const socialUrl = `social`;
  const technologyUrl = `technology`;

  const searchGrowthChart = marketUrl + '#search-growth-chart';
  const trafficGrowthChart = marketUrl + '#traffic-growth-chart';

  const trafficShareChart = performanceUrl + '#traffic-share-chart';
  const marketingEfficiencyChart = performanceUrl + '#marketing-efficiency-chart';
  const engagementStrengthChart = performanceUrl + '#engagement-strength-chart';
  const trafficTrendChart = performanceUrl + '#traffic-trend-chart';

  const brandCustomerExperienceChart = brandUrl + '#brand-customer-experience';
  const brandScaleAndVelocityChart = brandUrl + '#brand-scale-and-velocity-chart';

  const socialAudienceChart = socialUrl + '#social-audience-chart';
  const socialEngagementChart = socialUrl + '#social-engagement-chart';

  const technologyMaturityChart = technologyUrl + '#technology-maturity-chart';
  const technologyCapabilityDimensions = technologyUrl + '#capability-dimensions';

  if (
    searchTrendsChartData &&
    searchTrendsChartData.insights &&
    searchTrendsChartData.insights.cagr !== undefined
  ) {
    const marketSearchCagr = (+searchTrendsChartData.insights.cagr).toFixed(2);
    if (marketSearchCagr < 0) {
      flags.push({
        type: 'Market',
        title: 'Negative Search Trend (4 Years)',
        description: `Search demand for this market has fallen over the four year period, with a negative growth rate of ${marketSearchCagr}%. This could indicate a trend of declining interest for the target and competitor's proposition.`,
        questions: 'What trends could have contributed to a decline in searches?',
        chart: searchGrowthChart
      });
    }
  }

  if (
    searchTrendsChartData &&
    searchTrendsChartData.series &&
    searchTrendsChartData.series.length
  ) {
    const yValues = searchTrendsChartData.series[0].data.map((d) => d.y);

    const negativeMarketSearchVsPrevious12MonthsValue =
      negativeMarketSearchVsPrevious12Months(yValues);

    const last12Sum = sum(yValues.slice(-12)); // y4
    const previous12Sum = sum(yValues.slice(-24, -12)); // y3
    const Y4vsY3 = (((last12Sum - previous12Sum) / previous12Sum) * 100).toFixed(2);

    if (negativeMarketSearchVsPrevious12MonthsValue) {
      flags.push({
        type: 'Market',
        title: 'Negative Market Search vs Previous 12 months',
        description: `Search demand for this market has declined by ${Y4vsY3}% in the last 12 months, compared to the previous 12 months. This could indicate a recent decline in interest for the target and competitor's proposition.`,
        questions:
          'Have there been any recent changes that could have caused a decline in the volume searches for this market?',
        chart: searchGrowthChart
      });
    }
  }

  // if (
  //   trafficTrendsChartData &&
  //   trafficTrendsChartData.insights &&
  //   trafficTrendsChartData.insights.cagr !== undefined
  // ) {
  //   const marketTrafficCagr = (+trafficTrendsChartData.insights.cagr).toFixed(0);
  //   if (marketTrafficCagr < 0) {
  //     flags.push({
  //       type: 'Market',
  //       title: 'Negative Market Traffic Growth (4 Year)',
  //       description: `The volume of website visits for the competitor set has fallen over the 4 year period, with a negative growth rate of ${marketTrafficCagr}%. This could indicate a trend of declining demand or a decreasing ability of the competitor set to capture online demand.`,
  //       questions:
  //         'Does the decline in website visits reflect falling demand? \n Are there any trends that may have led to falling demand?',
  //       chart: trafficGrowthChart
  //     });
  //   }
  // }

  if (
    trafficTrendsChartData &&
    trafficTrendsChartData.series &&
    trafficTrendsChartData.series.length
  ) {
    const yValues = trafficTrendsChartData.series[0].data;
    const negativeMarketTrafficVsPrevious12MonthsValue =
      negativeMarketTrafficVsPrevious12Months(yValues);

    let last12Sum;
    if (trafficTrendsChartData.insights.monthsOfData === 24) {
      // yValues.length = 24
      last12Sum = sum(yValues.slice(-12)); // y4
    } else {
      // yValues.length = 16
      last12Sum = sum(yValues.slice(-4)); // y4
    }

    let previous12Sum;
    if (trafficTrendsChartData.insights.monthsOfData === 24) {
      previous12Sum = sum(yValues.slice(0, 12)); // y3
    } else {
      previous12Sum = sum(yValues.slice(-8, -4)); // y3
    }

    const Y4vsY3 = (((last12Sum - previous12Sum) / previous12Sum) * 100).toFixed(2);

    if (negativeMarketTrafficVsPrevious12MonthsValue) {
      flags.push({
        type: 'Market',
        title: 'Negative Market Traffic Growth',
        description: `The total volume of website visits has declined by ${Y4vsY3}%. This could indicate declining online interest and lower demand for the competitor set.`,
        questions:
          'Does the decline in website visits reflect falling demand?\n Are there any trends that may have led to falling demand?',
        chart: trafficGrowthChart
      });
    }
  }

  // NEW FLAG
  // const cagrForSearchGrowth = (+searchTrendsChartData.insights.cagr).toFixed(2);
  // const cagrForTrafficGrowth = (+trafficTrendsChartData.insights.cagr).toFixed(2);
  // const rateOfTrafficGrowthSignificantlyBelowGrowthOfSearchDemand =
  //   cagrForSearchGrowth > 0 && cagrForSearchGrowth > cagrForTrafficGrowth * 1.05;
  // if (rateOfTrafficGrowthSignificantlyBelowGrowthOfSearchDemand) {
  //   flags.push({
  //     type: 'Market',
  //     title: 'Rate of traffic growth significantly below growth of search demand',
  //     description: `Online demand for the market is growing significantly faster than traffic growth ${cagrForSearchGrowth}% vs ${cagrForTrafficGrowth}% respectively). This indicates that while market demand is growing, the ability for the competitor set to capture demand is decreasing. This may highlight a missed opportunity to grow with the market.`,
  //     questions:
  //       'Are there any competitors from outside of the competitor set that are capturing the growth in online searches?',
  //     chart: trafficShareChart
  //   });
  // }

  if (overviewChartData && overviewChartData.series && overviewChartData.series.length) {
    const lowTrafficVolumeVsCompetitorsValue = lowTrafficVolumeVsCompetitors(
      overviewChartData.series[0].data
    );
    if (lowTrafficVolumeVsCompetitorsValue) {
      flags.push({
        type: 'Performance',
        title: 'Low volume of traffic vs Competitors',
        description:
          'The target has low traffic share - less than half of the leader. This may indicate low revenue share in the market. There may be an opportunity to capture incremental traffic and market share.',
        questions:
          "What is the maturity level and size of the target compared to the leader of the market? \n What is the purchasing ability on the targets and competitors' website?",
        chart: trafficShareChart
      });
    }
  }

  const targetDecliningTraffic = trafficByMonthChartData.insights.targetDecliningTraffic;
  if (targetDecliningTraffic) {
    flags.push({
      type: 'Performance',
      title: `Declining traffic`,
      description: `Visits to the target's website have declined by ${trafficByMonthChartData.insights.L12mVp12m}% over the last ${trafficByMonthChartData.insights.monthsOfData} months. This may indicate lower demand or less effective marketing activities. We recommend a review of the target's acquisition strategy as this may reveal ineffective content or incorrect audience targeting.`,
      questions: `Does the decline in traffic correlate with a decline in revenue? \n What was the target's marketing strategy over the past ${trafficByMonthChartData.insights.monthsOfData} months?`,
      chart: trafficTrendChart
    });
  }

  const targetGrowthRateLowerThanFastestGrowingCompetitorValue =
    trafficByMonthChartData.insights.targetGrowthRateLowerThanFastestGrowingCompetitor;
  if (targetGrowthRateLowerThanFastestGrowingCompetitorValue) {
    flags.push({
      type: 'Performance',
      title: 'Slow traffic growth rate vs top competitor',
      description: `The target's traffic growth is significantly lower than that of the fastest growing competitor over the last ${trafficByMonthChartData.insights.monthsOfData} months. This may indicate lower demand or less effective marketing activities. We recommend a review of the competitor's acquisition strategy as this may indicate opportunities for the target to capture incremental traffic.`,
      questions:
        'How do the competitors’ marketing strategies differ from the target’s? \n What could be the reasons behind slow traffic growth? (E.g. new product launches, campaigns, PR)',
      chart: trafficTrendChart
    });
  }
  const freeShare = trafficSourceBreakdownChartData.insights.freeShare;
  if (freeShare < 0.6) {
    flags.push({
      type: 'Performance',
      title: 'High dependance on paid traffic',
      description:
        "The target's has a high share of paid traffic. High dependence on paid traffic may indicate low brand awareness and underinvestment in brand or search engine optimisation. As the business scales, we recommend investing in more cost effective channels, to apply downward pressure on the Cost of Acquiring Customers.",
      questions:
        "What is the target's traffic aquisition strategy and how do paid channels, such as Paid Search, fit within this strategy? \n What is the target's strategy evolution? \n What attribution model does the target use to track ROI from paid channels?",
      chart: marketingEfficiencyChart
    });
  }
  if (websiteEngagementScore < 60) {
    flags.push({
      type: 'Performance',
      title: 'Low website engagement vs top competitor',
      description:
        "The website has low engagement strength. This suggests poor user experience or that the marketing strategy is attracting the wrong audience. To improve the Bounce Rate and Pages Visited we recommend a review of the landing page content to ensure content relevancy to the incoming audience. To improve Visit Duration, we recommend a review of the target's marketing strategy, with a particular focus on targeting.",
      questions:
        'Which area on the website has the most website drop offs? \n What is the strategy for improving engagement on the website? \n Is the target currently doing any conversion rate optimisation? \n Has the target run any A/B tests in the past? If so, what were the details and results',
      chart: engagementStrengthChart
    });
  }

  // if (+summaryData.insights.overallScore <= 33) {
  //   flags.push({
  //     type: 'Performance',
  //     title: 'Low average score',
  //     description:
  //       'The target asset has a low average score across all areas this may indicate systemic performance issues that warrant further investigation.'
  //   });
  // }

  // if (brandSearchChartData && brandSearchChartData.series && brandSearchChartData.series.length) {
  //   const negativeBrandVeloctiyVsPrevious12MonthsValue = negativeBrandVeloctiyVsPrevious12Months(
  //     brandSearchChartData.series[0].data.map((d) => d.y)
  //   );
  //   if (negativeBrandVeloctiyVsPrevious12MonthsValue) {
  //     flags.push({
  //       type: 'Brand',
  //       title: 'Negative Brand Velocity vs the last 12 months',
  //       description:
  //         'Reduced brand velocity may indicate declining effectiveness of advertising and other brand building activities. Alternatively, this may be due to decreased demand. This can be cross referenced with the Market Search Growth chart.',
  //       metadata: {
  //         'brandSearchChartData.insights': brandSearchChartData.insights
  //       }
  //     });
  //   }
  // }

  if (
    brandSearchChartData &&
    brandSearchChartData.insights &&
    brandSearchChartData.insights.scores &&
    brandSearchChartData.insights.scores.length
  ) {
    const brandCagr = (+brandSearchChartData.insights.scores[0].cagr * 100).toFixed(2);
    if (brandCagr < 0) {
      flags.push({
        type: 'Brand',
        title: 'Falling brand searches (4 Year)',
        description: `The number of brand searches for the target has declined by ${brandCagr}% over the 4 year period. This suggests a decrease in demand or declining brand awareness.`,
        metadata: {
          cagr: brandCagr,
          'brandSearchChartData.insights': brandSearchChartData.insights
        },
        questions:
          "Has the decline in brand searches reflected a decline in demand? \n How does brand awareness fit into the target's strategy?",
        chart: brandScaleAndVelocityChart
      });
    }
  }

  const brandTargetGrowthRateLowerThanFastestGrowingCompetitorValue =
    brandSearchChartData.insights.targetGrowthRateLowerThanFastestGrowingCompetitor;
  if (
    brandSearchChartData &&
    brandSearchChartData.insights &&
    brandSearchChartData.insights.scores &&
    brandSearchChartData.insights.scores.length &&
    brandTargetGrowthRateLowerThanFastestGrowingCompetitorValue
  ) {
    flags.push({
      type: 'Brand',
      title: 'Growth rate of brand searches lower than the top competitor',
      description:
        "The growth rate of brand searches for the target is lower than that of the fastest growing competitor. This indicates that the target has less effective advertising and brand building methods. We recommend an investgation of the competitor's brand building methods to find opportunities to improve the target's brand awareness.",
      metadata: {
        'brandSearchChartData.insights': brandSearchChartData.insights
      },
      questions:
        "What strategy does the competitor use to drive brand awareness? \n How does that differ to the target's strategy?",
      chart: brandScaleAndVelocityChart
    });
  }

  if (brandSearchChartData && brandSearchChartData.series && brandSearchChartData.series.length) {
    const lowBrandScaleVsLargestCompetitor =
      brandSearchChartData.series[0].last12Months <
      0.5 * max(brandSearchChartData.series.slice(1).map((d) => d.last12Months));
    if (
      brandSearchChartData &&
      brandSearchChartData.series &&
      brandSearchChartData.series.length &&
      lowBrandScaleVsLargestCompetitor
    ) {
      flags.push({
        type: 'Brand',
        title: 'Low brand searches Vs largest competitor',
        description:
          "The target's volume of brand searches is low compared to the largest competitor. This may indicate low brand awareness and therefore an inefficient purchasing funnel. This is likely due to less effective brand building strategies.",
        metadata: {
          'brandSearchChartData.series[0].last12Months':
            brandSearchChartData.series[0].last12Months,
          '(0.75 * max(brandSearchChartData.series.slice(1).map(d => d.last12Months)))':
            0.75 * max(brandSearchChartData.series.slice(1).map((d) => d.last12Months)),
          'series including last12Months': brandSearchChartData.series
        },
        questions:
          'How mature is the brand compared to its competitors? \n Are there sub-brands within the website? \n What are the trends for the sub brands?',
        chart: brandScaleAndVelocityChart
      });
    }
  }

  const lowTrustpilotScore = trustpilotScore !== undefined && trustpilotScore < 60;
  if (trustpilotScore && lowTrustpilotScore) {
    flags.push({
      type: 'Brand',
      title: 'Low Trustpilot Score',
      description:
        'A low Trustpilot score reflects low customer satisfaction. Social proof is a key consideration factor in the customer purchase journey and therefore a low score and negative reviews may deter new customers from purchasing.',
      metadata: {
        trustpilotScore
      },
      questions:
        'What are the key themes/ topics for negative reviews? \n How is negative feedback managed? (Both internally and externally)',
      chart: brandCustomerExperienceChart
    });
  }

  const transformedSocialData = socialAudienceChartData?.data?.map((loopItem) => {
    const item = loopItem.socialbladeData;
    const facebookLikes =
      !item?.facebook || item?.facebook.error ? 0 : item?.facebook.statistics.total.likes;
    const twitterFollowers =
      !item?.twitter || item?.twitter.error ? 0 : item?.twitter.statistics.total.followers;
    const instagramFollowers =
      !item?.instagram || item?.instagram.error ? 0 : item?.instagram.statistics.total.followers;

    return {
      ...item,
      totalFollowers: facebookLikes + twitterFollowers + instagramFollowers
    };
  });

  const targetTotalFollowers = transformedSocialData[0].totalFollowers;
  const maxTotalFollowers = max(transformedSocialData.map((item) => item.totalFollowers));
  const lowSocialAudience = targetTotalFollowers < 0.33 * maxTotalFollowers;

  if (lowSocialAudience && socialbladeScore !== null) {
    flags.push({
      type: 'Social',
      title: 'Low Social Audience',
      description:
        "The target has a low social media following across the core platforms. We recommend a review of the target and competitors' social media strategy as there may be opportunities to build and foster online communities to drive incremental revenue.",
      metadata: {
        socialbladeScore
      },
      questions:
        "Is social media a key acquisition channel in this market? \n Where does social media fit into the target's marketing and brand strategy? \n What are the key platforms used in the market?",
      chart: socialAudienceChart
    });
  }

  const lowSocialEngagement = followersVsEngagementsChartData.insights.lowSocialEngagement;
  if (lowSocialEngagement && socialbladeScore !== null) {
    flags.push({
      type: 'Social',
      title: 'Low Social Engagement',
      metadata: {
        lowSocialEngagement
      },
      description:
        "The target's content on Facebook and Instagram achieves less engagement than that of the competitors. We recommend investigating the target's content strategy to find opportunities to improve engagement and thereby drive brand awareness, brand sentiment and incremental revenue.",
      questions:
        'Is the target engaging in two-way engagement, beyond posting? \n What is the response rate? (Replying to comments and talking to customers in DMs). \n What is the targets content strategy and its evolution?',
      chart: socialEngagementChart
    });
  }

  if (
    siteOptimisationChartData &&
    siteOptimisationChartData.data &&
    siteOptimisationChartData.data.length
  ) {
    const securityImprovementNeeded = siteOptimisationChartData.data[0].security < 60;
    if (securityImprovementNeeded) {
      flags.push({
        type: 'Technology',
        title: 'Improve site security',
        description:
          'The target’s site has 1 or more detected security vulnerabilities that require attention. Such issues can compromise customer privacy and harm brand trust. Execute an audit using Google Lighthouse for an inventory of the issues.',
        metadata: {
          siteOptimisationChartData
        },
        questions:
          'Does the company regularly audit website security?\n Does the company have a designated owner for cyber security?',
        chart: technologyCapabilityDimensions
      });
    }

    const speedImprovementNeeded = siteOptimisationChartData.data[0].performance < 60;
    if (speedImprovementNeeded) {
      flags.push({
        type: 'Technology',
        title: 'Improve site speed',
        description:
          'The target’s site speed is below a good level. The speed score is calibrated using Google’s Lighthouse service which analyses millions of sites across the web. Improving this score is expected to lead to better visitor engagement and conversion.',
        metadata: {
          siteOptimisationChartData
        },
        questions:
          'Does the company regularly audit website performance and take action? \n Does the company believe site performance is important to visitor engagement and conversion? \n What is preventing improvement? Technology platform limitations or ability of IT staff?',
        chart: technologyCapabilityDimensions
      });
    }

    const accessibilityImprovementNeeded = siteOptimisationChartData.data[0].accessibility < 60;
    if (accessibilityImprovementNeeded) {
      flags.push({
        type: 'Technology',
        title: 'Improve site accessibility',
        description:
          'The target’s site accessibility is below a good level. The accessibility score is calibrated using Google’s Lighthouse service which analyses millions of sites across the web. Improving this score is expected to lead to better visitor engagement and conversion.',
        metadata: {
          siteOptimisationChartData
        },
        questions:
          'Does the company regularly audit website accessibility and take action? \n Does the company believe site accessibility is important to visitor engagement and conversion? \n What is preventing improvement? Technology platform limitations or ability of IT staff?',
        chart: technologyCapabilityDimensions
      });
    }

    const seoImprovementNeeded = siteOptimisationChartData.data[0].seo < 60;
    if (seoImprovementNeeded) {
      flags.push({
        type: 'Technology',
        title: 'Improve technical search engine optimization (SEO) score',
        description:
          'The target’s technical SEO score is below a go od level. The SEO score is calibrated using Google’s Lighthouse service which analyses millions of sites across the web. Improving this score is expected to lead to better organic search performance.',
        metadata: {
          siteOptimisationChartData
        },
        questions:
          'Does the company regularly audit website SEO optimisation and take action?\n Does the company believe SEO is important to its marketing strategy?\n What is preventing improvement? Technology platform limitations or ability of IT and marketing staff?',
        chart: technologyCapabilityDimensions
      });
    }
  }

  return flags;
};
