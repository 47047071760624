import React from 'react';
import isArrayEmpty from '../../utils/isArrayEmpty';

const TrafficSourceSelect = ({
  classes,
  trafficSourceChartType,
  setTrafficSourceChartType,
  mobileTrafficSourceBreakdownChartData
}) => {
  return (
    <div className={classes.platformSelectContainer}>
      <select
        value={trafficSourceChartType}
        onChange={(e) => setTrafficSourceChartType(e.target.value)}>
        {!isArrayEmpty(mobileTrafficSourceBreakdownChartData.series) && (
          <option value="combinedTrafficVolumes">All</option>
        )}
        <option value="trafficSourceVolumes">Desktop</option>
        {!isArrayEmpty(mobileTrafficSourceBreakdownChartData.series) && (
          <option value="mobileTrafficVolumes">Mobile</option>
        )}
      </select>
    </div>
  );
};

export default TrafficSourceSelect;
