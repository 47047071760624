import {
  Grid,
  Paper,
  makeStyles,
  Chip,
  Button,
  IconButton,
  Container,
  Box
} from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { AuthTokenContext } from '../AuthenticationWrapper';
import * as fillTemplate from 'es6-dynamic-template';
import { useCMS } from '../../api';
import ReactMarkdown from 'react-markdown';
import Loading from '../Loading';

const PageSummary = ({ project, tab, content, cta }) => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    companyHeaderContainer: {
      backgroundColor: '#FFFFFF',
      padding: '30px 0px',
      borderRadius: '20px'
    },
    companyHeaderInformationContainer: {
      padding: '0px 30px 0px 30px',
      color: '#7A8199'
    },
    companyCardInformationContainer: {
      padding: '0px 0px 0px 30px',
      color: '#7A8199'
    },
    companyHeaderButton: {
      textTransform: 'lowercase',
      backgroundColor: '#EFEDF9',
      color: '#3C21BA'
    },
    companyCardButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    companyCardButton: {
      backgroundColor: '#EFEDF9',
      color: '#3C21BA',
      borderRadius: '4px',
      marginLeft: '10px',
      height: '30px',
      cursor: 'pointer !important'
    },
    companyInformationNameHeading: {
      color: '#3C21BA',
      width: '100%'
    },
    chipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    chipCardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'start',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    chip: {
      backgroundColor: 'inherit',
      color: '#7A8199'
    },
    cardChip: {
      backgroundColor: 'inherit',
      color: '#7A8199',
      margin: '0px'
    },
    companyInformationHeaderLogo: {
      margin: '60px auto',
      width: '100%'
    },
    companyInformationCardLogoContainer: {
      margin: '0px auto',
      paddingBottom: '20px',
      borderBottom: '1px solid #EBE8F8'
    },
    companyInformationHeaderLogoContainer: {
      margin: 'auto 0'
    },
    companyImgContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto'
    },
    warningIcon: {
      fill: '#ffda00'
    },
    flag: {
      marginRight: 10,
      height: 16
    },
    content: {
      color: 'black'
    }
  }));
  const classes = useStyles();
  const cms = useCMS();

  const [countries, setCountries] = useState();
  const auth = useContext(AuthTokenContext);

  useEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_URL}/api/countries`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setCountries(response.data);
      });
  }, []);

  const templateString = cms && cms.data && cms?.data[content]?.content;

  const getTemplateContent = () => {
    return fillTemplate(templateString, {});
  };

  if (!countries || countries.length === 0) {
    return <Loading />;
  }

  const country = countries[0];
  const projectCountry = countries.find((x) => x.code === project?.countries[0]);

  return (
    <Grid item md={12} sm={12}>
      <Paper elevation={0} className={classes.companyHeaderContainer}>
        <Grid container direction="row">
          <Grid item md={12} className={classes.companyHeaderInformationContainer}>
            <Grid container direction="row">
              <Grid item md={4}>
                <h1 className={classes.companyInformationNameHeading}>{tab}</h1>
              </Grid>

              <Grid item md={8}>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ width: 'auto' }}>
                    {projectCountry && (
                      <>
                        <Grid className={classes.flag} item>
                          <img
                            width={16}
                            height={16}
                            src={`/img/flags/${projectCountry?.icon}.svg`}
                          />
                        </Grid>
                        <Grid className={classes.content} item>
                          {projectCountry?.name}
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ width: 'auto', marginLeft: '20px' }}>
                    {cta}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={12}>
                <ReactMarkdown>
                  {cms && cms.data && cms.data[content] && getTemplateContent()}
                </ReactMarkdown>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PageSummary;
