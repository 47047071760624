import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Chart from 'react-apexcharts';
import isArrayEmpty from '../../utils/isArrayEmpty';
import Loading from '../Loading';
import '../CompetitorAnalysis/Overview.css';
import { useAuth } from '../../hooks/use-auth';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';

const useStyles = makeStyles(() => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function MobileTrafficSourceBreakdown({
  project,
  dataFetched,
  isMobileTrafficSourceVolumesLoading,
  mobileTrafficVolumesData,
  mobileTrafficSourceBreakdownChartData,
  organisation
}) {
  // PERFORMANCE - MARKETING EFFICIENCY CHART
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;

  const classes = useStyles();

  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    if (isMobileTrafficSourceVolumesLoading) return;
    // TODO: need to be able to swap between mobile and normal data
    if (isArrayEmpty(mobileTrafficVolumesData) || !mobileTrafficVolumesData) return;

    const { categories, series } = mobileTrafficSourceBreakdownChartData;

    setCategories(categories);
    setSeries(series);

    setOptions({
      dataLabels: {
        formatter: function (value) {
          return Math.round(value) + '%';
        }
      },
      tooltip: {
        theme: 'light',
        x: {
          show: true
        },
        y: {
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          }
        }
      },
      xaxis: {
        categories,
        labels: {
          formatter: (value) => {
            return value + '%';
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      colors: ['#4C1E8B', '#3CC0FF', '#FF9E45', '#F67DFF', '#877DFF', '#FF7D9C', '#03CEA4'],
      chart: {
        stacked: true,
        stackType: '100%',
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      }
    });
  }, [mobileTrafficSourceBreakdownChartData]);

  if (isMobileTrafficSourceVolumesLoading) return <Loading />;

  if (dataFetched && !isArrayEmpty(mobileTrafficVolumesData)) {
    return (
      <Grid>
        {options && series && (
          <Paper className={classes.graphContainer} elevation={0} id="marketing-efficiency-chart">
            {orgType === 'Lite' && <OverlayUpgradeButton />}
            <Chart
              className="marketing-efficiency"
              options={options}
              series={series}
              type="bar"
              width="100%"
            />
          </Paper>
        )}
      </Grid>
    );
  }

  return (
    <Paper className={classes.graphContainer} elevation={0} id="marketing-efficiency-chart">
      <Skeleton width="50%" />
      <Skeleton width="20%" />
      <Skeleton width="30%" />
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Paper>
  );
}
