import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Logout from '../components/Logout';
import { Button, Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: '1px solid #EBE8F8',
    height: '80px',
    paddingLeft: '20px',
    overflow: 'hidden',
    fontSize: '16px',
    '& p': {
      margin: 0
    }
  },
  metadata: {
    padding: '10px'
  },
  name: {
    color: '#3C21BA'
  },
  company: {
    color: '#7A8199'
  },
  chipContainer: {
    marginLeft: '25px',
    marginRight: '15px'
  },
  chip: {
    backgroundColor: '#CDF5ED'
  },
  logoutContainer: {
    padding: '0 !important',
    borderLeft: '1px solid #EBE8F8'
  },
  upgradeButtonContainer: {
    padding: '0 20px !important',
    borderLeft: '1px solid #EBE8F8',
    height: '80px',
    display: 'flex',
    justifyContent: 'center'
  },
  upgradeLink: {
    display: 'block'
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    textTransform: 'none'
  }
}));

export default function LoggedInUser({ user, metadata, tier }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Grid item className={classes.metadata}>
          <p className={classes.name}>{metadata.full_name}</p>
          <p className={classes.company}>{metadata.company}</p>
        </Grid>

        <Grid item className={classes.chipContainer}>
          {/*<Avatar alt={metadata.full_name} src={user.picture} />*/}
          <Chip className={classes.chip} label={tier}></Chip>
        </Grid>

        <Grid item className={classes.upgradeButtonContainer} style={{ alignItems: 'center' }}>
          <Link to="/upgrade" className={classes.upgradeLink}>
            <Button
              component="div"
              className={classes.button}
              variant="contained"
              color="primary"
              disableElevation>
              Upgrade
            </Button>
          </Link>
        </Grid>
        <Grid item className={classes.logoutContainer}>
          <Logout />
        </Grid>
      </Grid>
    </div>
  );
}
