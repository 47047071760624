export const mobileTrafficBySourceGroupConfig = [
  {
    name: 'Direct',
    organic: true,
    paid: false
  },
  {
    name: 'Search / Organic',
    key: 'Organic Search',
    organic: true,
    paid: false
  },
  {
    name: 'Search / Paid',
    key: 'Paid Search',
    organic: false,
    paid: 'true'
  },
  {
    name: 'Social',
    organic: true,
    paid: false
  },
  {
    name: 'Referral',
    key: 'Referrals',
    organic: true,
    paid: false
  },
  {
    name: 'Display Ad',
    key: 'Display Ads',
    organic: false,
    paid: true
  },
  {
    name: 'Email',
    key: 'Mail',
    organic: false,
    paid: true
  }
];
