import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { Traffic } from './Traffic';

export function TrafficNumber({ auth, index, domain, country }) {
  const [fetching, setFetching] = useState(true);
  const [traffic, setTraffic] = useState(false);

  useEffect(() => {
    // Delay fetching new data based on index of competitor list
    const timer = setTimeout(async () => {
      console.log('Fetching traffic for', country, domain);
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/search/${domain}/${country}?companyInfoOnly=true`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      );
      setTraffic(response?.data?.companyInfo?.traffic);
      setFetching(false);
    }, index * 1000);
    setFetching(true);
    setTraffic(false);
    // setError(null);

    return () => clearTimeout(timer);
  }, [country]);

  if (fetching) {
    return (
      <Box>
        <Skeleton variant="rectangular" width={60} height={17} />
      </Box>
    );
  } else {
    return <Traffic traffic={traffic} />;
  }
}
