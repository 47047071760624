import React from 'react';
import { Grid, Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    float: 'right',
    position: 'relative',
    top: '-45px'
  },
  info: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dialog: {
    padding: '2rem'
  },
  paper: {
    minWidth: '500px',
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default function InfoDialog({ text }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className={classes.container}>
      <Grid item>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={handleClickOpen}
        >
          <img className={classes.info} src="/img/ui/info.svg" />
        </Typography>
      </Grid>
      <Dialog
        className={classes.dialog}
        classes={{
          paper: classes.paper
        }}
        open={open}
        onClose={handleClose}
        p={8}
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>{text}</MuiDialogContent>
      </Dialog>
    </Grid>
  );
}
