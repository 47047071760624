import { max, sum } from 'lodash';

export const negativeMarketSearchVsPrevious12Months = (yValues, noOfYears) => {
  const NUMBER_OF_YEARS = noOfYears || 4 - 1;

  const last12Sum = sum(yValues.slice(-12)); // y4
  const previous12Sum = sum(yValues.slice(-24, -12)); // y3

  return last12Sum < previous12Sum;
};

export const negativeMarketTrafficVsPrevious12Months = (yValues, noOfYears) => {
  const NUMBER_OF_YEARS = noOfYears || 4 - 1;

  const last12Sum = sum(yValues.slice(-4)); // y4
  const previous12Sum = sum(yValues.slice(-8, -4)); // y3

  return last12Sum < previous12Sum;
};

export const lowTrafficVolumeVsCompetitors = (yValues, noOfYears) => {
  const NUMBER_OF_YEARS = noOfYears || 4 - 1;

  const targetValue = yValues[0];
  const competitorValues = yValues.slice(1);
  const halfOfMaxValue = max(competitorValues) * 0.5;

  return targetValue < halfOfMaxValue;
};

export const negativeBrandVeloctiyVsPrevious12Months = (yValues, noOfYears) => {
  const NUMBER_OF_YEARS = noOfYears || 4 - 1;

  const last12Sum = sum(yValues.slice(-12)); // y4
  const previous12Sum = sum(yValues.slice(-24, -12)); // y3

  return last12Sum < previous12Sum;
};
