import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error from '../../components/Error';
import PageSummary from '../../components/Company/PageSummary';
import TabMenu from '../../components/Projects/TabMenu';
import GrabbingDataFooter from '../../components/Projects/GrabbingDataFooter';

import { Button, Grid, makeStyles } from '@material-ui/core';
import SocialContent from '../../components/Social/SocialContent';
import Loading from '../../components/Loading';
import { useCookies } from 'react-cookie';
import OnBoardModal from '../../components/OnBoardModal';
import SocialModal from '../../components/SocialModal';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SocialAccountsModal from '../../components/SocialAccountsModal';

const useStyles = makeStyles(() => ({
  outer: {
    minWidth: '100%',
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingBottom: '100px',
    paddingTop: '20px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  button: {
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    cursor: 'pointer',
    '&:hover': {
      color: '#1976d2',
      cursor: 'pointer'
    }
  }
}));

export default function Social({
  project,
  isLoadingProject,
  isErrorProject,
  socialAudienceChartData,
  isSocialbladeLoading,
  followersVsEngagementsChartData,
  organisation,
  isLoadingOrganisation
}) {
  const COOKIE_KEY = 'prism_social';
  const [cookies, setCookie] = useCookies([COOKIE_KEY]);

  const classes = useStyles();
  const { projectId } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [showSocialAccountsModal, setShowSocialAccountsModal] = useState(false);

  useEffect(() => {
    const cookieExists = cookies.prism_social_warning;

    setShowModal(!cookieExists);
  }, []);

  if (isLoadingProject || isSocialbladeLoading) return <Loading />;
  if (isLoadingOrganisation) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;
  const companyForHeader = company;

  const handleModalButtonClick = () => {
    setShowSocialAccountsModal(true);
  };

  return (
    <Grid className={classes.outer} direction="column">
      <SocialModal open={showModal} setOpen={setShowModal} />
      <SocialAccountsModal
        open={showSocialAccountsModal}
        setOpen={setShowSocialAccountsModal}
        socialAudienceChartData={socialAudienceChartData}
        projectId={projectId}
      />

      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={1} project={project} />
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        <PageSummary
          company={companyForHeader}
          project={project}
          tab="Social"
          content={'social-page'}
          cta={
            <Button
              className={classes.button}
              color="secondary"
              endIcon={<EditIcon />}
              onClick={handleModalButtonClick}>
              <span>Edit Social Accounts</span>
            </Button>
          }
        />

        <SocialContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          socialAudienceChartData={socialAudienceChartData}
          isSocialbladeLoading={isSocialbladeLoading}
          followersVsEngagementsChartData={followersVsEngagementsChartData}
          organisation={organisation}
        />
      </Grid>
      {!project.dataFetched && <GrabbingDataFooter />}
    </Grid>
  );
}
