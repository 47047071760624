import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCMS } from '../../api';
import Error from '../../components/Error';
import PageSummary from '../../components/Company/PageSummary';
import TabMenu from '../../components/Projects/TabMenu';

import { Grid, makeStyles } from '@material-ui/core';
import MarketBackgroundContent from '../../components/MarketBackground/MarketBackgroundContent';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  outer: {
    position: 'relative',
    display: 'flex',
    minWidth: '100%',
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingTop: '20px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function MarketBackground({
  project,
  isLoadingProject,
  isErrorProject,
  searchTrendsChartData,
  trafficTrendsChartData,
  setSearchTrendsKeywords,
  organisation
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  const cms = useCMS();

  const [filters] = useState({});

  if (isLoadingProject) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;
  const companyForHeader = company;

  return (
    <Grid container className={classes.outer} direction="column">
      <Grid>
        <Grid container>
          <TabMenu projectId={projectId} index={1} project={project} />
        </Grid>
      </Grid>
      <Grid className={classes.container} container>
        {/* <CompanyInformationHeader company={companyForHeader} project={project} /> */}
        <PageSummary
          company={companyForHeader}
          project={project}
          tab="Market"
          content={'market-page'}
        />

        <MarketBackgroundContent
          project={project}
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          searchTrendsChartData={searchTrendsChartData}
          trafficTrendsChartData={trafficTrendsChartData}
          setSearchTrendsKeywords={setSearchTrendsKeywords}
          organisation={organisation}
        />
      </Grid>
    </Grid>
  );
}
