import React, { useEffect } from 'react';
import { maxBy } from 'lodash-es';
import { Grid, Paper, makeStyles, Box } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { CSVLink, CSVDownload } from 'react-csv';
import Loading from '../Loading';
import { useAuth } from '../../hooks/use-auth';
import UpgradeButton from '../Auth/UpgradeButton';

const useStyles = makeStyles(() => ({
  graphContainer: {
    width: '100%',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    overflow: 'auto'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  customCell: {
    borderBottom: 'none',
    '& p': {
      margin: 0
    }
  },
  customHeaderCellTitle: {
    minWidth: '100px',
    borderRadius: '5px',
    padding: '10px'
  },
  customHeaderCellTitleGreen: {
    background: '#c5e0b4'
  },
  customHeaderCellTitleRed: {
    background: '#ff7e78'
  },
  customTableBody: {
    '& tr > td > p': {
      backgroundColor: '#E8E5EC',
      color: '#1F004A',
      margin: '0px',
      padding: '8px',
      borderRadius: '5px'
    },
    '& tr > th > p': {
      backgroundColor: '#E8E5EC',
      color: '#1F004A',
      margin: '0px',
      padding: '8px',
      borderRadius: '5px'
    }
  },
  customCellGreen: {
    border: '1px solid #c5e0b4'
  },
  customCellRed: {
    border: '1px solid #ff7e78'
  },
  downloadCSV: {
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    fontSize: 12,
    fontFamily:
      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    '&:hover': { color: '#1976d2' },
    fontWeight: 500,
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '5px 8px',
    textDecoration: 'none'
  }
}));

export default function WebsiteEngagement({
  project,
  dataFetched,
  websiteEngagementChartData,
  isWebsiteEngagementLoading,
  organisation
}) {
  // PERFORMANCE - ENGAGEMENT STRENGTH CHART
  const { orgType } = organisation;
  if (!project) return <Loading />;
  if (isWebsiteEngagementLoading) return <Loading />;

  const data = websiteEngagementChartData?.data;

  const classes = useStyles();

  if (dataFetched && data) {
    const visitDurations = data.map((d) => d.find((i) => 'visitDuration' in i));
    const maxVisitDurationObject = maxBy(visitDurations, 'visitDuration');
    const maxVisitDuration = maxVisitDurationObject.visitDuration;

    const pagesPerVisit = data.map((d) => d.find((i) => 'pagesPerVisit' in i));
    const maxPagesPerVisitObject = maxBy(pagesPerVisit, 'pagesPerVisit');
    const maxPagesPerVisit = maxPagesPerVisitObject.pagesPerVisit;

    const bounceRates = data.map((d) => d.find((i) => 'bounceRate' in i));
    const maxBounceRateObject = maxBy(bounceRates, 'bounceRate');
    const maxBounceRate = maxBounceRateObject.bounceRate;

    const csvData = data.map((item, index) => ({
      Domain: item[0].domain,
      'Average visit duration (indexed)': Math.round(
        (Number(item[0].visitDuration) / maxVisitDuration) * 100
      ),
      'Average # pages / visit (indexed)': Math.round(
        (Number(item[1].pagesPerVisit) / maxPagesPerVisit) * 100
      ),
      'Bounce rate (indexed)': Math.round((Number(item[2].bounceRate) / maxBounceRate) * 100)
    }));

    return (
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.graphContainer}
          id="engagement-strength-chart">
          <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10, marginRight: 20 }}>
            {orgType === 'Lite' ? (
              <UpgradeButton />
            ) : (
              <CSVLink
                className={classes.downloadCSV}
                data={csvData}
                filename={'engagement_strength.csv'}>
                Download CSV
              </CSVLink>
            )}
          </div>
          {/* <h3 className={classes.graphTitle}>{title}</h3> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.customCell} />
                <TableCell className={classes.customCell} align="center">
                  <Box
                    className={classNames(
                      classes.customHeaderCellTitle,
                      classes.customHeaderCellTitleGreen
                    )}>
                    <p>Average visit duration</p>
                    <p>(indexed)</p>
                  </Box>
                </TableCell>
                <TableCell className={classes.customCell} align="center">
                  <div
                    className={classNames(
                      classes.customHeaderCellTitle,
                      classes.customHeaderCellTitleGreen
                    )}>
                    <p>Average # pages / visit</p>
                    <p>(indexed)</p>
                  </div>
                </TableCell>
                <TableCell className={classes.customCell} align="center">
                  <div
                    className={classNames(
                      classes.customHeaderCellTitle,
                      classes.customHeaderCellTitleRed
                    )}>
                    <p>Bounce rate</p>
                    <p>(indexed)</p>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.customTableBody}>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.customCell} component="th" scope="row">
                    <p>
                      {item[0].domain == project.name ? <b>{item[0].domain}</b> : item[0].domain}
                    </p>
                  </TableCell>
                  <TableCell className={classNames(classes.customCell)} align="center">
                    <p className={classes.customCellGreen}>
                      {Math.round((Number(item[0].visitDuration) / maxVisitDuration) * 100)}
                    </p>
                  </TableCell>
                  <TableCell className={classes.customCell} align="center">
                    <p className={classes.customCellGreen}>
                      {Math.round((Number(item[1].pagesPerVisit) / maxPagesPerVisit) * 100)}
                    </p>
                  </TableCell>
                  <TableCell className={classes.customCell} align="center">
                    <p className={classes.customCellRed}>
                      {Math.round((Number(item[2].bounceRate) / maxBounceRate) * 100)}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  return (
    <Paper className={classes.graphContainer} elevation={0} id="engagement-strength-chart">
      {/* <h3 className={classes.graphTitle}>{title}</h3> */}
      <Skeleton width="50%" />
      <Skeleton width="20%" />
      <Skeleton width="30%" />
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Paper>
  );
}
