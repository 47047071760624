// Social Audience chart

import React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { abbreviateNumber } from '../../utils/numbers';
import Loading from '../Loading';
import '../CompetitorAnalysis/Overview.css';
import UpgradeButton from '../Auth/UpgradeButton';
import { useAuth } from '../../hooks/use-auth';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';

const useStyles = makeStyles(() => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function SocialAudience({
  project,
  dataFetched,
  socialAudienceChartData,
  isSocialbladeLoading,
  organisation
}) {
  // SOCIAL AUDIENCE CHART
  const { orgType } = organisation;
  const classes = useStyles();

  if (!project) return <Loading />;
  if (isSocialbladeLoading) return <Loading />;
  if (!socialAudienceChartData.data) return <Loading />;
  if (isArrayEmpty(socialAudienceChartData.data.socialbladeData)) return <Loading />;

  const { series, categories } = socialAudienceChartData;

  return (
    <Grid>
      {series && categories && (
        <Paper className={classes.graphContainer} elevation={0} id="social-audience-chart">
          {orgType === 'Lite' && <OverlayUpgradeButton />}
          <Chart
            className="social-audience"
            options={{
              dataLabels: {
                formatter: function (value, opts) {
                  let dp = 0;
                  return abbreviateNumber(value, dp);
                }
              },
              xaxis: {
                categories
              },
              chart: {
                stacked: true,
                toolbar: {
                  tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                  }
                }
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    let dp = 0;

                    return abbreviateNumber(value, dp);
                  }
                }
              },
              colors: ['#4C1E8B', '#877DFF', '#03CEA4']
            }}
            series={series}
            type="bar"
            width="100%"
          />
        </Paper>
      )}
    </Grid>
  );
}
