import React, { useEffect } from 'react';
import Error from '../../components/Error';
import { Grid, makeStyles } from '@material-ui/core';
import SummaryPageContent from '../../components/Summary/SummaryPageContent';
import CompanyContent from '../../components/Company/CompanyContent';
import MarketBackgroundContent from '../../components/MarketBackground/MarketBackgroundContent';
import CompetitorAnalysisContent from '../../components/CompetitorAnalysis/CompetitorAnalysisContent';
import BrandContent from '../../components/Brand/BrandContent';
import SocialContent from '../../components/Social/SocialContent';
import CoverPage from '../../components/Export/CoverPage';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '140px',
    width: '90%'
  },
  rowContainer: {
    marginTop: '10px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },

  pageBreakAfter: {
    '@media print': {
      'page-break-after': 'always'
    }
  }
}));

export default function Export({
  applyFilters,
  brandSearchesChartData,
  brandSearchChartData,
  countries,
  filters,
  followersVsEngagementsChartData,
  isBrandSearchesLoading,
  isErrorProject,
  isLoadingProject,
  isTrafficSourceVolumesLoading,
  isTrustPilotLoading,
  isWebsiteTrafficVolumesLoading,
  isWebsiteEngagementLoading,
  overviewChartData,
  project,
  projectId,
  searchTrendsChartData,
  setSearchTrendsKeywords,
  socialbladeScore,
  summaryData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  trafficTrendsChartData,
  trustpilotChartData,
  trustpilotScore,
  websiteEngagementChartData,
  websiteEngagementScore,
  websiteTrafficVolumes,
  isSocialbladeLoading,
  socialAudienceChartData,
  siteOptimisationChartData
}) {
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('afterprint', (event) => {
      window.close();
    });

    window.setTimeout(() => {
      window.print();
    }, 2000);
  }, []);

  if (isLoadingProject) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  return (
    <Grid style={{ width: '100%', flexDirection: 'column' }}>
      <div className={classes.pageBreakAfter}>
        <CoverPage project={project} />
      </div>

      <Grid className={classes.pageBreakAfter}>
        <SummaryPageContent
          project={project}
          summaryData={summaryData}
          brandSearchChartData={brandSearchChartData}
          followersVsEngagementsChartData={followersVsEngagementsChartData}
          overviewChartData={overviewChartData}
          searchTrendsChartData={searchTrendsChartData}
          socialbladeScore={socialbladeScore}
          trafficByMonthChartData={trafficByMonthChartData}
          trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
          trafficTrendsChartData={trafficTrendsChartData}
          trustpilotScore={trustpilotScore}
          websiteEngagementScore={websiteEngagementScore}
          siteOptimisationChartData={siteOptimisationChartData}
        />
      </Grid>

      <Grid className={classes.pageBreakAfter}>
        <CompanyContent />
      </Grid>

      <Grid className={classes.pageBreakAfter}>
        <h2 className={classes.title}>Market</h2>

        <MarketBackgroundContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          searchTrendsChartData={searchTrendsChartData}
          trafficTrendsChartData={trafficTrendsChartData}
          setSearchTrendsKeywords={setSearchTrendsKeywords}
          brandSearchesChartData={brandSearchesChartData}
          isBrandSearchesLoading={isBrandSearchesLoading}
        />
      </Grid>

      <Grid className={classes.pageBreakAfter}>
        <h2 className={classes.title}>Performance</h2>

        <CompetitorAnalysisContent
          projectId={projectId}
          project={project}
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          countries={countries}
          filters={filters}
          applyFilters={applyFilters}
          websiteTrafficVolumes={websiteTrafficVolumes}
          isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
          overviewChartData={overviewChartData}
          trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
          isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
          trafficByMonthChartData={trafficByMonthChartData}
          isWebsiteEngagementLoading={isWebsiteEngagementLoading}
          websiteEngagementChartData={websiteEngagementChartData}
        />
      </Grid>

      <Grid className={classes.pageBreakAfter}>
        <h2 className={classes.title}>Brand</h2>

        <BrandContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          brandSearchesChartData={brandSearchesChartData}
          isBrandSearchesLoading={isBrandSearchesLoading}
          trustpilotChartData={trustpilotChartData}
          isTrustPilotLoading={isTrustPilotLoading}
        />
      </Grid>

      <Grid className={classes.pageBreakAfter}>
        <h2 className={classes.title}>Social</h2>

        <SocialContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          brandSearchesChartData={brandSearchesChartData}
          isBrandSearchesLoading={isBrandSearchesLoading}
          trustpilotChartData={trustpilotChartData}
          isTrustPilotLoading={isTrustPilotLoading}
          socialAudienceChartData={socialAudienceChartData}
          isSocialbladeLoading={isSocialbladeLoading}
          followersVsEngagementsChartData={followersVsEngagementsChartData}
        />
      </Grid>
    </Grid>
  );
}
