import React, { useEffect, useState } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { abbreviateNumber } from '../../utils/numbers';
import { sortBy } from 'lodash';
import Loading from '../Loading';
import { useAuth } from '../../hooks/use-auth';
import UpgradeButton from '../Auth/UpgradeButton';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
const useStyles = makeStyles(() => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function CategoryCoverage({
  title,
  project,
  dataFetched,
  isPagespeedInsightsLoading,
  pagespeedInsightsData,
  siteOptimisationChartData,
  organisation
}) {
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;

  const [chartSeries, setChartSeries] = useState([]);

  const data = pagespeedInsightsData;

  const isLoading = isPagespeedInsightsLoading;

  const classes = useStyles();

  const domains = data.map((d) => d.domain);
  const categoryKeys = ['performance', 'accessibility', 'seo'];

  useEffect(() => {
    if (!data || data.length === 0) return;

    const transformedSeries = data
      .filter((d) => d.overall && d.accessibility && d.performance && d.performance && d.seo)
      .map((d) => {
        return {
          name: d.domain,
          data: categoryKeys.map((key) => {
            return d[key];
          })
        };
      });

    setChartSeries(transformedSeries);
  }, [data]);

  if (isLoading) return <Loading />;
  if (chartSeries.length === 0) return <Loading />;
  if (categoryKeys.length === 0) return <Loading />;

  if (!(dataFetched && !isArrayEmpty(data) && data)) {
    return (
      <Paper className={classes.graphContainer} elevation={0}>
        <Skeleton width="50%" />
        <Skeleton width="20%" />
        <Skeleton width="30%" />
        <Skeleton width="40%" />
        <Skeleton width="25%" />
      </Paper>
    );
  }

  console.log('chartSeries', chartSeries);

  return (
    <Grid>
      {chartSeries && categoryKeys.length && (
        <Paper className={classes.graphContainer} elevation={0} id="capability-dimensions">
          {orgType === 'Lite' && <OverlayUpgradeButton />}
          <Chart
            options={{
              fill: {
                opacity: 0
              },
              colors: ['#F67DFF', '#5D2AFF', '#3CC0FF', '#F1C70B', '#4F82FF', '#1F004A'],
              xaxis: {
                categories: categoryKeys
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    let dp = 0;
                    if (value < 10000000) {
                      dp = 1;
                    }
                    return abbreviateNumber(value, dp);
                  }
                }
              }
            }}
            series={chartSeries}
            type="radar"
            width="100%"
          />
        </Paper>
      )}
    </Grid>
  );
}
