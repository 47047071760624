import axios from 'axios';
// Use SWR hooks
import { useCMS } from './cms';

import {
  useProjectsList,
  useProject,
  useProjectWebsiteEngagement,
  useProjectWebsiteTrafficVolumes,
  useProjectBrandedSearch,
  useProjectBounceRates,
  useProjectPagesPerVisit,
  useProjectVisitDuration,
  useProjectTrafficSourceBreakdown,
  useProjectSentiment,
  useProjectAnalystReport,
  useProjectShareOfVoice
} from './projects';

import { useCredits, useOrganisation } from './organisations';

const API_URL = process.env.REACT_APP_URL;

const API = axios.create({
  baseURL: `${API_URL}/api`
});

export default {
  getCountries: () => API.get('/countries')
};

export {
  useCMS,
  useProject,
  useProjectsList,
  useProjectWebsiteEngagement,
  useProjectWebsiteTrafficVolumes,
  useProjectBrandedSearch,
  useProjectBounceRates,
  useProjectPagesPerVisit,
  useProjectVisitDuration,
  useProjectTrafficSourceBreakdown,
  useCredits,
  useProjectSentiment,
  useProjectAnalystReport,
  useProjectShareOfVoice,
  useOrganisation
};
