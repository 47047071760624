import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: 'white',
    margin: '20px 0',
    borderRadius: '20px',
    flexDirection: (props) => props.direction,

    [`@media print`]: {
      flexDirection: 'column-reverse !important'
    }
  },

  first: {
    width: '66%',

    [`@media print`]: {
      width: '70%'
    }
  },

  second: {
    width: '33%',

    [`@media print`]: {
      width: '90%'
    }
  }
}));

export default function ChartAndDetailContainer(props) {
  const reverse = props.reverse || false;

  const classes = useStyles({
    direction: reverse ? 'row-reverse' : 'row'
  });

  return (
    <div className={classes.container}>
      <div className={classes.first}>{props.children[0]}</div>

      <Divider orientation="vertical" flexItem style={{ margin: '40px 0' }} />

      <div className={classes.second}>{props.children[1]}</div>
    </div>
  );
}
