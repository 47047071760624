import {
  Box,
  Button,
  Chip,
  Container as MuiContainer,
  Divider,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';

import { useEffect, useState } from 'react';

import Container from '../components/Container';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  currentPlanButton: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    background: '#3C21BA',
    opacity: 0.2,
    color: 'white',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&': {
      pointerEvents: 'none'
    },
    '&:hover': {
      pointerEvents: 'none'
    }
  },
  upgradePlanButton: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    background: '#3C21BA',
    opacity: 1,
    color: 'white',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  divider: {
    margin: '15px 0 !important'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '2%',
    marginLeft: '40px'
  },
  tierPaper: {
    boxShadow: '0px 4px 20px rgba(60, 33, 186, 0.05)',
    padding: '10px',
    width: '30%',
    borderRadius: '10px'
  },
  tierGridContainer: {
    minWidth: '100%',
    padding: '10px'
  },
  popularChip: {
    backgroundColor: 'lightgreen',
    color: 'white',
    borderRadius: '5px',
    padding: '5px 10px'
  },
  needMoreContainer: {
    textAlign: 'center',
    color: 'grey',
    textDecoration: 'underline',
    marginTop: '15px'
  },
  tableTitle: {
    color: 'purple',
    fontSize: '20px'
  },
  sectionWrapper: {
    padding: '50px'
  },
  features: {
    color: '#3C21BA',
    fontSize: '20px',
    lineHeight: '24px'
  },
  contentHeading: {
    paddingRight: '30px'
  },
  rowFeatures: {},
  removeMargin: {
    margin: 0,
    borderBottom: '1px solid #E5E5E5',
    '&:first-of-type': {
      borderTop: '1px solid #E5E5E5'
    },
    '&:last-of-type': {
      borderBottom: '0px'
    }
  },
  topBorder: {
    width: 'calc(100% - 24px)'
  }
}));

export default function AdvisoryHub() {
  const classes = useStyles();
  const history = useHistory();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [credits, setCredits] = useState(0);
  const [userFetched, setUserFetched] = useState(false);
  const [tier, setTier] = useState('');

  const [iframeHeight, setIframeHeight] = useState(0);

  // useEffect(() => {
  //   const iframe = document.querySelector('iframe');
  //   const updateHeight = () => {
  //     setIframeHeight(iframe.contentWindow.document.body.scrollHeight);
  //   };
  //   updateHeight();
  //   iframe.addEventListener('load', updateHeight);
  //   window.addEventListener('resize', updateHeight);
  //
  //   return () => {
  //     iframe.removeEventListener('load', updateHeight);
  //     window.removeEventListener('resize', updateHeight);
  //   };
  // }, []);

  useEffect(() => {
    // webflowCustomCode();
  }, []);

  const containerStyle = { height: iframeHeight };

  return (
    <iframe
      allow="fullscreen,autoplay,geolocation"
      frameBorder={0}
      src="https://starlit-tulumba-93c28e.netlify.app/prism-blank.html"
      height="100%"
      width="100%"></iframe>
  );
}
