import { Grid, Paper, makeStyles, Chip, Button, Container, Box } from '@material-ui/core';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export default function CompanyInformationHeader({ company, variant, match, project }) {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    companyHeaderContainer: {
      backgroundColor: '#FFFFFF',
      padding: '30px 0px',
      borderRadius: '20px'
    },
    companyHeaderInformationContainer: {
      borderLeft: '1px solid #EBE8F8',
      padding: '0px 0px 0px 30px',
      color: '#7A8199'
    },
    companyCardInformationContainer: {
      padding: '0px 0px 0px 30px',
      color: '#7A8199'
    },
    companyHeaderButton: {
      textTransform: '',
      backgroundColor: '#EFEDF9',
      color: '#3C21BA',
      marginRight: '10px'
    },
    companyCardButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    companyCardButton: {
      backgroundColor: '#EFEDF9',
      color: '#3C21BA',
      borderRadius: '4px',
      marginLeft: '10px',
      height: '30px',
      cursor: 'pointer !important'
    },
    companyInformationNameHeading: {
      color: '#3C21BA',
      width: '100%'
    },
    chipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    chipCardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'start',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    chip: {
      backgroundColor: 'inherit',
      color: '#7A8199'
    },
    cardChip: {
      backgroundColor: 'inherit',
      color: '#7A8199',
      margin: '0px',
      maxWidth: '100%'
    },
    companyInformationHeaderLogo: {
      margin: '60px auto',
      width: '100%'
    },
    companyInformationCardLogoContainer: {
      margin: '0px auto',
      paddingBottom: '20px',
      borderBottom: '1px solid #EBE8F8'
    },
    companyInformationHeaderLogoContainer: {
      margin: 'auto 0'
    },
    companyImgContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto'
    },
    companyImg: {
      display: 'flex',
      justifyContent: 'center',
      background: `url(${company.image_url || '/img/prism_short_purple.svg'}) no-repeat`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      height: '100px',
      width: '100%'
    },
    warningIcon: {
      fill: '#ffda00'
    },

    exportPdfButton: {
      backgroundColor: '#EFEDF9',
      color: '#3C21BA',
      textTransform: 'none'
    }
  }));

  const classes = useStyles();

  const externalUrl = company.url || project?.domain;

  function handleClickPdfButton() {
    const url = '/projects/' + project?.id + '/export';
    window.open(url, '_blank');
  }

  return (
    <Grid item md={variant === 'card' ? 4 : 12}>
      <Paper elevation={0} className={classes.companyHeaderContainer}>
        <Grid container direction="row">
          <Grid
            item
            md={variant === 'card' ? 10 : 2}
            className={
              variant === 'card'
                ? classes.companyInformationCardLogoContainer
                : classes.companyInformationHeaderLogoContainer
            }>
            <Grid container justifyContent="space-between">
              <Grid item md={variant === 'card' ? 6 : null} className={classes.companyImgContainer}>
                {/* competitor logo set as background image in css */}
                <img
                  src={`https://icons.duckduckgo.com/ip3/${company.url || project?.domain}.ico`}
                  width={50}
                />
              </Grid>
              {variant === 'card' ? (
                <Grid md={6} className={classes.companyCardButtonContainer}>
                  <Button
                    href={`https://${externalUrl}`}
                    target="_blank"
                    variant="contained"
                    startIcon={<CallMadeOutlinedIcon />}
                    disableElevation
                    className={classes.companyHeaderButton}>
                    Website
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            item
            md={10}
            className={
              variant === 'card'
                ? classes.companyCardInformationContainer
                : classes.companyHeaderInformationContainer
            }>
            <Grid container direction="row">
              <Grid item md={variant === 'card' ? 12 : 4}>
                {variant === 'card' && (
                  <h1 className={classes.companyInformationNameHeading}>
                    {company?.company_name || company?.url}
                  </h1>
                )}

                {variant !== 'card' && (
                  <h1 className={classes.companyInformationNameHeading}>
                    {company?.company_name || project?.name}
                  </h1>
                )}
              </Grid>

              {variant === 'card' ? null : (
                <Grid item md={8}>
                  <Grid container justifyContent="flex-end">
                    {/*<Button*/}
                    {/*  className={classes.exportPdfButton}*/}
                    {/*  style={{ marginRight: '20px' }}*/}
                    {/*  onClick={handleClickPdfButton}*/}
                    {/*>Export PDF*/}
                    {/*</Button>*/}
                    <Button
                      href={`https://${externalUrl}`}
                      target="_blank"
                      variant="contained"
                      startIcon={<CallMadeOutlinedIcon />}
                      disableElevation
                      className={classes.companyHeaderButton}>
                      Website
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container>
              <Grid item md={12}>
                {company?.description?.length && (
                  <p>
                    From the brand:{' '}
                    <span style={{ textTransform: 'capitalize' }}>{company.description}</span>
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={12}>
                <div
                  className={
                    variant === 'card' ? classes.chipCardContainer : classes.chipContainer
                  }>
                  {company?.headquarters?.length > 0 && (
                    <Chip
                      className={variant === 'card' ? classes.cardChip : classes.chip}
                      label={company.headquarters}
                      icon={<RoomOutlinedIcon />}
                    />
                  )}

                  {company?.website_category && (
                    <Chip
                      className={variant === 'card' ? classes.cardChip : classes.chip}
                      label={company.website_category.replaceAll('_', ' ')}
                      icon={<PowerSettingsNewOutlinedIcon />}
                    />
                  )}
                  {company?.employee_range && (
                    <Chip
                      className={variant === 'card' ? classes.cardChip : classes.chip}
                      label={`${company.employee_range} employees`}
                      icon={<PersonOutlineOutlinedIcon />}
                    />
                  )}
                  {company?.estimated_revenue_in_usd && (
                    <Chip
                      className={variant === 'card' ? classes.cardChip : classes.chip}
                      label={`${company.estimated_revenue_in_usd} est. revenue`}
                      icon={<AttachMoneyOutlinedIcon />}
                    />
                  )}
                </div>

                {!company && (
                  <Container>
                    <Grid container direction="row" spacing={4} alignItems="center">
                      <Box marginRight={2}>
                        <ReportProblemOutlinedIcon
                          className={classes.warningIcon}
                          lightingColor="yellow"
                          fill="#FF0000"
                        />
                      </Box>
                      <Box>
                        <p>
                          Apologies, we are unable to locate a profile for this company. This can
                          occur for small businesses or very new start-ups.
                        </p>
                        <p>Our support team have been notified.</p>
                      </Box>
                    </Grid>
                  </Container>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
