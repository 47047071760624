import { Route, useParams } from 'react-router-dom';
import CompetitorAnalysis from './CompetitorAnalysis';
import MarketBackground from './MarketBackground';
import Company from './Company';
import Summary from './Summary';
import {
  useOrganisation,
  useProject,
  useProjectWebsiteEngagement,
  useProjectWebsiteTrafficVolumes
} from '../../api';
import React, { useEffect, useState } from 'react';
import isArrayEmpty from '../../utils/isArrayEmpty';
import { groupBy, max, maxBy, mean, meanBy, minBy, reduce, sortBy, sum } from 'lodash';
import {
  useProjectBrandSearches,
  useProjectIndustrySearches,
  useProjectIndustryTraffic,
  useProjectPagespeedInsights,
  useProjectSocialblade,
  useProjectTrafficMobileSourceVolumes,
  useProjectTrafficSourceVolumes,
  useProjectTrustPilot
} from '../../api/projects';
import moment from 'moment';
import {
  getCagrScore,
  getCagrValue,
  getMonthHighAvg,
  getMonthLowAvg,
  getTrendLineSlope,
  getTrendSeries as getTrafficChartTrendSeries,
  groupByMonth
} from '../../utils/charts';
import { trafficBySourceGroupConfig } from '../../utils/trafficBySourceGroupConfig';
import createTrend from 'trendline';
import Brand from './Brand';
import Social from './Social';
import Export from './Export';
import Loading from '../../components/Loading';
import Tech from './Tech';
import {
  calculateMobileTrafficSourceTotals,
  calculateSumOfMobileTraffic,
  calculateTrafficSourceTotals,
  calculateSumOfTraffic,
  mergeTrafficSourceSums
} from './TrafficSourceCalulcations';
import { mobileTrafficBySourceGroupConfig } from '../../utils/mobileTrafficBySourceGroupConfig';

const ProjectRouteContainer = () => {
  const anchor = window.location.hash;
  useEffect(() => {
    if (anchor) {
      window.scrollTo(0, 0);
      setTimeout(function () {
        const section = document.querySelector(anchor);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  }, [anchor]);

  // From route params
  const { id: projectId } = useParams();

  const [filters, setFilters] = useState({});
  const [countries, setCountries] = useState([]);

  const applyFilters = (filters) => {
    setFilters(filters);
    setCountries(Object.keys(filters).filter((key) => filters[key]));
  };

  const scoreOrNull = (score, toFixed = 0) => {
    if (score === null) return null;
    return +score.toFixed(toFixed);
  };

  // Get project data
  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId);

  // Get organisation

  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation
  } = useOrganisation();

  // ==========================================================
  // Various chart states
  // ==========================================================

  // The logic here is that scores should have a default state value of undefined

  // Market Background Charts State

  // Search trends chart state
  const [searchTrendsSeries, setSearchTrendsSeries] = useState([]);
  const [searchTrendsCategories, setSearchTrendsCategories] = useState([]);
  const [searchTrendsKeywords, setSearchTrendsKeywords] = useState([]);
  const [searchTrendsCagr, setSearchTrendsCagr] = useState();
  const [searchTrendsScore, setSearchTrendsScore] = useState();
  const [searchTrendsInsights, setSearchTrendsInsights] = useState();

  // Traffic trends chart state
  const [trafficTrendsSeries, setTrafficTrendsSeries] = useState([]);
  const [trafficTrendsCategories, setTrafficTrendsCategories] = useState([]);
  const [trafficTrendsCagr, setTrafficTrendsCagr] = useState();
  const [trafficTrendsScore, setTrafficTrendsScore] = useState();
  const [trafficTrendsInsights, setTrafficTrendsInsights] = useState();

  // Competitor Analysis Charts State

  // Overview chart state
  const [series, setSeries] = useState();
  const [competitors, setCompetitors] = useState([]);
  const [overviewScore, setOverviewScore] = useState(100);
  const [overviewChartInsights, setOverviewChartInsights] = useState({});

  // Competitor Analysis state
  const [trafficSourceBreakdownScore, setTrafficSourceBreakdownScore] = useState(100);
  const [trafficSourceBreakdownInsights, setTrafficSourceBreakdownInsights] = useState({});
  const [trafficSourceBreakdownSeries, setTrafficSourceBreakdownSeries] = useState([]);
  const [trafficSourceBreakdownCategories, setTrafficSourceBreakdownCategories] = useState([]);

  // Mobile Traffic Source chart
  const [mobileTrafficSourceBreakdownSeries, setMobileTrafficSourceBreakdownSeries] = useState([]);
  const [mobileTrafficSourceBreakdownCategories, setMobileTrafficSourceBreakdownCategories] =
    useState([]);

  // Combined Traffic Source chart
  const [combinedTrafficSourceBreakdownScore, setCombinedTrafficSourceBreakdownScore] =
    useState(100);
  const [combinedTrafficSourceBreakdownInsights, setCombinedTrafficSourceBreakdownInsights] =
    useState({});
  const [combinedTrafficSourceBreakdownSeries, setCombinedTrafficSourceBreakdownSeries] =
    useState();
  const [combinedTrafficSourceBreakdownCategories, setCombinedTrafficSourceBreakdownCategories] =
    useState();

  // Traffic by month chart state
  const [trafficByMonthSeries, setTrafficByMonthSeries] = useState();
  const [trafficByMonthCategories, setTrafficByMonthCategories] = useState();
  const [trafficByMonthScore, setTrafficByMonthScore] = useState(100);
  const [trafficByMonthChartInsights, setTrafficByMonthChartInsights] = useState({});

  // Website engagement chart state
  const [websiteEngagementDataForChart, setWebsiteEngagementDataForChart] = useState();
  const [websiteEngagementScore, setWebsiteEngagementScore] = useState(100);
  const [websiteEngagementChartInsights, setWebsiteEngagementChartInsights] = useState({});

  // Summary state
  const [overallScore, setOverallScore] = useState(100);
  const [overallRank, setOverallRank] = useState(0);
  const [rankedAssets, setRankedAssets] = useState([]);
  const [rankingInsights, setRankingInsights] = useState({});
  const [marketScore, setMarketScore] = useState(100);

  // Brand Search state
  const [brandSearchSeries, setBrandSearchSeries] = useState([]);
  const [brandSearchCategories, setBrandSearchCategories] = useState([]);
  const [brandSearchInsights, setBrandSearchInsights] = useState({});
  const [brandSearchScore, setBrandSearchScore] = useState(undefined);

  // Trustpilot state
  const [trustpilotScore, setTrustpilotScore] = useState(undefined);
  const [trustpilotChartInsights, setTrustpilotChartInsights] = useState({});

  // Socialblade state (Social Audience)
  const [socialbladeScore, setSocialbladeScore] = useState(undefined);
  const [socialbladeChartInsights, setSocialbladeChartInsights] = useState({});
  const [socialAudienceSeries, setSocialAudienceSeries] = useState([]);
  const [socialAudienceCategories, setSocialAudienceCategories] = useState([]);

  // Followers Vs Engagements state
  const [followersVsEngagementsScore, setFollowersVsEngagementsScore] = useState(undefined);
  const [followersVsEngagementsChartInsights, setFollowersVsEngagementsChartInsights] = useState(
    {}
  );

  const [siteOptimisationInsights, setSiteOptimisationInsights] = useState({});
  const [siteOptimisationScore, setSiteOptimisationScore] = useState(undefined);

  // ==========================================================
  // Fetch data from API
  // ==========================================================

  // Market Background Charts
  const {
    data: industrySearchesData,
    isLoading: isIndustrySearchesLoading,
    isError: industrySearchesError
  } = useProjectIndustrySearches(projectId);

  const {
    data: industryTrafficData,
    isLoading: isIndustryTrafficLoading,
    isError: industryTrafficError
  } = useProjectIndustryTraffic(projectId);

  // Competitor Analysis Charts
  const {
    data: websiteTrafficVolumesData,
    isLoading: isWebsiteTrafficVolumesLoading,
    isError: websiteTrafficVolumesError
  } = useProjectWebsiteTrafficVolumes(project, countries);

  const {
    data: trafficSourceVolumesData,
    isLoading: isTrafficSourceVolumesLoading,
    isError: trafficSourceVolumesError
  } = useProjectTrafficSourceVolumes(project, countries);

  const {
    data: trafficMobileSourceVolumesData,
    isLoading: isMobileTrafficSourceVolumesLoading,
    isError: trafficMobileSourceVolumesError
  } = useProjectTrafficMobileSourceVolumes(project, countries);

  const {
    data: websiteEngagementData,
    isLoading: isWebsiteEngagementLoading,
    isError: websiteEngagementError
  } = useProjectWebsiteEngagement(project, countries);

  // Brand Charts
  const {
    data: brandSearchesData,
    isLoading: isBrandSearchesLoading,
    isError: brandSearchesError
  } = useProjectBrandSearches(projectId);

  const {
    data: trustPilotData,
    isLoading: isTrustPilotLoading,
    isError: trustPilotError
  } = useProjectTrustPilot(projectId);

  // Social Charts
  const {
    data: socialbladeData,
    isLoading: isSocialbladeLoading,
    isError: socialbladeError
  } = useProjectSocialblade(projectId);

  // Tech Charts
  const {
    data: pagespeedInsightsData,
    isLoading: isPagespeedInsightsLoading,
    isError: pagespeedInsightsError
  } = useProjectPagespeedInsights(projectId);

  // ==========================================================
  // Effects to run when data is fetched to process chart data
  // ==========================================================

  function getDateSeries(data) {
    return [...new Set(data.map((item) => item.date))];
  }

  function reduceDataSeriesForChart(filters, dateSeries, data, filterByActiveKeywords = false) {
    const filteredData = [];

    const countries = project.countries;

    countries.forEach((country) => {
      filteredData.push({
        name: country,
        data: dateSeries.map((date) => {
          return {
            x: date,
            y: data
              .filter((item) => item.date === date && item.country === country) // filter to month
              .map((d) => d.data)
              .flat()
              .filter((item) => {
                if (filterByActiveKeywords) {
                  return searchTrendsKeywords.find(
                    (keyword) => keyword.keyword === item.keyword && keyword.selected
                  );
                } else {
                  return true;
                }
              })
              .map((d) => d.volume)
              .reduce((a, b) => +a + +b, 0)
          };
        })
      });
    });

    return filteredData;
  }

  function getTrendSeries(seriesData) {
    if (!seriesData.length) return [];
    if (!seriesData[0].data) return [];

    const dataForTrendCalculation = seriesData[0].data.map((item, index) => {
      return {
        x: index + 1,
        y: item.y
      };
    });

    const trend = createTrend(dataForTrendCalculation, 'x', 'y');
    const trendSeries = {
      name: 'Trendline',
      data: seriesData[0].data.map((item, index) => {
        return {
          x: item.x,
          y: trend.calcY(index + 1)
        };
      })
    };

    return trendSeries;
  }

  // Set search trends chart data
  useEffect(() => {
    if (isIndustrySearchesLoading) return;
    if (isArrayEmpty(industrySearchesData) || !industrySearchesData) return;
    if (!project || isLoadingProject) return;

    function getKeywords(data) {
      // eslint-disable-next-line
      return _(
        data
          .map((d) => d.data)
          .flat()
          .map((ob) => {
            ob.volume = +ob.volume;
            return ob;
          })
      )
        .groupBy('keyword')
        .map((objs, key) => ({
          selected: true,
          keyword: key,
          // eslint-disable-next-line
          volume: _.sumBy(objs, 'volume')
        }))
        .sortBy('volume')
        .reverse()
        .value();
    }

    // Keyword stuff
    const keywords = getKeywords(industrySearchesData);
    setSearchTrendsKeywords(keywords);

    // Chart stuff
    const dateSeries = getDateSeries(industrySearchesData);
    setSearchTrendsCategories(dateSeries.map((date) => moment(date).valueOf()));

    const seriesData = reduceDataSeriesForChart(filters, dateSeries, industrySearchesData);
    const trendData = getTrendSeries(seriesData);
    const yValues = seriesData[0].data.map((i) => i.y);
    const cagrValue = getCagrValue(yValues);
    seriesData.push(trendData);

    setSearchTrendsSeries(seriesData);
    setSearchTrendsCagr(cagrValue);

    // Transform the data into { x: [], y: [] } format
    // Group the data by month
    const totalsByMonth = groupByMonth(
      seriesData[0].data.map((item, index) => {
        return {
          x: moment(item.x),
          y: item.y
        };
      })
    );

    const cagrScore = getCagrScore(cagrValue);
    setSearchTrendsScore(cagrScore);

    setSearchTrendsInsights({
      cagr: cagrValue.toFixed(1),
      trendlineSlope: getTrendLineSlope(seriesData[1].data.map((i) => i.y)),
      monthLowAvg: getMonthLowAvg(totalsByMonth),
      monthHighAvg: getMonthHighAvg(totalsByMonth),
      score: cagrScore.toFixed(0)
    });
  }, [industrySearchesData, project]);

  // Recalculate chart data when keywords are selected/deselected
  useEffect(() => {
    if (isIndustrySearchesLoading) return;
    if (isArrayEmpty(industrySearchesData) || !industrySearchesData) return;
    if (!project || isLoadingProject) return;

    // Chart stuff
    const dateSeries = getDateSeries(industrySearchesData);
    setSearchTrendsCategories(dateSeries.map((date) => moment(date).valueOf()));

    const seriesData = reduceDataSeriesForChart(filters, dateSeries, industrySearchesData, true);
    const trendData = getTrendSeries(seriesData);
    const yValues = seriesData[0].data.map((i) => i.y);
    const cagrValue = getCagrValue(yValues);
    seriesData.push(trendData);

    setSearchTrendsSeries(seriesData);
    setSearchTrendsCagr(cagrValue);

    // Transform the data into { x: [], y: [] } format
    // Group the data by month
    const totalsByMonth = groupByMonth(
      seriesData[0].data.map((item, index) => {
        return {
          x: moment(item.x),
          y: item.y
        };
      })
    );

    const cagrScore = getCagrScore(cagrValue);
    setSearchTrendsScore(cagrScore);

    setSearchTrendsInsights({
      cagr: cagrValue.toFixed(1),
      trendlineSlope: getTrendLineSlope(seriesData[1].data.map((i) => i.y)),
      monthLowAvg: getMonthLowAvg(totalsByMonth),
      monthHighAvg: getMonthHighAvg(totalsByMonth),
      score: cagrScore.toFixed(0)
    });
  }, [searchTrendsKeywords]);

  // Set traffic trends chart data
  useEffect(() => {
    if (isArrayEmpty(industryTrafficData) || !industryTrafficData) return;
    if (!project || isLoadingProject) return;

    function getSelectedCountriesFromFilters(filters) {
      return Object.keys(filters).filter((key) => filters[key]);
    }

    function getDateSeries(data) {
      return [...new Set(data.map((item) => item.date))];
    }

    function reduceDataSeriesForChart(filters, dateSeries, data) {
      const filteredData = [];

      const countries = Object.keys(filters).length
        ? getSelectedCountriesFromFilters(filters)
        : project.countries;

      countries.forEach((country) => {
        filteredData.push({
          name: country,
          data: dateSeries.map((date) => {
            return data
              .filter((data) => data.date === date && data.country === country)
              .map((d) => {
                return d?._sum?.value ? d?._sum?.value : d.value;
              })
              .reduce((a, b) => Number(a) + Number(b), 0);
          })
        });
      });

      return filteredData;
    }

    const dateSeries = getDateSeries(industryTrafficData);
    const monthsOfData = dateSeries.length;

    const categoriesData = dateSeries.map((date) => moment(date).valueOf());
    setTrafficTrendsCategories(categoriesData);

    const seriesData = reduceDataSeriesForChart(filters, dateSeries, industryTrafficData);

    const dataForTrendCalculation = seriesData[0].data.map((item, index) => {
      return {
        x: index + 1,
        y: item
      };
    });
    const trendData = getTrafficChartTrendSeries(dataForTrendCalculation);
    seriesData.push(trendData);

    setTrafficTrendsSeries(seriesData);

    const cagrValue = getCagrValue(
      seriesData[0].data,
      monthsOfData === 24 ? 12 : 4,
      monthsOfData === 24 ? 2 : 4
    );
    setTrafficTrendsCagr(cagrValue);

    // Transform the data into { x: [], y: [] } format
    const xy = seriesData[0].data.map((y, index) => {
      return {
        x: moment(categoriesData[index]),
        y
      };
    });

    // Group the data by month
    const totalsByMonth = groupByMonth(xy);
    const score = getCagrScore(cagrValue);
    setTrafficTrendsScore(score);
    const templateVars = {
      cagr: cagrValue.toFixed(1),
      trendlineSlope: getTrendLineSlope(seriesData[0].data),
      monthLowAvg: getMonthLowAvg(totalsByMonth),
      monthHighAvg: getMonthHighAvg(totalsByMonth),
      score: score.toFixed(0),
      monthsOfData: monthsOfData === 24 ? 24 : 48
    };

    setTrafficTrendsInsights(templateVars);
  }, [industryTrafficData, project]);

  // BRAND CHART STUFF NOW
  useEffect(() => {
    if (isArrayEmpty(brandSearchesData) || !brandSearchesData) return;
    if (!project || isLoadingProject) return;
    if (isBrandSearchesLoading) return;

    const getDateSeries = (data) => {
      // if (!data) return
      return [...new Set(data.map((item) => item.date))];
    };

    const reduceDataSeriesForChart = (filters, dateSeries, data) => {
      if (!dateSeries) return;
      const filteredData = [];

      const groupedByDomain = groupBy(data, 'domain');
      const domains = Object.keys(groupedByDomain);

      // {
      //   bbc.com: [
      //     {
      //       date: '2020-01-01',
      //       volume: 1
      //     },
      //     {
      //       date: '2020-01-02',
      //       volume: 2
      //     }
      //   ]
      // }

      for (const [key, value] of Object.entries(groupedByDomain)) {
        const name = key;
        const data = value.map((val) => ({
          x: val.date,
          y: val.data[0].volume
        }));

        filteredData.push({
          name,
          data,
          last12Months: sum(data.slice(-12).map((i) => Number(i.y))),
          first12Months: sum(data.slice(0, 12).map((i) => Number(i.y)))
        });
      }

      return filteredData;
    };

    const dateSeries = getDateSeries(brandSearchesData);
    const seriesData = reduceDataSeriesForChart(filters, dateSeries, brandSearchesData);

    setBrandSearchCategories(dateSeries.map((date) => moment(date).valueOf()));

    const assets = [project.domain, ...project.competitors];

    const seriesDataForChart = assets.map((asset) => {
      const item = seriesData.find((i) => i.name === asset);

      if (item) {
        return item;
      }
      return { name: asset, data: [] };
    });

    setBrandSearchSeries(seriesDataForChart);

    const k = 13.26588435;
    const maxLast12Months =
      seriesData.length > 0 ? maxBy(seriesData, 'last12Months').last12Months : 0;

    const scores = assets.map((asset) => {
      const item = seriesData.find((i) => i.name === asset);

      if (item) {
        const { name, data, last12Months, first12Months } = item;
        const cagr = (last12Months / first12Months) ** (1 / 3) - 1;
        const logistic = 100 / (1 + Math.exp(-k * (cagr - 0.05)));
        const indexedScore = maxLast12Months > 0 ? 100 * (last12Months / maxLast12Months) : 0;

        let score;

        score = +(0.5 * logistic + 0.5 * indexedScore).toFixed(0);

        return {
          name,
          cagr,
          logistic,
          score,
          first12Months,
          seriesDataItem: item
        };
      } else {
        return {
          name: asset,
          cagr: 0,
          logistic: 0,
          score: null,
          first12Months: 0,
          seriesDataItem: { data: [] }
        };
      }
    });

    // If only 1 brand has data, ALL scores should be null
    if (scores.length === 1) {
      scores.forEach((score) => {
        score.score = null;
      });
    }

    const targetDomainHasData = scores[0].name === project?.domain;

    let targetGrowthRateLowerThanFastestGrowingCompetitor;
    if (!targetDomainHasData || scores.length <= 1) {
      targetGrowthRateLowerThanFastestGrowingCompetitor = false;
    } else {
      const targetCagrPlusFivePercent = scores[0].cagr * 1.05;
      const fastestGrowingCompetitor = maxBy(scores.slice(1), 'cagr');
      const sumTrafficVolumeY1 = fastestGrowingCompetitor.first12Months;

      targetGrowthRateLowerThanFastestGrowingCompetitor =
        targetCagrPlusFivePercent < fastestGrowingCompetitor.cagr && sumTrafficVolumeY1 > 10000;
    }

    const brandSearchChartInsights = {
      targetName: project?.domain || '',
      score: +scores[0].score,
      maxScore: maxBy(scores, 'score').score,
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score,
      minScoreOwner: minBy(scores, 'score').name,
      scores,
      targetGrowthRateLowerThanFastestGrowingCompetitor
    };

    setBrandSearchInsights(brandSearchChartInsights);

    setBrandSearchScore(scoreOrNull(scores[0].score));
  }, [brandSearchesData, project]);

  // Set overview chart data
  useEffect(() => {
    if (isArrayEmpty(websiteTrafficVolumesData) || !websiteTrafficVolumesData) return;
    if (!project || isLoadingProject) return;

    const filteredData = websiteTrafficVolumesData
      .filter((item) => item.length)
      .map((c) => c.slice(-12));

    const competitorNames = filteredData.map((fd) => fd[0].domain);
    setCompetitors(competitorNames);

    const seriesData = [
      {
        name: 'Traffic',
        data: filteredData.map((item) => {
          // item is array of 12 months with { date, domain, _sum }
          return item.reduce((acc, ret) => {
            acc = acc + Math.round(+ret._sum.value);
            return acc;
          }, 0);
        })
      }
    ];

    setSeries(seriesData);

    const overviewScore = (seriesData[0].data[0] / max(seriesData[0].data)) * 100;
    setOverviewScore(overviewScore);

    const scores = seriesData[0].data.map((item, index) => {
      return {
        name: competitorNames[index],
        score: (item / max(seriesData[0].data)) * 100
      };
    });

    const overviewChartInsights = {
      maxScore: maxBy(scores, 'score').score.toFixed(0),
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score.toFixed(0),
      minScoreOwner: minBy(scores, 'score').name,
      avgScore: meanBy(scores, 'score').toFixed(0),
      trendlineSlope: 'MISSING',
      monthLowAvg: 'MISSING',
      monthHighAvg: 'MISSING',
      score: overviewScore.toFixed(0),
      targetName: project?.domain || '',
      scores
    };

    setOverviewChartInsights(overviewChartInsights);
  }, [websiteTrafficVolumesData, project]);

  // Set trustpilot chart data
  useEffect(() => {
    if (isArrayEmpty(trustPilotData) || !trustPilotData) return;

    const scores = trustPilotData.map((item, index) => {
      if (!item.businessUnit) {
        return {
          name: item.domain,
          score: null,
          trustpilotScore: 0,
          activeManagementScore: 0,
          numberOfReviews: 0
        };
      }

      const score = item.businessUnit.trustScore * 20;

      const profileClaimed = item.businessUnit.isClaimed;
      const askingForReviews = item.businessUnit?.activity?.isAskingForReviews;
      const negativeReviewsReplyRate =
        item.businessUnit?.activity?.replyBehavior.replyPercentage.toFixed(0);
      const daysToReply = item.businessUnit?.activity?.replyBehavior.averageDaysToReply;

      const trustpilotScore = item.businessUnit?.trustScore;
      const numberOfReviews = item.businessUnit?.numberOfReviews || 0;

      const activeManagementScore =
        33 * Number(profileClaimed) +
        33 * Number(askingForReviews) +
        34 * (negativeReviewsReplyRate / (100 * (daysToReply < 1 ? 1 : daysToReply)));

      return {
        name: item.domain,
        score: +score,
        trustpilotScore,
        activeManagementScore,
        numberOfReviews
      };
    });

    if (scores.filter((s) => s.score !== null).length === 1) {
      scores.forEach((score) => {
        score.score = null;
      });
    }

    const atLeastOneScore = scores.some((score) => score.score !== null);

    const trustpilotChartInsights = {
      maxScore: atLeastOneScore ? maxBy(scores, 'score').score.toFixed(0) : 0,
      maxScoreOwner: atLeastOneScore ? maxBy(scores, 'score').name : 'None',
      minScore: atLeastOneScore ? minBy(scores, 'score').score.toFixed(0) : 0,
      minScoreOwner: atLeastOneScore ? minBy(scores, 'score').name : 'None',
      avgScore: atLeastOneScore ? meanBy(scores, 'score').toFixed(0) : 0,
      maxAMScore: atLeastOneScore
        ? maxBy(scores, 'activeManagementScore').activeManagementScore.toFixed(0)
        : 0,
      maxAMScoreOwner: atLeastOneScore ? maxBy(scores, 'activeManagementScore').name : 'None',
      minAMScore: atLeastOneScore
        ? minBy(scores, 'activeManagementScore').activeManagementScore.toFixed(0)
        : 0,
      minAMScoreOwner: atLeastOneScore ? minBy(scores, 'activeManagementScore').name : 'None',
      avgAMScore: atLeastOneScore ? meanBy(scores, 'activeManagementScore').toFixed(0) : 0,
      targetName: project?.domain,
      score: scoreOrNull(scores[0].score),
      scores
    };

    setTrustpilotChartInsights(trustpilotChartInsights);

    const trustpilotScore = scores[0].score;
    setTrustpilotScore(trustpilotScore);
  }, [trustPilotData, project]);

  // Calculate traffic source breakdown score
  useEffect(() => {
    if (isArrayEmpty(trafficSourceVolumesData) || !trafficSourceVolumesData) return;
    if (!project || isLoadingProject) return;

    const categories = trafficSourceVolumesData.map((item) => {
      return item.domain;
    });
    setTrafficSourceBreakdownCategories(categories);

    const series = trafficBySourceGroupConfig.map((config) => {
      return {
        name: config.name,
        // Returns an array for each group, like [ 5%, 16%, 10% ]
        data: trafficSourceVolumesData.map((dataByDomain) => {
          const total = Object.values(dataByDomain.groups).reduce((acc2, group) => {
            const { visits } = group[0];
            const visitsTotal = visits.reduce((acc3, visit) => {
              acc3 += visit.paid + visit.organic;
              return acc3;
            }, 0);

            acc2 += visitsTotal;

            return acc2;
          }, 0);

          const group = dataByDomain.groups[config.key || config.name];
          if (!group) return 0;

          const visits = group[0].visits;

          const groupTotal = visits.reduce((acc, visit) => {
            const { paid, organic } = visit;

            if (config.paid) {
              acc += paid;
            }

            if (config.organic) {
              acc += organic;
            }

            return acc;
          }, 0);

          return groupTotal / total;
        })
      };
    });

    setTrafficSourceBreakdownSeries(series);

    const sumVisits = (visits, filterFn) =>
      visits.reduce((acc, { organic, paid }) => acc + filterFn(organic, paid), 0);

    const flattenedData = trafficSourceVolumesData.map((item) => {
      const freeTraffic =
        sumVisits(item.groups.Direct[0].visits, (organic) => organic) +
        sumVisits(item.groups.Search[0].visits, (organic) => organic);

      const allTraffic = Object.values(item.groups).reduce(
        (result, [{ visits }]) => result + sumVisits(visits, (organic, paid) => organic + paid),
        0
      );
      return {
        ...item,
        freeTraffic,
        allTraffic,
        freeShare: allTraffic > 0 ? freeTraffic / allTraffic : 0
      };
    });

    const maxFreeTraffic = max(flattenedData.map((item) => item.freeTraffic));
    const volumeScore = (flattenedData[0].freeTraffic / maxFreeTraffic) * 100;

    const freeShare =
      flattenedData[0].allTraffic > 0
        ? flattenedData[0].freeTraffic / flattenedData[0].allTraffic
        : 0;
    const a = 100 * freeShare;
    const b = max(flattenedData.map((item) => item.freeTraffic / item.allTraffic));
    const shareScore = a / b;

    const targetDomainScore = 0.5 * volumeScore + 0.5 * shareScore;

    setTrafficSourceBreakdownScore(targetDomainScore);

    const scores = flattenedData.map((item) => {
      const volumeScore = (item.freeTraffic / maxFreeTraffic) * 100;

      const shareScore = (100 * item.freeShare) / maxBy(flattenedData, 'freeShare').freeShare;

      const score = 0.5 * volumeScore + 0.5 * shareScore;

      return {
        name: item.domain,
        volumeScore,
        shareScore,
        score
      };
    });

    const trafficSourceBreakdownChartInsights = {
      maxScore: maxBy(scores, 'score').score.toFixed(0),
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score.toFixed(0),
      minScoreOwner: minBy(scores, 'score').name,
      avgScore: meanBy(scores, 'score').toFixed(0),
      trendlineSlope: 'MISSING',
      monthLowAvg: 'MISSING',
      monthHighAvg: 'MISSING',
      score: targetDomainScore.toFixed(0),
      targetName: project.domain,
      scores,
      freeShare
    };

    setTrafficSourceBreakdownInsights(trafficSourceBreakdownChartInsights);
  }, [trafficSourceVolumesData, project]);

  // Calculate mobile traffic source breakdown score
  useEffect(() => {
    if (
      isArrayEmpty(trafficMobileSourceVolumesData) ||
      !trafficMobileSourceVolumesData ||
      Object.keys(trafficMobileSourceVolumesData).length === 0
    )
      return;
    if (!project || isLoadingProject) return;

    const categories = trafficMobileSourceVolumesData.map((item) => {
      return item.domain;
    });
    setMobileTrafficSourceBreakdownCategories(categories);

    const series = mobileTrafficBySourceGroupConfig.map((config) => {
      const totals = calculateMobileTrafficSourceTotals(trafficMobileSourceVolumesData, config);
      return {
        name: config.name,
        // Returns an array for each group, like [ 5%, 16%, 10% ]
        data: totals.map((total) => {
          const percentage = total.groupTotal / total.total;
          return percentage || 0;
        })
      };
    });
    setMobileTrafficSourceBreakdownSeries(series);
  }, [trafficMobileSourceVolumesData, project]);

  // Combined traffic source breakdown
  useEffect(() => {
    if (
      isArrayEmpty(trafficMobileSourceVolumesData) ||
      !trafficMobileSourceVolumesData ||
      Object.keys(trafficMobileSourceVolumesData).length === 0
    )
      return;
    if (isArrayEmpty(trafficSourceVolumesData) || !trafficSourceVolumesData) return;
    if (!project || isLoadingProject) return;

    // Map over each config and calculate the totals for each domain
    const series = trafficBySourceGroupConfig.map((config) => {
      let mobileTotals = [];
      if (config.name === 'Search / Paid' || config.name === 'Search / Organic') {
        const mobileConfig = mobileTrafficBySourceGroupConfig.find(
          (item) => item.name === config.name
        );

        mobileTotals = calculateMobileTrafficSourceTotals(
          trafficMobileSourceVolumesData,
          mobileConfig
        );
      } else {
        mobileTotals = calculateMobileTrafficSourceTotals(trafficMobileSourceVolumesData, config);
      }
      const desktopTotals = calculateTrafficSourceTotals(trafficSourceVolumesData, config);

      return {
        name: config.name,

        data: mobileTotals.map((item, index) => {
          const groupTotal = item.groupTotal + desktopTotals[index].groupTotal;
          const total = item.total + desktopTotals[index].total;
          return groupTotal / total;
        })
      };
    });

    setCombinedTrafficSourceBreakdownSeries(series);

    const trafficSums = calculateSumOfTraffic(trafficSourceVolumesData);
    const mobileTrafficSums = calculateSumOfMobileTraffic(trafficMobileSourceVolumesData);

    const mergedSums = mergeTrafficSourceSums(trafficSums, mobileTrafficSums);

    const maxFreeTraffic = max(mergedSums.map((item) => item.freeTraffic));
    const volumeScore = (mergedSums[0].freeTraffic / maxFreeTraffic) * 100;

    const freeShare =
      mergedSums[0].allTraffic > 0 ? mergedSums[0].freeTraffic / mergedSums[0].allTraffic : 0;
    const a = 100 * freeShare;
    const b = max(mergedSums.map((item) => item.freeTraffic / item.allTraffic));
    const shareScore = a / b;

    const targetDomainScore = 0.5 * volumeScore + 0.5 * shareScore;

    setCombinedTrafficSourceBreakdownScore(targetDomainScore);

    const scores = mergedSums.map((item) => {
      const volumeScore = (item.freeTraffic / maxFreeTraffic) * 100;

      const shareScore = (100 * item.freeShare) / maxBy(mergedSums, 'freeShare').freeShare;

      const score = 0.5 * volumeScore + 0.5 * shareScore;

      return {
        name: item.domain,
        volumeScore,
        shareScore,
        score
      };
    });

    const trafficSourceBreakdownChartInsights = {
      maxScore: maxBy(scores, 'score').score.toFixed(0),
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score.toFixed(0),
      minScoreOwner: minBy(scores, 'score').name,
      avgScore: meanBy(scores, 'score').toFixed(0),
      trendlineSlope: 'MISSING',
      monthLowAvg: 'MISSING',
      monthHighAvg: 'MISSING',
      score: targetDomainScore.toFixed(0),
      targetName: project.domain,
      scores,
      freeShare
    };

    setCombinedTrafficSourceBreakdownInsights(trafficSourceBreakdownChartInsights);
  }, [mobileTrafficSourceBreakdownSeries, trafficSourceBreakdownSeries, project]);

  // Set traffic by month chart data
  useEffect(() => {
    if (isArrayEmpty(websiteTrafficVolumesData) || !websiteTrafficVolumesData) return;
    if (!project || isLoadingProject) return;

    const categories = websiteTrafficVolumesData[0].map((item) => {
      return moment(item.date);
    });
    setTrafficByMonthCategories(categories);

    const filteredData = websiteTrafficVolumesData.filter((item) => item.length);
    const monthsOfData = filteredData[0].length;
    const series = filteredData.map((item, index) => {
      const data = item.map((values) => values._sum.value);
      const last12Months = sum(data.slice(-(monthsOfData / 2)));
      const previous12Months = sum(data.slice(0, monthsOfData / 2));
      const last12vsPrevious12 =
        last12Months > 0 && previous12Months > 0 ? last12Months / previous12Months - 1 : 0;

      const k = 13.26588435;
      const logistic = 100 / (1 + Math.exp((-k / 3) * (last12vsPrevious12 - 0.05)));

      return {
        name: item[0].domain,
        data,
        last12vsPrevious12,
        logistic,
        last12Months,
        previous12Months,
        growthRate: last12Months > 0 && previous12Months > 0 ? last12Months / previous12Months : 0
      };
    });

    const logistic = series[0].logistic;
    const maxLogistic = max(series.map((item) => item.logistic));
    const indexedScore = (logistic / maxLogistic) * 100;
    const score = (0.5 * logistic + 0.5 * indexedScore).toFixed(0);
    setTrafficByMonthScore(+score);

    const scores = series.map((item) => {
      const logistic = item.logistic;
      const indexedScore = (logistic / maxLogistic) * 100;
      const score = (0.5 * logistic + 0.5 * indexedScore).toFixed(0);

      return {
        name: item.name,
        logistic,
        indexedScore,
        score: +score
      };
    });

    const dataForTrendCalculation = series[0].data.map((item, index) => {
      return {
        x: index + 1,
        y: item
      };
    });

    const growthForTarget = series[0].growthRate;
    const growthForTargetPlusFivePercent = growthForTarget * 1.05;
    const fastestGrowingCompetitor = maxBy(series.slice(1), 'growthRate');
    const fastestGrowingCompetitorFirstSixMonthsSum = fastestGrowingCompetitor.previous6Months;
    const maxGrowthForCompetitors = fastestGrowingCompetitor.growthRate;

    const targetGrowthRateLowerThanFastestGrowingCompetitor =
      growthForTargetPlusFivePercent < maxGrowthForCompetitors &&
      fastestGrowingCompetitorFirstSixMonthsSum > 30000;

    const trafficByMonthChartInsights = {
      maxScore: maxBy(scores, 'score').score,
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score,
      minScoreOwner: minBy(scores, 'score').name,
      avgScore: meanBy(scores, 'score'),
      cagr: '*****TBC*****',
      // trendlinesSlope should be up or down depending on last5vsPrevious6 value
      // If the last 6 months vs the previous 6 months is a positive number then up else down
      trendlineSlope: (series[0].last12vsPrevious12 * 100).toFixed(1) > 0 ? 'up' : 'down',
      targetName: project.domain,
      score: (+score).toFixed(0),
      L12mVp12m: (series[0].last12vsPrevious12 * 100).toFixed(1),
      maxL12mVpsmOwner: maxBy(series, 'last12vsPrevious12').name,
      maxL12mVp12m: (maxBy(series, 'last12vsPrevious12').last12vsPrevious12 * 100).toFixed(1),
      minL12mVpsmOwner: minBy(series, 'last12vsPrevious12').name,
      minL12mVp12m: (minBy(series, 'last12vsPrevious12').last12vsPrevious12 * 100).toFixed(1),
      scores,
      targetDecliningTraffic: series[0].last12Months < series[0].previous12Months,
      targetGrowthRateLowerThanFastestGrowingCompetitor,
      monthsOfData: monthsOfData
    };

    setTrafficByMonthSeries(series);
    setTrafficByMonthChartInsights(trafficByMonthChartInsights);
  }, [websiteTrafficVolumesData, project]);

  // Set website engagement chart data
  useEffect(() => {
    if (isArrayEmpty(websiteEngagementData) || !websiteEngagementData) return;

    const data = websiteEngagementData;

    const visitDurations = data.map((d) => d.find((i) => 'visitDuration' in i));
    const maxVisitDurationObject = maxBy(visitDurations, 'visitDuration');
    const maxVisitDuration = maxVisitDurationObject.visitDuration;

    const pagesPerVisit = data.map((d) => d.find((i) => 'pagesPerVisit' in i));
    const maxPagesPerVisitObject = maxBy(pagesPerVisit, 'pagesPerVisit');
    const maxPagesPerVisit = maxPagesPerVisitObject.pagesPerVisit;

    const bounceRates = data.map((d) => d.find((i) => 'bounceRate' in i));
    const maxBounceRateObject = maxBy(bounceRates, 'bounceRate');
    const maxBounceRate = maxBounceRateObject.bounceRate;

    setWebsiteEngagementDataForChart(data);

    const scores = data.map((item, index) => {
      const visitDuration = item.find((i) => 'visitDuration' in i).visitDuration;
      const pagesPerVisit = item.find((i) => 'pagesPerVisit' in i).pagesPerVisit;
      const bounceRate = item.find((i) => 'bounceRate' in i).bounceRate;

      const visitDurationScore = (visitDuration / maxVisitDuration) * 100;
      const pagesPerVisitScore = (pagesPerVisit / maxPagesPerVisit) * 100;
      const bounceRateScore = (bounceRate / maxBounceRate) * 100;

      const score = (visitDurationScore + pagesPerVisitScore - bounceRateScore + 100) / 3;

      return {
        name: item[0].domain,
        visitDurationScore,
        pagesPerVisitScore,
        bounceRateScore,
        score: +score
      };
    });

    const websiteEngagementChartInsights = {
      maxScore: maxBy(scores, 'score').score.toFixed(0),
      maxScoreOwner: maxBy(scores, 'score').name,
      minScore: minBy(scores, 'score').score.toFixed(0),
      minScoreOwner: minBy(scores, 'score').name,
      avgScore: meanBy(scores, 'score').toFixed(0),
      targetName: project?.domain,
      score: scores[0].score.toFixed(0),
      scores
    };

    setWebsiteEngagementChartInsights(websiteEngagementChartInsights);

    const websiteEngagementScore = scores[0].score;
    setWebsiteEngagementScore(websiteEngagementScore);
  }, [websiteEngagementData, project]);

  // Social Page

  // Set chart data for Social Audience chart
  useEffect(() => {
    if (isArrayEmpty(socialbladeData) || !socialbladeData) return;

    const { series, categories } = {
      series: [
        {
          name: 'Facebook',
          data: socialAudienceChartData.data.map((item) => {
            if (!item.socialbladeData?.facebook || item.socialbladeData?.facebook.error) {
              return 0;
            }
            return item.socialbladeData.facebook.statistics.total.likes;
          })
        },
        {
          name: 'Twitter',
          data: socialAudienceChartData.data.map((item) => {
            if (!item.socialbladeData?.twitter || item.socialbladeData?.twitter.error) {
              return 0;
            }
            return item.socialbladeData?.twitter.statistics.total.followers;
          })
        },
        {
          name: 'Instagram',
          data: socialAudienceChartData.data.map((item) => {
            if (!item.socialbladeData?.instagram || item.socialbladeData?.instagram.error) {
              return 0;
            }
            return item.socialbladeData.instagram.statistics.total.followers;
          })
        }
      ],
      categories: socialAudienceChartData.data.map((d) => d.domain)
    };

    setSocialAudienceSeries(series);
    setSocialAudienceCategories(categories);

    const transformedData = socialbladeData.map((item) => {
      const facebookLikes =
        !item.socialbladeData?.facebook || item.socialbladeData?.facebook.error
          ? 0
          : item.socialbladeData.facebook.statistics.total.likes;
      const twitterFollowers =
        !item.socialbladeData?.twitter || item.socialbladeData?.twitter.error
          ? 0
          : item.socialbladeData?.twitter.statistics.total.followers;
      const instagramFollowers =
        !item.socialbladeData?.instagram || item.socialbladeData?.instagram.error
          ? 0
          : item.socialbladeData?.instagram.statistics.total.followers;

      return {
        ...item,
        totalFollowers: facebookLikes + twitterFollowers + instagramFollowers
      };
    });

    const maxTotalFollowers = maxBy(transformedData, 'totalFollowers').totalFollowers;

    const scores = transformedData.map((item, index) => {
      if (item.totalFollowers === 0) {
        return {
          name: item.domain,
          score: null
        };
      }
      const indexedScore = 100 * (item.totalFollowers / maxTotalFollowers);
      const relativeShareScore =
        100 * (item.totalFollowers / sum(transformedData.map((i) => i.totalFollowers)));

      const score = (indexedScore + relativeShareScore) / 2;

      return {
        name: item.domain,
        score
      };
    });

    // If only ONE brand has data, then set ALL scores to null
    if (scores.filter((item) => item.score !== null).length === 1) {
      scores.forEach((item, index) => {
        if (item.score === null) {
          scores[index].score = null;
        }
      });
    }

    const atLeastOneScoreIsNotNull = scores.some((item) => item.score !== null);

    const socialAudienceChartInsights = {
      maxScore: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').score.toFixed(0) : 0,
      maxScoreOwner: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').name : 'None',
      minScore: atLeastOneScoreIsNotNull ? minBy(scores, 'score').score.toFixed(0) : 0,
      minScoreOwner: atLeastOneScoreIsNotNull ? minBy(scores, 'score').name : 'None',
      avgScore: atLeastOneScoreIsNotNull ? meanBy(scores, 'score').toFixed(0) : 0,
      targetName: project?.domain,
      score: scoreOrNull(scores[0].score),
      scores
    };

    setSocialbladeChartInsights(socialAudienceChartInsights);

    const socialAudienceChartScore = scores[0].score;
    setSocialbladeScore(socialAudienceChartScore);
  }, [socialbladeData, project]);

  // Set chart data for SocialFollowers vs Engagement chart
  useEffect(() => {
    if (isArrayEmpty(socialbladeData) || !socialbladeData) return;

    const data = socialbladeData;
    const transformedData = data.map((item) => {
      const itemData = item.socialbladeData;
      const facebookLikes =
        !itemData?.facebook || itemData?.facebook.error
          ? 0
          : itemData.facebook.statistics.total.likes;
      const twitterFollowers =
        !itemData?.twitter || itemData?.twitter.error
          ? 0
          : itemData.twitter.statistics.total.followers;
      const instagramFollowers =
        !itemData?.instagram || itemData?.instagram.error
          ? 0
          : itemData.instagram.statistics.total.followers;

      const facebookEngagements =
        (itemData?.facebook &&
          itemData?.facebook.daily &&
          mean(itemData.facebook.daily.map((d) => d.talking_about))) ||
        0;
      const instagramEngagements =
        (itemData?.instagram?.statistics?.average?.likes || 0) +
        (itemData?.instagram?.statistics?.average?.comments || 0);
      const totalEngagements = facebookEngagements + instagramEngagements;

      return {
        ...item,
        totalFollowers: facebookLikes + instagramFollowers,
        totalEngagements
      };
    });

    const maxTotalFollowers = maxBy(transformedData, 'totalFollowers').totalFollowers;
    const maxTotalEngagements = maxBy(transformedData, 'totalEngagements').totalEngagements;

    const calculateEngagementsIndexedScore = (totalEngagements, maxTotalEngagements) => {
      if (maxTotalEngagements === 0) return 0;

      return 100 * (totalEngagements / maxTotalEngagements);
    };

    const calculateEngagementsRelativeShareScore = (itemTotalEngagements, totalEngagements) => {
      if (totalEngagements === 0) return 0;

      return 100 * (itemTotalEngagements / totalEngagements);
    };

    const scores = transformedData.map((item, index) => {
      // null condition for: If there is no data for any of the brands the scores should all be zero (null also acceptable). Else all brands with no data should be given the mean score of those for which there is data.
      if (item.totalFollowers === 0 && item.totalEngagements === 0) {
        return {
          name: item.domain,
          score: null,
          totalFollowers: 0,
          totalEngagements: 0
        };
      }

      const followersIndexedScore = 100 * (item.totalFollowers / maxTotalFollowers);
      const followersRelativeShareScore =
        100 * (item.totalFollowers / sum(transformedData.map((i) => i.totalFollowers)));
      const engagementsIndexedScore = calculateEngagementsIndexedScore(
        item.totalEngagements,
        maxTotalEngagements
      );
      const engagementsRelativeShareScore = calculateEngagementsRelativeShareScore(
        item.totalEngagements,
        sum(transformedData.map((i) => i.totalEngagements))
      );

      const score =
        (followersIndexedScore +
          followersRelativeShareScore +
          engagementsIndexedScore +
          engagementsRelativeShareScore) /
        4;

      return {
        name: item.domain,
        score,
        totalFollowers: item.totalFollowers,
        totalEngagements: item.totalEngagements
      };
    });

    // If only ONE brand has data, then set ALL scores to null
    if (scores.filter((item) => item.score !== null).length === 1) {
      scores.forEach((item, index) => {
        if (item.score === null) {
          scores[index].score = null;
        }
      });
      // If there is no data for any of the brands the scores should all be null
    } else if (scores.every((item) => item.score === null)) {
      scores.forEach((item, index) => {
        scores[index].score = null;
      });
    }

    const atLeastOneScoreIsNotNull = scores.some((item) => item.score !== null);

    const targetHasLowSocialEngagement =
      transformedData[0].totalEngagements < 0.33 * maxTotalEngagements;

    const followersVsEngagementsChartInsights = {
      maxScore: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').score.toFixed(0) : 0,
      maxScoreOwner: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').name : 'None',
      minScore: atLeastOneScoreIsNotNull ? minBy(scores, 'score').score.toFixed(0) : 0,
      minScoreOwner: atLeastOneScoreIsNotNull ? minBy(scores, 'score').name : 'None',
      avgScore: atLeastOneScoreIsNotNull ? meanBy(scores, 'score').toFixed(0) : 0,
      targetName: project?.domain,
      score: scoreOrNull(scores[0].score),
      scores,
      lowSocialEngagement: targetHasLowSocialEngagement
    };

    setFollowersVsEngagementsChartInsights(followersVsEngagementsChartInsights);

    const followersVsEngagementsChartScore = scoreOrNull(scores[0].score);
    setFollowersVsEngagementsScore(followersVsEngagementsChartScore);
  }, [socialbladeData, project]);

  // TECH

  // Set chart data for Site Optimisation chart
  useEffect(() => {
    if (isArrayEmpty(pagespeedInsightsData) || !pagespeedInsightsData) return;

    const transformedData = pagespeedInsightsData;

    const scores = transformedData.map((item, index) => {
      return {
        name: item.domain,
        score: item.overall || null
      };
    });

    const atLeastOneScoreIsNotNull = scores.some((item) => item.score !== null);

    const siteOptimisationInsightsData = {
      maxScore: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').score.toFixed(0) : 0,
      maxScoreOwner: atLeastOneScoreIsNotNull ? maxBy(scores, 'score').name : 'None',
      minScore: atLeastOneScoreIsNotNull ? minBy(scores, 'score').score.toFixed(0) : 0,
      minScoreOwner: atLeastOneScoreIsNotNull ? minBy(scores, 'score').name : 'None',
      avgScore: atLeastOneScoreIsNotNull ? meanBy(scores, 'score').toFixed(0) : 0,
      targetName: project?.domain,
      score: scoreOrNull(scores[0].score),
      scores
    };

    setSiteOptimisationInsights(siteOptimisationInsightsData);
    setSiteOptimisationScore(scoreOrNull(scores[0].score));
  }, [pagespeedInsightsData, project]);

  // Summary page
  useEffect(() => {
    const marketScoreValue = (searchTrendsScore + trafficTrendsScore) / 2;
    setMarketScore(marketScoreValue.toFixed(0));
  }, [searchTrendsScore, trafficTrendsScore]);

  // Ranking scores
  useEffect(() => {
    if (!project || isLoadingProject) return;
    if (
      overviewScore === undefined ||
      trafficSourceBreakdownScore === undefined ||
      trafficByMonthScore === undefined ||
      websiteEngagementScore === undefined ||
      brandSearchScore === undefined
    ) {
      return;
    }

    if (!overviewChartInsights.scores) return;
    if (!trafficSourceBreakdownInsights.scores) return;
    if (!trafficByMonthChartInsights.scores) return;
    if (!websiteEngagementChartInsights.scores) return;
    if (!brandSearchInsights.scores) return;

    // Some projects do not have trustpilot or socialblade data, so we do not require them here
    // if (!trustpilotChartInsights.scores) return
    // if (!socialbladeChartInsights.scores) return

    // Not all projects have a site optimisation score

    const scoresArray = [
      overviewScore,
      trafficSourceBreakdownScore,
      trafficByMonthScore,
      websiteEngagementScore,
      brandSearchScore,
      trustpilotScore,
      socialbladeScore,
      followersVsEngagementsScore,
      siteOptimisationScore
    ];

    const filteredScoresArray = scoresArray.filter((item) => item !== undefined && item !== null);
    const overallScoreValue = sum(filteredScoresArray) / filteredScoresArray.length;

    const assets = [project.domain, ...project.competitors];

    const rankedAssets = sortBy(
      assets.map((asset, index) => {
        const overviewScore = overviewChartInsights.scores.find((i) => i.name === asset).score;
        const trafficSourceBreakdownInsightsType =
          Object.keys(combinedTrafficSourceBreakdownInsights).length === 0
            ? trafficSourceBreakdownInsights
            : combinedTrafficSourceBreakdownInsights;
        const trafficSourceBreakdownScore = trafficSourceBreakdownInsightsType.scores.find(
          (i) => i.name === asset
        ).score;
        const trafficByMonthScore = trafficByMonthChartInsights.scores.find(
          (i) => i.name === asset
        ).score;
        const websiteEngagementScore = websiteEngagementChartInsights.scores.find(
          (i) => i.name === asset
        ).score;
        const brandScore = brandSearchInsights.scores.find((i) => i.name === asset)
          ? brandSearchInsights.scores.find((i) => i.name === asset).score
          : undefined;
        const trustpilotScore =
          trustpilotChartInsights.scores &&
          trustpilotChartInsights.scores.find((i) => i.name === asset)
            ? trustpilotChartInsights.scores.find((i) => i.name === asset).score
            : undefined;
        const socialAudienceScore =
          socialbladeChartInsights.scores &&
          socialbladeChartInsights.scores.find((i) => i.name === asset).score;
        const followersVsEngagementsScore =
          followersVsEngagementsChartInsights.scores &&
          followersVsEngagementsChartInsights.scores.find((i) => i.name === asset).score;
        const siteOptimisationScore =
          siteOptimisationInsights.scores &&
          siteOptimisationInsights.scores.find((i) => i.name === asset).score;

        const scoresArray = [
          overviewScore,
          trafficSourceBreakdownScore,
          trafficByMonthScore,
          websiteEngagementScore,
          brandScore,
          trustpilotScore,
          socialAudienceScore,
          followersVsEngagementsScore,
          siteOptimisationScore
        ].filter((item) => item !== undefined && item !== null);

        const score = sum(scoresArray) / scoresArray.length;

        return {
          name: asset,
          score: +score
        };
      }),
      'score'
    )
      .reverse()
      .map((asset, index) => {
        return {
          ...asset,
          rank: index + 1
        };
      });

    const rankingInsightsData = {
      overallScore: overallScoreValue.toFixed(0),
      rankedAssets,
      overallRank: rankedAssets.find((i) => i.name === project.domain).rank,
      maxOverallScore: maxBy(rankedAssets, 'score').score.toFixed(0),
      maxOverallScoreOwner: maxBy(rankedAssets, 'score').name,
      minOverallScore: minBy(rankedAssets, 'score').score.toFixed(0),
      minOverallScoreOwner: minBy(rankedAssets, 'score').name
    };

    setRankingInsights(rankingInsightsData);
  }, [
    overviewScore,
    trafficSourceBreakdownScore,
    trafficByMonthScore,
    websiteEngagementScore,
    overviewChartInsights,
    trafficSourceBreakdownInsights,
    trafficByMonthChartInsights,
    websiteEngagementChartInsights,
    siteOptimisationInsights,
    combinedTrafficSourceBreakdownScore
  ]);

  useEffect(() => {
    if (isLoadingProject) return <Loading />;
    setCountries(project.countries);
  }, [project]);

  // Check for errors
  const possibleErrors = [
    industrySearchesError,
    industryTrafficError,
    websiteTrafficVolumesError,
    trafficSourceVolumesError,
    trafficMobileSourceVolumesError,
    websiteEngagementError,
    brandSearchesError,
    trustPilotError,
    socialbladeError
  ];

  // if (possibleErrors.some((error) => error)) {
  //   console.log(error);
  //   throw new Error('Issue fetching data');
  // }

  // ================================================================
  // Chart data objects to be passed into sub components
  // ================================================================

  // Chart data objects to be passed into Market Background component
  const searchTrendsChartData = {
    data: industrySearchesData,
    series: searchTrendsSeries,
    categories: searchTrendsCategories,
    keywords: searchTrendsKeywords,
    score: searchTrendsScore,
    insights: searchTrendsInsights
  };

  const trafficTrendsChartData = {
    data: industryTrafficData,
    series: trafficTrendsSeries,
    categories: trafficTrendsCategories,
    score: trafficTrendsScore,
    insights: trafficTrendsInsights
  };
  // Chart data objects to be passed into Competitor Analysis component
  const overviewChartData = {
    series,
    competitors,
    score: overviewScore,
    insights: overviewChartInsights
  };

  const trafficSourceBreakdownChartData = {
    score: trafficSourceBreakdownScore,
    insights: trafficSourceBreakdownInsights,
    series: trafficSourceBreakdownSeries,
    categories: trafficSourceBreakdownCategories
  };

  const mobileTrafficSourceBreakdownChartData = {
    series: mobileTrafficSourceBreakdownSeries,
    categories: mobileTrafficSourceBreakdownCategories
  };

  const combinedTrafficSourceBreakdownChartData = {
    score: combinedTrafficSourceBreakdownScore,
    insights: combinedTrafficSourceBreakdownInsights,
    series: combinedTrafficSourceBreakdownSeries,
    categories: trafficSourceBreakdownCategories
  };

  const trafficByMonthChartData = {
    series: trafficByMonthSeries,
    categories: trafficByMonthCategories,
    score: trafficByMonthScore,
    insights: trafficByMonthChartInsights
  };

  const websiteEngagementChartData = {
    data: websiteEngagementData,
    score: websiteEngagementScore,
    insights: websiteEngagementChartInsights
  };

  // BRAND STUFF HERE:
  const brandSearchesChartData = {
    data: brandSearchesData,
    series: brandSearchSeries,
    categories: brandSearchCategories,
    insights: brandSearchInsights,
    score: brandSearchScore
  };

  const trustpilotChartData = {
    data: trustPilotData,
    score: trustpilotScore,
    insights: trustpilotChartInsights
  };

  // SOCIAL PAGE CHART DATA:

  const socialAudienceChartData = {
    data: socialbladeData,
    score: socialbladeScore,
    insights: socialbladeChartInsights,
    series: socialAudienceSeries,
    categories: socialAudienceCategories
  };

  const followersVsEngagementsChartData = {
    data: socialbladeData,
    score: followersVsEngagementsScore,
    insights: followersVsEngagementsChartInsights
  };

  const siteOptimisationChartData = {
    data: pagespeedInsightsData,
    score: siteOptimisationScore,
    insights: siteOptimisationInsights
  };

  const summaryData = {
    insights: {
      ...rankingInsights,
      marketScore,
      targetName: project?.domain
    }
  };

  const getSummaryTrafficSourceBreakdownChartData = () => {
    return Object.keys(combinedTrafficSourceBreakdownChartData.insights).length === 0
      ? trafficSourceBreakdownChartData
      : combinedTrafficSourceBreakdownChartData;
  };

  return (
    <>
      <Route
        path="/projects/:projectId/performance"
        render={(props) => {
          return (
            <CompetitorAnalysis
              projectId={projectId}
              project={project}
              isLoadingProject={isLoadingProject}
              isErrorProject={isErrorProject}
              countries={countries}
              filters={filters}
              applyFilters={applyFilters}
              websiteTrafficVolumes={websiteTrafficVolumesData}
              isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
              overviewChartData={overviewChartData}
              trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
              isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
              trafficSourceVolumesData={trafficSourceVolumesData}
              isMobileTrafficSourceVolumesLoading={isMobileTrafficSourceVolumesLoading}
              mobileTrafficVolumesData={trafficMobileSourceVolumesData}
              mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
              combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
              trafficByMonthChartData={trafficByMonthChartData}
              isWebsiteEngagementLoading={isWebsiteEngagementLoading}
              websiteEngagementChartData={websiteEngagementChartData}
              organisation={organisation}
              isLoadingOrganisation={isLoadingOrganisation}
              {...props}
            />
          );
        }}
      />

      <Route
        path="/projects/:projectId/market"
        render={(props) => (
          <MarketBackground
            isLoadingProject={isLoadingProject}
            isErrorProject={isErrorProject}
            project={project}
            searchTrendsChartData={searchTrendsChartData}
            trafficTrendsChartData={trafficTrendsChartData}
            setSearchTrendsKeywords={setSearchTrendsKeywords}
            brandSearchesChartData={brandSearchesChartData}
            isBrandSearchesLoading={isBrandSearchesLoading}
            organisation={organisation}
            {...props}
          />
        )}
      />

      <Route
        path="/projects/:projectId/brand"
        render={(props) => {
          return (
            <Brand
              isLoadingProject={isLoadingProject}
              isErrorProject={isErrorProject}
              project={project}
              brandSearchesChartData={brandSearchesChartData}
              isBrandSearchesLoading={isBrandSearchesLoading}
              trustpilotChartData={trustpilotChartData}
              isTrustPilotLoading={isTrustPilotLoading}
              organisation={organisation}
              isLoadingOrganisation={isLoadingOrganisation}
              {...props}
            />
          );
        }}
      />

      <Route
        path="/projects/:projectId/social"
        render={(props) => {
          return (
            <Social
              isLoadingProject={isLoadingProject}
              isErrorProject={isErrorProject}
              project={project}
              socialAudienceChartData={socialAudienceChartData}
              isSocialbladeLoading={isSocialbladeLoading}
              followersVsEngagementsChartData={followersVsEngagementsChartData}
              organisation={organisation}
              isLoadingOrganisation={isLoadingOrganisation}
              {...props}
            />
          );
        }}
      />

      <Route path="/projects/:projectId/profile" render={(props) => <Company {...props} />} />

      <Route
        path="/projects/:projectId/summary"
        render={(props) => (
          <Summary
            brandSearchChartData={brandSearchesChartData}
            followersVsEngagementsChartData={followersVsEngagementsChartData}
            overviewChartData={overviewChartData}
            searchTrendsChartData={searchTrendsChartData}
            socialAudienceChartData={socialAudienceChartData}
            socialbladeScore={socialbladeScore}
            summaryData={summaryData}
            trafficByMonthChartData={trafficByMonthChartData}
            trafficSourceBreakdownChartData={getSummaryTrafficSourceBreakdownChartData()}
            trafficTrendsChartData={trafficTrendsChartData}
            trustpilotChartData={trustpilotChartData}
            trustpilotScore={trustpilotScore}
            websiteEngagementScore={websiteEngagementScore}
            websiteEngagementChartData={websiteEngagementChartData}
            organisation={organisation}
            isLoadingOrganisation={isLoadingOrganisation}
            siteOptimisationChartData={siteOptimisationChartData}
            {...props}
          />
        )}
      />

      <Route
        path="/projects/:projectId/technology"
        render={(props) => (
          <Tech
            isLoadingProject={isLoadingProject}
            isErrorProject={isErrorProject}
            project={project}
            pagespeedInsightsData={pagespeedInsightsData}
            isPagespeedInsightsLoading={isPagespeedInsightsLoading}
            siteOptimisationChartData={siteOptimisationChartData}
            organisation={organisation}
            isLoadingOrganisation={isLoadingOrganisation}
            {...props}
          />
        )}
      />

      <Route
        path="/projects/:projectId/export"
        render={(props) => (
          <Export
            applyFilters={applyFilters}
            brandSearchChartData={brandSearchesChartData}
            brandSearchesChartData={brandSearchesChartData}
            countries={countries}
            filters={filters}
            followersVsEngagementsChartData={followersVsEngagementsChartData}
            isBrandSearchesLoading={isBrandSearchesLoading}
            isErrorProject={isErrorProject}
            isLoadingProject={isLoadingProject}
            isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
            isTrustPilotLoading={isTrustPilotLoading}
            isWebsiteEngagementLoading={isWebsiteEngagementLoading}
            isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
            overviewChartData={overviewChartData}
            project={project}
            projectId={projectId}
            searchTrendsChartData={searchTrendsChartData}
            setSearchTrendsKeywords={setSearchTrendsKeywords}
            socialbladeScore={socialbladeScore}
            summaryData={summaryData}
            trafficByMonthChartData={trafficByMonthChartData}
            trafficSourceBreakdownChartData={
              combinedTrafficSourceBreakdownChartData || trafficSourceBreakdownChartData
            }
            trafficTrendsChartData={trafficTrendsChartData}
            trustpilotChartData={trustpilotChartData}
            trustpilotScore={trustpilotScore}
            websiteEngagementChartData={websiteEngagementChartData}
            websiteEngagementScore={websiteEngagementScore}
            websiteTrafficVolumes={websiteTrafficVolumesData}
            isSocialbladeLoading={isSocialbladeLoading}
            socialAudienceChartData={socialAudienceChartData}
            organisation={organisation}
            isLoadingOrganisation={isLoadingOrganisation}
            siteOptimisationChartData={siteOptimisationChartData}
            {...props}
          />
        )}
      />
    </>
  );
};

export default ProjectRouteContainer;
