import { Switch, withStyles } from '@material-ui/core';

export const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 23,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    transform: 'translateX(2px) translateY(1px)',
    padding: 1,
    '&$checked': {
      transform: 'translateX(18px) translateY(1px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#6CB242',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 18,
    height: 18
  },
  track: {
    borderRadius: 23 / 2,
    // border: `1px solid ${theme.palette.grey[400]}`,
    border: 'none',
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#7A8199',
    opacity: 1
    //transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});
