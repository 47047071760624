const calculateTrafficSourceTotals = (trafficSourceVolumesData, config) => {
  return trafficSourceVolumesData.map((dataByDomain) => {
    const total = Object.values(dataByDomain.groups).reduce((acc2, group) => {
      const { visits } = group[0];
      const visitsTotal = visits.reduce((acc3, visit) => {
        acc3 += visit.paid + visit.organic;
        return acc3;
      }, 0);

      acc2 += visitsTotal;

      return acc2;
    }, 0);

    const group = dataByDomain.groups[config.key || config.name];
    if (!group) return { groupTotal: 0, total: total };

    const visits = group[0].visits;

    const groupTotal = visits.reduce((acc, visit) => {
      const { paid, organic } = visit;

      if (config.paid) {
        acc += paid;
      }

      if (config.organic) {
        acc += organic;
      }

      return acc;
    }, 0);

    return { groupTotal, total };
  });
};

const calculateMobileTrafficSourceTotals = (trafficMobileSourceVolumesData, config) => {
  return trafficMobileSourceVolumesData.map((dataByDomain) => {
    const total = Object.values(dataByDomain.groups).reduce((acc2, group) => {
      const { visits } = group[0];
      const visitsTotal = visits.reduce((acc3, visit) => {
        acc3 += visit.visits;
        return acc3;
      }, 0);

      acc2 += visitsTotal;

      return acc2;
    }, 0);

    const group = dataByDomain.groups[config.key || config.name];
    if (!group) return { groupTotal: 0, total: total };

    const visits = group[0].visits;

    const groupTotal = visits.reduce((acc, visit) => {
      return acc + visit.visits;
    }, 0);

    return { groupTotal, total };
  });
};

const calculateSumOfTraffic = (trafficSourceVolumesData) => {
  const sumVisits = (visits, filterFn) =>
    visits.reduce((acc, { organic, paid }) => acc + filterFn(organic, paid), 0);

  return trafficSourceVolumesData.map((item) => {
    const freeTraffic =
      sumVisits(item.groups.Direct[0].visits, (organic) => organic) +
      sumVisits(item.groups.Search[0].visits, (organic) => organic);

    const allTraffic = Object.values(item.groups).reduce(
      (result, [{ visits }]) => result + sumVisits(visits, (organic, paid) => organic + paid),
      0
    );

    return {
      ...item,
      freeTraffic,
      allTraffic,
      freeShare: allTraffic > 0 ? freeTraffic / allTraffic : 0
    };
  });
};

const calculateSumOfMobileTraffic = (trafficMobileSourceVolumesData) => {
  const sumMobileVisits = (visits, filterFn) =>
    visits.reduce((acc, { visits: totalVisits }) => acc + filterFn(totalVisits), 0);

  return trafficMobileSourceVolumesData.map((item) => {
    const freeTraffic =
      sumMobileVisits(item.groups.Direct[0].visits, (totalVisits) => totalVisits) +
      sumMobileVisits(item.groups['Organic Search'][0].visits, (totalVisits) => totalVisits);

    const allTraffic = Object.values(item.groups).reduce(
      (result, [{ visits }]) => result + sumMobileVisits(visits, (totalVisits) => totalVisits),
      0
    );

    return {
      ...item,
      freeTraffic,
      allTraffic,
      freeShare: allTraffic > 0 ? freeTraffic / allTraffic : 0
    };
  });
};

const mergeTrafficSourceSums = (trafficSourceSums, mobileTrafficSourceSums) => {
  return trafficSourceSums.map((item, index) => {
    const freeTraffic = item.freeTraffic + mobileTrafficSourceSums[index].freeTraffic;
    const allTraffic = item.allTraffic + mobileTrafficSourceSums[index].allTraffic;
    return {
      ...item,
      freeTraffic: freeTraffic,
      allTraffic: allTraffic,
      freeShare: allTraffic > 0 ? freeTraffic / allTraffic : 0
    };
  });
};

export {
  calculateTrafficSourceTotals,
  calculateMobileTrafficSourceTotals,
  calculateSumOfMobileTraffic,
  calculateSumOfTraffic,
  mergeTrafficSourceSums
};
