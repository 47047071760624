import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useOrganisation, useProject, useProjectsList } from '../../api';
import Error from '../../components/Error';
import { useAuth0 } from '@auth0/auth0-react';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { AuthTokenContext } from '../../components/AuthenticationWrapper';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

import {
  CircularProgress,
  Grid,
  Paper,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box
} from '@material-ui/core';
import Loading from '../../components/Loading';
import classNames from 'classnames';
import { WarningTriangle } from '../../components/WarningTriangle';
import { useAuth } from '../../hooks/use-auth';
import LinkedInBox from "../../components/LinkedInBox";

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%'
  },
  container: {
    marginTop: '50px',
    top: '100px',
    left: '100px',
    width: '1200px'
  },
  item: {
    borderRadius: '20px',
    margin: '10px',
    padding: '20px'
  },
  active: {
    opacity: '1',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.7'
    }
  },
  title: {
    color: '#3C21BA',
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  meta: {
    color: '#3C21BA',
    '& em': {
      color: '#555B71',
      fontStyle: 'normal'
    }
  },
  inactive: {
    opacity: '0.7',
    '&:hover': {
      cursor: 'wait'
    }
  },
  failed: {
    opacity: '0.6',
    '&:hover': {
      cursor: 'not-allowed'
    }
  },
  embedContainer: {
    paddingTop: '100px',
    height: '100%',
    backgroundColor: '#fff',
    position: "fixed",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column'
  },

  projectListContainer: {
    maxWidth: "900px",
    margin: "0 auto",
  },

}));

export default function ProjectsList() {
  const { user, isAuthenticated, loading, getAccessTokenSilently } = useAuth0();
  const auth = useContext(AuthTokenContext);

  const {
    data: org,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation
  } = useOrganisation();

  const classes = useStyles();
  const { projects, isLoading, isError } = useProjectsList();

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileFor, setUploadedFileFor] = useState(null);
  const [isFileInputError, setIsFileInputError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [uploadResponse, setIsUploadResponse] = useState(null);

  // useEffect(async () => {
  //   if (!loading) {
  //     await getUserMetadata(isAuthenticated, getAccessTokenSilently, user).then((res) => {
  //       setOrganisation(res.company);
  //     });
  //   }
  //   return () => {
  //     setOrganisation(organisation);
  //   };
  // }, [user]);

  const handleDialogOpen = (project) => {
    setOpenUploadDialog(true);
    setUploadedFileFor(project);
    setIsUploadResponse(null);
  };
  const handleDialogClose = () => {
    setOpenUploadDialog(false);
    setIsUploadResponse(null);
  };
  const handleFileInputChange = (e) => {
    if (e.target.files[0].name.split('.')[0] === uploadedFileFor.name) {
      setIsFileInputError(false);
      setIsUploadDisabled(false);
      setUploadedFile(e.target.files[0]);
    } else {
      setIsFileInputError(true);
      setErrorMsg('File name has to be exact as project name');
      setIsUploadDisabled(true);
    }
  };
  const showAlert = () => {
    if (!uploadResponse) return <></>;
    if (uploadResponse.status === 200) {
      return <Alert severity="success">{uploadResponse?.data?.message}</Alert>;
    }
    return <Alert severity="error">{uploadResponse?.data?.message}</Alert>;
  };

  if (isLoading || isLoadingOrganisation) return <Loading />;
  if (isError || isErrorOrganisation) return <Error error={isError} />;

  const organisation = org?.name;

  return (
    <Grid container className={classes.outer}>
      <Grid xs={12} sm={10} className={classes.container} container justifyContent="space-evenly">
        <Grid item xs={6} sm={10} alignItems={"center"} >
          <h1 style={{ textAlign: "center" }}>Projects</h1>
        </Grid>

        <Grid item container xs={4} sm={10} md={12} alignItems={"center"} className={classes.projectListContainer}>
          {projects.map((project) => {
            return (
              <ProjectItem
                key={project.id}
                project={project}
                organisation={organisation}
                handleDialogOpen={handleDialogOpen}
              />
            );
          })}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={2} className={classes.embedContainer}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <LinkedInBox siteUuid={"1872cbf67800f46b4500fbac50c899a1"} embedUuid={"1872cc107600b8cbabca1bac52885e4e"}/>
        </Grid>
      </Grid>
    </Grid>

  );
}

function ProjectItem({ project, organisation, handleDialogOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const isPalladium = organisation.toLowerCase() === 'palladium digital';

  const hasFailedMetrics = project.Metrics.some((metric) => metric.status === 'FAILED');

  const handleClick = () => {
    if (!project.dataFetched) return null;
    if (hasFailedMetrics) return null;
    return history.push(`/projects/${project.id}/summary`);
  };

  return (
    <Grid item md={6} sm={12}>
      <Paper
        className={classNames(
          classes.item,
          project.dataFetched && !hasFailedMetrics ? classes.active : classes.inactive,
          hasFailedMetrics && classes.failed
        )}
        elevation={0}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid
            item
            md={9}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick()}>
            <Grid item md={2}>
              <img
                src={`https://icons.duckduckgo.com/ip3/${project?.domain}.ico`}
                width="42"
                height="42"
              />
            </Grid>
            <Grid item md={10}>
              <Grid item container direction="row">
                {!project.dataFetched && (
                  <Grid item md={1}>
                    <CircularProgress
                      className={classes.spinner}
                      disableShrink
                      variant="indeterminate"
                      data-testid="loading"
                      size={20}
                      thickness={5}
                      classes={{
                        circle: classes.circle
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={11}>
                  <p className={classes.title}>{project.name}</p>
                </Grid>
              </Grid>
              <p>
                <strong>Domain:</strong> {project.domain}
              </p>
              {isPalladium && (
                <p>
                  <strong>Organisation:</strong> {project.organisation}
                </p>
              )}
              <p className={classes.meta}>
                <em>Date searched:</em> {moment(project.createdAt).format('D MMMM YYYY')}
              </p>
              {!project.dataFetched && (
                <p>
                  We are currently gathering data for this project. This usually takes 5 minutes.
                </p>
              )}

              {hasFailedMetrics && (
                <Grid container>
                  <WarningTriangle />
                  <span>Project failed - please contact support.</span>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={3} />
        </Grid>
      </Paper>
    </Grid>
  );
}
