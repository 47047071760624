import { useEffect, useRef, useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as yup from 'yup';
import {
  Button,
  Chip,
  Grid,
  makeStyles,
  InputBase,
  Divider,
  Paper,
  Tooltip
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from 'axios';
import CompetitorsList from '../components/CompetitorsList';
import CountriesList from '../components/CountriesList';
import AddCustomCompetitor from '../components/AddCustomCompetitor';
import CreditsMain from '../components/Credits/CreditsMain';
import InfoDialog from '../components/InfoDialog';
import { AuthTokenContext } from '../components/AuthenticationWrapper';
import { Traffic } from '../components/Traffic';
import psl from 'psl';
import { useAuth } from '../hooks/use-auth';
import { useOrganisation } from '../api';
import Loading from '../components/Loading';
import LockIcon from '@material-ui/icons/Lock';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '1224px'
  },
  outer: {
    width: '100%'
  },
  inner: {
    borderRadius: '20px',
    padding: '30px',
    marginTop: '10px'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '2%',
    marginLeft: '40px'
  },
  columnHeading: {
    fontSize: '18px',
    color: '#394056',
    fontWeight: 'normal',
    margin: '0 10px',
    height: '50px'
  },
  columnHeadingSubText: {
    color: '#BBBFCE',
    fontSize: '11px',
    fontWeight: 'normal',
    margin: '-20px 10px 20px'
  },
  button: {
    borderRadius: '5px',
    textTransform: 'none'
  },
  margin: {
    margin: theme.spacing(1)
  },
  projectNameLabel: {
    marginTop: '30px',
    marginBottom: '10px'
  },
  projectNameInput: {
    borderRadius: '10px',
    border: '1px solid #DCDEE6',
    width: '100%',
    height: '40px',
    padding: '5px 10px'
  },
  targetWebsiteLabel: {
    marginTop: '10px'
  },
  chip: {
    width: '100%',
    margin: '10px 0',
    backgroundColor: '#F5F6FA',
    color: '#3C21BA',
    borderRadius: '10px',
    padding: '20px 0'
    // fontWeight: 'bold'
  },
  scrollBox: {
    height: '500px',
    overflowY: 'auto',
    paddingRight: '10px',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(122, 129, 153, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7A8199'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#7A8199'
    }
  },
  searchTimeText: {
    padding: '0px 10px',
    fontSize: '13px',
    color: '#555B71'
  },
  companyInfoTitle: {
    color: '#3C21BA',
    margin: 0,
    marginTop: '10px',
    padding: 0,
    fontWeight: 600
  },
  companyInfoText: {
    color: '#555B71',
    margin: 0,
    padding: 0,
    marginBotton: '10px'
  },
  customCompetitorContainer: {
    marginTop: '10px'
  },
  annualTrafficHeading: {
    position: 'absolute',
    bottom: '-20px',
    right: '0px',
    textAlign: 'right',
    marginRight: '100px',
    color: '#BBBFCE',
    fontSize: '11px',
    fontWeight: 'normal'
  }
}));

export default function Results() {
  const history = useHistory();

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const {
    data: org,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation
  } = useOrganisation();

  const competitorsEl = useRef(null);
  const countriesEl = useRef(null);

  const classes = useStyles();
  const match = useRouteMatch('/search/:target');
  // Get the domain as a target to fetch competitor related endpoint and to post to saved search
  const target = match.params.target;

  const [submitting, setSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  // const [organisation, setOrganisation] = useState(null);

  // Project properties
  const [projectName, setProjectName] = useState('');

  // All competitors to handle custom competitors component
  const [competitors, setCompetitors] = useState([]);
  // Selected Competitors
  // const [selectedCompetitors, setSelectedCompetitors] = useState()

  // Start count at 1 because we assume that there is a default selected value is the first item
  // const [selectedCompetitorsCount, setSelectedCompetitorsCount] = useState(1)
  const selectedCompetitors = competitors.filter((c) => c.selected === true);

  const selectedCompetitorsCount = selectedCompetitors.length;

  // Selected Countries
  const [countriesData, setCountriesData] = useState();
  const [selectedCountries, setSelectedCountries] = useState({ UK: true });
  const [country, setCountry] = useState(['GB']);
  const [fetchingCountryTraffic, setFetchingCountryTraffic] = useState(true);
  // Start count at 1 because we assume that there is a default selected value is the first item
  const [selectedCountriesCount, setSelectedCountriesCount] = useState(1);
  const [companyInfo, setCompanyInfo] = useState();

  const auth = useContext(AuthTokenContext);

  useEffect(() => {
    setFetchingCountryTraffic(true);
    axios
      .get(`${process.env.REACT_APP_URL}/api/search/${target}/${country}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setCompanyInfo(response?.data?.companyInfo);
        setFetchingCountryTraffic(false);
      });
  }, [country]);

  useEffect(() => {
    if (!fetchingCountryTraffic) {
      if (Object.keys(companyInfo || {}).length === 0) {
        const title = 'Insufficient online presence';
        const content = `Please check the website is correct. Prism requires the target’s website to have several thousand users a year to run. <br><br> Website used: ${target}`;
        history.push({
          pathname: window.location.pathname,
          search: `?modal=show&title=${title}&content=${content}&button=Return to Search&variant=search`
        });
      }
    }
  }, [companyInfo, fetchingCountryTraffic]);

  // useEffect(async () => {
  //   if (!isLoading) {
  //     await getUserMetadata(isAuthenticated, getAccessTokenSilently, user).then((res) => {
  //       setOrganisation(res.company);
  //     });
  //   }
  //   return () => {
  //     setOrganisation(organisation);
  //   };
  // }, [user]);

  // Set initial project name to searched domain name
  useEffect(() => {
    const parsed = psl.parse(target);
    const tld = parsed.tld;

    const domain = target.split(`.${tld}`)[0];
    setProjectName(`Project - ${domain}`);
  }, []);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleSelectedCompetitorsChange = (e) => {
    if (e.target.checked) {
      // setSelectedCompetitorsCount(selectedCompetitorsCount + 1)
    } else {
      // setSelectedCompetitorsCount(selectedCompetitorsCount - 1)
    }
  };

  const handleCompetitorToggle = (e, selectedCompetitor) => {
    const competitorsClone = competitors.slice();
    const competitorIndex = competitors.findIndex((c) => c.url === selectedCompetitor.url);
    competitorsClone[competitorIndex].selected = !competitorsClone[competitorIndex].selected;
    setCompetitors(competitorsClone);
  };

  const handleSelectedCountriesChange = (e) => {
    const countriesChecked = Array.from(countriesEl.current.querySelectorAll('input:checked')).map(
      (country) => country.value
    );
    setCountry(countriesChecked);

    const prevState = selectedCountries;
    setSelectedCountries({
      ...prevState,
      [e.target.value]: !!e.target.checked
    });

    if (e.target.checked) {
      setSelectedCountriesCount(selectedCountriesCount + 1);
    } else {
      setSelectedCountriesCount(selectedCountriesCount - 1);
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    setIsError(false);
    setErrorMsg(null);
    const competitorsChecked = selectedCompetitors.map((c) => c.url);
    const countriesChecked = Array.from(countriesEl.current.querySelectorAll('input:checked')).map(
      (country) => country.value
    );

    const parameters = {
      name: projectName,
      domain: target,
      competitors: competitorsChecked,
      countries: countriesChecked,
      organisation: organisation,
      ownerEmail: user.email,
      ownerName: user.name
    };

    fetch(`${process.env.REACT_APP_URL}/api/save_search`, {
      method: 'post',
      body: JSON.stringify(parameters),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.accessToken}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      })
      .then((data) => {
        const { user, userProjectsCount } = data;

        const { created_at } = user;

        const age = moment().diff(created_at, 'days');

        if (userProjectsCount === 1 && age <= 20) {
          // eslint-disable-next-line no-undef
          gtag('event', 'newuser_newreport', {
            target,
            country: countriesChecked[0],
            competitors: competitorsChecked,
            organisation
          });
        }
        // eslint-disable-next-line no-undef
        gtag('event', 'create_report', {
          target,
          country: countriesChecked[0],
          competitors: competitorsChecked,
          organisation
        });

        return location.assign('/projects');
      })
      .catch((err) => {
        setSubmitting(false);
        setIsError(true);
        err.then((errorMessage) => {
          errorMessage.errors.forEach((el) => setErrorMsg(el.msg));
        });
      });
  };

  if (isLoadingOrganisation) return <Loading />;
  const organisation = org?.name;
  const orgType = org?.orgType;
  const chooseCountryModalContent = (
    <div>
      <p>Prism will analyse your target company’s performance in a specific country.</p>
      <p>It is therefore important to choose a country that matches the company URL.</p>
      <p>Example of good country selection: acmecorp.de with Germany</p>
      <p>Example of poor country selection: acmecorp.de with Canada</p>
      <p>
        To learn more visit our{' '}
        <a target="_blank" href="https://prism.helpscoutdocs.com" rel="noreferrer">
          Knowledge Base
        </a>
      </p>
    </div>
  );

  const suggestedCompetitorsModalContent = (
    <div>
      <p>Prism will analyse your target company’s performance relative to a set of competitors.</p>
      <p>
        It is therefore important to choose competitors that meaningfully compete in the chosen
        country.
      </p>
      <p>
        The suggested list is created using the sites visitors go to before or after they visit the
        target’s website. This is done globaly and irrespective of product proposition. Further
        analysis is needed to decide which competitors to choose.
      </p>
      <p>You can also add your own competitors using the ‘Add your own competitor’ button.</p>
      <p>
        Our{' '}
        <a target="_blank" href="https://prism.helpscoutdocs.com" rel="noreferrer">
          Knowledge Base
        </a>{' '}
        contains detailed advice on choosing a digital competitor set.
      </p>
    </div>
  );

  return (
    <Grid container justifyContent="center" className={classes.outer}>
      <Paper className={classes.inner} elevation={0}>
        <Grid container className={classes.root} data-testid="results">
          <Grid container item spacing={3}>
            {isError && (
              <Grid item md={12}>
                <Alert severity="error">{errorMsg}</Alert>
              </Grid>
            )}
            <Grid item md={3}>
              <h3 className={classes.columnHeading}>Project details</h3>
              <Grid>
                <div className={classes.projectNameLabel} htmlFor="">
                  Project Name
                </div>
                <InputBase
                  className={classes.projectNameInput}
                  id="outlined-basic"
                  fullWidth
                  placeholder="Enter a name for this project"
                  variant="outlined"
                  value={projectName}
                  onChange={handleProjectNameChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
              <Grid>
                <div className={classes.targetWebsiteLabel} htmlFor="">
                  Target Website
                </div>
                <Chip className={classes.chip} label={target} />
                {companyInfo && (
                  <>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justify="center">
                      <Grid item>
                        <img src={`https://icons.duckduckgo.com/ip3/${target}.ico`} width={50} />
                      </Grid>
                      <Grid container spacing={0} direction="column">
                        <p className={classes.companyInfoTitle}>Title</p>
                        <p className={classes.companyInfoText}>{companyInfo.company_name}</p>
                        {companyInfo?.traffic && (
                          <>
                            <p className={classes.companyInfoTitle}>Annual Traffic</p>

                            {orgType === 'Lite' && (
                              <Tooltip title="Upgrade to view annual traffic">
                                <LockIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    history.push('/upgrade');
                                  }}></LockIcon>
                              </Tooltip>
                            )}

                            {orgType != 'Lite' && fetchingCountryTraffic && (
                              <Skeleton variant="rectangular" width={80} height={17} />
                            )}

                            {orgType != 'Lite' && !fetchingCountryTraffic && (
                              <p className={classes.companyInfoText}>
                                <Traffic traffic={companyInfo?.traffic} />
                              </p>
                            )}
                          </>
                        )}

                        <p className={classes.companyInfoTitle}>Headquarters</p>
                        <p className={classes.companyInfoText}>{companyInfo.headquarters}</p>

                        {companyInfo?.website_category && (
                          <>
                            <p className={classes.companyInfoTitle}>Industry</p>
                            <p className={classes.companyInfoText}>
                              {companyInfo?.website_category.replaceAll('_', ' ')}
                            </p>
                          </>
                        )}

                        {companyInfo?.description && (
                          <>
                            <p className={classes.companyInfoTitle}>Description</p>
                            <p className={classes.companyInfoText}>
                              from the brand:{' '}
                              <span style={{ textTransform: 'capitalize' }}>
                                {companyInfo?.description}
                              </span>
                            </p>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item md={3}>
              <h3 className={classes.columnHeading}>Choose a country</h3>
              <InfoDialog text={chooseCountryModalContent} />
              {/* Use the 2 dom elements below to even out the vertical spacing for the right column */}
              <h4 className={classes.columnHeadingSubText}>{'\u00A0'}</h4>
              <h4 className={classes.columnHeadingSubText}>{'\u00A0'}</h4>
              <Grid item md={12} className={classes.scrollBox} ref={countriesEl}>
                <CountriesList
                  handleSelectedCountriesChange={handleSelectedCountriesChange}
                  selectedCountriesCount={selectedCountriesCount}
                  maxSelected={1}
                  domain={target}
                />
              </Grid>
            </Grid>

            <Grid item md={6}>
              <div style={{ position: 'relative' }}>
                <h3 className={classes.columnHeading}>Choose competitors</h3>
                <InfoDialog text={suggestedCompetitorsModalContent} />
                <h4 className={classes.columnHeadingSubText}>
                  Suggested websites have a shared audience
                </h4>
                <h4 className={classes.columnHeadingSubText}>Select up to 5 competitors</h4>

                {!!competitors && !!competitors.length && (
                  <div className={classes.annualTrafficHeading}>Annual Traffic</div>
                )}
              </div>

              <Grid item md={12} className={classes.scrollBox} ref={competitorsEl}>
                <CompetitorsList
                  competitors={competitors}
                  setCompetitors={setCompetitors}
                  handleSelectedCompetitorsChange={handleSelectedCompetitorsChange}
                  selectedCompetitorsCount={selectedCompetitorsCount}
                  selectedCompetitors={selectedCompetitors}
                  target={target}
                  maxSelected={5}
                  handleCompetitorToggle={handleCompetitorToggle}
                  country={country}
                  orgType={orgType}
                />
              </Grid>

              <Grid className={classes.customCompetitorContainer}>
                <AddCustomCompetitor
                  competitors={competitors}
                  setCompetitors={setCompetitors}
                  selectedCompeitorsCount={selectedCompetitorsCount}
                  maxSelected={5}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider variant="fullWidth" />
            </Grid>
            <Grid item md={7}>
              <p className={classes.searchTimeText}>
                <CreditsMain />
              </p>
            </Grid>
            <Grid container item md={5} spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  disableElevation
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => history.goBack()}>
                  Go back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={submitting}
                  onClick={() => handleSubmit()}
                  endIcon={<ChevronRightIcon />}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
