import { Button, Grid, makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import UpgradeButton from './UpgradeButton';

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    position: 'absolute',
    top: (props) => props.top,
    right: (props) => props.right,
    textAlign: 'right',
    zIndex: 111111
  }
}));
export default function OverlayUpgradeButton({ top = 0, right = 30 }) {
  const classes = useStyles({
    top,
    right
  });
  return (
    <div className={classes.overlayContainer}>
      <UpgradeButton />
    </div>
  );
}
