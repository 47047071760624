import { Grid, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '1100px'
  },
  outer: {
    width: '100%'
  },
  inner: {
    borderRadius: '20px',
    padding: '30px',
    marginTop: '10px'
  }
}));

export default function Container({ children }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" className={classes.outer}>
      <Paper className={classes.inner} elevation={0}>
        <Grid container className={classes.root}>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
}
