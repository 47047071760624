// Search Trend chart

import React, { useEffect } from 'react';
import { Paper, makeStyles, Chip } from '@material-ui/core';
import Chart from 'react-apexcharts';
import Skeleton from '@material-ui/lab/Skeleton';
import { format } from 'd3-format';
import classNames from 'classnames';
import '../CompetitorAnalysis/Overview.css';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
import { useAuth } from '../../hooks/use-auth';
import UpgradeButton from '../Auth/UpgradeButton';

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    width: '100%',
    padding: '20px',
    margin: '10px',
    position: 'relative'
  },

  graphSubtitle: {
    fontSize: '12px'
  },

  chips: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    },

    [`@media print`]: {
      'break-before': 'page',
      marginTop: '50px'
    }
  },

  chip: {
    margin: '4px'
  },

  chipDeselected: {
    opacity: 0.5
  }
}));

export default function IndustrySearches({
  title,
  subtitle,
  dataFetched,
  filters,
  project,
  data,
  series,
  categories,
  keywords,
  cagr,
  setSearchTrendsKeywords,
  organisation
}) {
  // MARKET / SEARCH GROWTH CHART
  const { orgType } = organisation;
  const classes = useStyles();

  const onKeywordClick = (keyword) => {
    const newKeywords = keywords.slice();
    const matchingKeyword = newKeywords.find((k) => k.keyword === keyword.keyword);
    if (matchingKeyword) {
      matchingKeyword.selected = !matchingKeyword.selected;
    }

    setSearchTrendsKeywords(newKeywords);
  };

  if (dataFetched && data && series && series.length > 0) {
    return (
      <Paper className={classes.graphContainer} elevation={0} id="search-growth-chart">
        <h4 className={classes.graphSubtitle}>{subtitle}</h4>
        {orgType === 'Lite' && <OverlayUpgradeButton top={55} right={25} />}
        <Chart
          className="alpha-graph-legends"
          options={{
            stroke: {
              curve: 'smooth',
              dashArray: [0, 5],
              colors: ['rgba(26, 31, 101, 1)', 'rgba(00, 00, 00, 0.6)']
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              type: 'datetime',
              categories: categories,
              tickPlacement: 'on',
              labels: {
                format: "MMM 'yy"
              }
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return format('.2s')(val);
                }
              }
            },
            colors: ['#1A1F65', '#292d60'],
            chart: {
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              },
              toolbar: {
                tools: {
                  download: true,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false
                }
              }
            }
          }}
          series={series.map((s, i) => {
            if (i === 0) s.name = 'Target and competitors';
            return s;
          })}
          type="line"
          width="100%"
        />

        <div className={classes.chips}>
          {keywords && keywords.length && (
            <>
              <div style={{ position: 'relative' }}>
                {orgType === 'Lite' && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: '0',
                      left: '0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: '1',
                      backdropFilter: 'blur(3px)'
                    }}>
                    <UpgradeButton justifyContent="center" text="Upgrade to edit keywords" />
                  </div>
                )}

                <div>
                  <span>Keywords used (click to filter):</span>

                  {keywords.map((keyword, idx) => (
                    <Chip
                      className={classNames(
                        classes.chip,
                        !keyword.selected && classes.chipDeselected
                      )}
                      size="small"
                      label={keyword.keyword}
                      key={idx}
                      onClick={() => onKeywordClick(keyword)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.graphContainer} elevation={0}>
      {/* <h3 className={classes.graphTitle}>{title}</h3> */}
      <Skeleton width="50%" />
      <Skeleton width="20%" />
      <Skeleton width="30%" />
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Paper>
  );
}
