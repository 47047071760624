import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://56420a936759473195a93053dddb3073@o554124.ingest.sentry.io/5684134',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

console.log('https://' + process.env.REACT_APP_AUTH0_APIEXPLORER + '/api/v2/');

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={'https://' + process.env.REACT_APP_AUTH0_APIEXPLORER + '/api/v2/'}
    // https://palladium-thunder.eu.auth0.com/api/v2/
    // audience="https://palladium-thunder.eu.auth0.com/api/v2/"
    scope="openid profile email read:current_user update:current_user_metadata">
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
