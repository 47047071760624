import { useContext, useState, useEffect } from 'react';

import { Button, InputBase, alpha, makeStyles, withStyles } from '@material-ui/core';

import axios from 'axios';
import { AuthTokenContext } from './AuthenticationWrapper';
import parseUri from '../utils/url';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#3C21BA',
    borderRadius: '5px',
    color: '#FFF',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      color: '#3C21BA',
      backgroundColor: 'RGBA(60, 33, 186, 0.2)'
    }
  },
  icon: {
    color: '#FFF',
    marginRight: '10px'
  },
  addButton: {
    borderRadius: '5px',
    textTransform: 'none'
  }
}));

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    marginRight: '10px'
  },
  error: {
    '& input': {
      borderColor: 'red !important',
      borderWidth: '1px',
      outline: '1px solid red',
      color: 'red'
    },
    '& input:focus': {
      outline: '1px solid red',
      color: 'red'
    }
  },
  input: {
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: '#F5F6FA',
    border: 'none',
    fontSize: '16px',
    width: '520px',
    textAlign: 'center',
    color: '#3C21BA',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&::placeholder': {
      color: '#7A8199'
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

export default function AddCustomCompetitor({
  competitors,
  setCompetitors,
  selectedCompeitorsCount,
  maxSelected
}) {
  const auth = useContext(AuthTokenContext);

  const classes = useStyles();

  const [isInput, toggleInput] = useState(false);
  const [value, setValue] = useState('');
  const [typedValue, setTypedValue] = useState('');
  const [domainInvalid, setDomainInvalid] = useState(false);
  const [adding, setAdding] = useState(false);

  const isValidDomain = async (domain) => {
    return axios
      .get(`${process.env.REACT_APP_URL}/api/check-custom-domain?domain=${domain}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setDomainInvalid(false);
        return true;
      })
      .catch((error) => {
        setDomainInvalid(true);
        setAdding(false);
        return false;
      });
  };

  const validateDomain = async (uri) => {
    const parsedUri = parseUri(uri);
    const domain = parsedUri.host.replace(/^www\./, '');
    if (await isValidDomain(domain)) {
      setValue(domain);
    }
  };

  const handleChange = (e) => {
    setTypedValue(e.target.value);
  };

  const handleKeydown = async (e) => {
    // If already adding, don't do anything
    if (adding) return;

    // If user hits enter, validate domain and if valid, setValue to trigger the useEffect below
    if (e.key === 'Enter') {
      setAdding(true);
      await validateDomain(typedValue);
    }
  };

  // If user clicks Add button
  const handleClick = async () => {
    setAdding(true);
    await validateDomain(typedValue);
  };

  useEffect(() => {
    if (value.length) {
      const competitorsArray = competitors.slice(0);

      const existing = competitorsArray.find((competitor) => competitor.url === value);
      if (existing) {
        existing.selected = true;
        setCompetitors(competitorsArray);
      } else {
        competitorsArray.unshift({
          url: value,
          score: 0,
          selected: true
        });

        setCompetitors(competitorsArray);
      }

      toggleInput(false);
      setValue('');
      setAdding(false);
      setTypedValue('');
    }
  }, [value]);

  if (isInput) {
    return (
      <div>
        <CustomInput
          id="custom-input"
          placeholder="Enter domain name"
          onChange={handleChange}
          onKeyDown={handleKeydown}
          autoFocus
          autoComplete="off"
          error
        />
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleClick()}>
          Add
        </Button>
      </div>
    );
  }
  return (
    <Button
      className={classes.button}
      variant="contained"
      disableElevation
      onClick={() => toggleInput(true)}
      disabled={selectedCompeitorsCount >= maxSelected}>
      <>
        <img className={classes.icon} src="/img/ui/add-competitor-white.svg" />
        Add your own competitor
      </>
    </Button>
  );
}
