import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#EFF1F5',
    padding: '30px',
    zIndex: '999',
    borderBottom: '1px solid #E0E3EA',
    '@media print': {
      display: 'none'
    }
  },
  sectionTitle: {
    fontWeight: '500',
    color: '#7A8199',
    fontSize: '16px',
    marginBottom: '10px'
  },
  buttonContainer: {
    position: 'relative'
  },
  comingSoonChip: {
    position: 'absolute',
    zIndex: '999',
    top: -15,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#03CEA4',
    borderRadius: '10px',
    color: '#fff',
    textTrasform: 'uppercase'
  },
  active: {
    backgroundColor: 'RGBA(60, 33, 186, 0.1) !important',
    color: '#3C21BA !important'
  }
}));

const StyledButton = withStyles({
  root: {
    backgroundColor: '#D6D8E0',
    boxShadow: 'none',
    border: 'none',
    textTransform: 'none',
    width: '140px',
    '@media (min-width: 1600px)': {
      width: '150px'
    },
    borderRadius: '10px',
    fontWeight: '500',
    fontSize: '16px',
    color: '#7A8199',
    '&:hover': {
      backgroundColor: 'RGBA(60, 33, 186, 0.1)',
      color: '#3C21BA'
    }
  }
})(Button);

export default function TabMenu({ projectId, index = 0, project }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container>
      <Paper className={classes.container} elevation={0}>
        <Grid item container direction="row" spacing={2}>
          <Grid item container md={3}>
            <Grid item>
              <Grid item>
                <Typography className={classes.sectionTitle}>About</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-start" spacing={2}>
              <Grid item>
                <MenuButton text="Profile" path={`/projects/${projectId}/profile`} />
              </Grid>
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem />

          {/* Diligence section */}
          <Grid item container md={7}>
            <Grid item>
              <Grid item>
                <Typography className={classes.sectionTitle}>Diligence</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-start" spacing={3}>
              <Grid item>
                <MenuButton text="Summary" path={`/projects/${projectId}/summary`} />
              </Grid>
              <Grid item>
                <MenuButton text="Market" path={`/projects/${projectId}/market`} />
              </Grid>
              <Grid item>
                <MenuButton text="Performance" path={`/projects/${projectId}/performance`} />
              </Grid>
              <Grid item>
                <MenuButton text="Brand" path={`/projects/${projectId}/brand`} />
              </Grid>
              <Grid item>
                <MenuButton
                  text="Social"
                  path={`/projects/${projectId}/social`}
                  disabled={!project.hasSocialbladeData}
                />
              </Grid>

              <Grid item>
                <MenuButton text="Technology" path={`/projects/${projectId}/technology`} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

function MenuButton({ text, path, isComingSoon, disabled = false }) {
  const classes = useStyles();
  const history = useHistory();
  const pathname = history.location.pathname.split('/').pop();
  return (
    <Box className={classes.buttonContainer}>
      {isComingSoon && <Chip className={classes.comingSoonChip} size="small" label="Coming Soon" />}
      <StyledButton
        variant="contained"
        disableElevation
        disableRipple
        onClick={() => history.push(path)}
        className={path?.split('/').pop() === pathname ? classes.active : null}
        disabled={disabled}>
        {text}
      </StyledButton>
    </Box>
  );
}
