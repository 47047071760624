import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Box,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AuthTokenContext } from './AuthenticationWrapper';

const FLAG_ICON_SIZE = 38;

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '400px'
  },
  block: {
    backgroundColor: '#FAFAFA',
    padding: '3px',
    margin: '3px',
    borderRadius: '10px',
    paddingRight: '30px'
  },
  title: {
    color: '#3C21BA',
    fontWeight: 600
  }
}));

export default function CountriesList({
  selectedCountriesCount,
  handleSelectedCountriesChange,
  maxSelected,
  target
}) {
  const classes = useStyles();
  const [countries, setCountries] = useState();
  const auth = useContext(AuthTokenContext);

  useEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_URL}/api/countries?target=${target}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setCountries(response.data);
      });
  }, []);

  if (!countries) {
    return <></>;
  }

  return (
    <Grid container>
      <FormControl component="fieldset">
        <RadioGroup defaultValue={countries[0].code} name="countries">
          {countries.map((country, index) => (
            <Block
              key={index}
              first={index === 0}
              country={country}
              selectedCountriesCount={selectedCountriesCount}
              handleSelectedCountriesChange={handleSelectedCountriesChange}
              maxSelected={maxSelected}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

function Block({
  first,
  country,
  selectedCountriesCount,
  handleSelectedCountriesChange,
  maxSelected
}) {
  const classes = useStyles();

  const handleChange = (e) => {
    handleSelectedCountriesChange(e);
  };

  return (
    <Grid container>
      <Grid container className={classes.block} spacing={3} alignItems="center">
        <Grid item md={3}>
          <img
            width={FLAG_ICON_SIZE}
            height={FLAG_ICON_SIZE}
            src={`/img/flags/${country.icon}.svg`}
          />
        </Grid>
        <Grid item md={7}>
          <Box className={classes.title}>{country?.label ? country.label : country.name}</Box>
        </Grid>
        <Grid item md={2}>
          <Radio onChange={handleChange} value={country.code} />
        </Grid>
      </Grid>
    </Grid>
  );
}
