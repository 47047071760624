import useSWR from 'swr';
import { useContext } from 'react';
import { AuthTokenContext } from '../components/AuthenticationWrapper';

const fetcher = async (url, token) => {
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

function countryParams(countries) {
  var array = [];

  for (var key in countries) {
    array.push('countries[]' + '=' + encodeURIComponent(countries[key]));
  }

  return array.join('&');
}

function domainParams(domains) {
  var array = [];

  for (var key in domains) {
    array.push('domain' + '=' + encodeURIComponent(domains[key]));
  }

  return array.join('&');
}

const options = {
  revalidateOnFocus: false,
  shouldRetryOnError: false
};

export function useProject(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}`, auth.accessToken],
    fetcher,
    options
  );

  return {
    project: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectsList() {
  const auth = useContext(AuthTokenContext);
  const customOptions = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    refreshInterval: 3000
  };
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects`, auth.accessToken],
    fetcher,
    customOptions
  );

  return {
    projects: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectTrafficSourceBreakdown(id, countries) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_URL}/api/projects/${id}/traffic-source-breakdown?${countryParams(
        countries
      )}`,
      auth.accessToken
    ],
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectTrafficSourceVolumes(project, countries) {
  if (!countries) return;
  const auth = useContext(AuthTokenContext);
  // const { data, error } = useSWR(, fetcher)

  const args = () => {
    return project
      ? [
          `${process.env.REACT_APP_URL}/api/projects/${
            project.id
          }/traffic-source-volumes?${countryParams(countries)}`,
          auth.accessToken
        ]
      : false;
  };

  const { data, error } = useSWR(args, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectTrafficMobileSourceVolumes(project, countries) {
  if (!countries) return;
  const auth = useContext(AuthTokenContext);
  const args = () => {
    return project
      ? [
          `${process.env.REACT_APP_URL}/api/projects/${
            project.id
          }/mobile-traffic-volumes?${countryParams(countries)}`,
          auth.accessToken
        ]
      : false;
  };

  const { data, error } = useSWR(args, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectWebsiteEngagement(project, countries) {
  if (!countries) return;
  const auth = useContext(AuthTokenContext);

  const args = () => {
    return project
      ? [
          `${process.env.REACT_APP_URL}/api/projects/${
            project.id
          }/website-engagement?${countryParams(countries)}`,
          auth.accessToken
        ]
      : false;
  };

  const { data, error } = useSWR(args, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectWebsiteTrafficVolumes(project, countries) {
  if (!countries) return;
  const auth = useContext(AuthTokenContext);

  const args = () => {
    return project
      ? [
          `${process.env.REACT_APP_URL}/api/projects/${
            project.id
          }/website-traffic-volumes?${countryParams(project.countries)}`,
          auth.accessToken
        ]
      : false;
  };

  const { data, error } = useSWR(args, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectBrandedSearch(id, countries) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_URL}/api/projects/${id}/branded-search?${countryParams(countries)}`,
      auth.accessToken
    ],
    fetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectBounceRates(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/bounce-rates`, auth.accessToken],
    fetcher,
    options
  );

  return {
    bounceRates: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectPagesPerVisit(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/pages-per-visit`, auth.accessToken],
    fetcher,
    options
  );

  return {
    pagesPerVisit: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectVisitDuration(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/visit-duration`, auth.accessToken],
    fetcher,
    options
  );

  return {
    visitDuration: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectDomainAuthority(id, domains) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_URL}/api/projects/${id}/domain-authority?${domainParams(domains)}`,
      auth.accessToken
    ],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectIndustryTraffic(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/industry-traffic`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectTrustPilotScores(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/trust-pilot-scores`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectIndustrySearches(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/industry-searches`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectBrandSearches(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/brand-searches`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectTrustPilot(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/trustpilot`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectSocialblade(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/socialblade`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectPagespeedInsights(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/pagespeed-insights`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectShareOfVoice(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/share-of-voice`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectSocialVolumes(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/brand-watch-industry`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectSentiment(id, countries) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_URL}/api/projects/${id}/sentiment?${countryParams(countries)}`,
      auth.accessToken
    ],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useProjectAnalystReport(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/analyst-report`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useCompetitorInfo(id) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/projects/${id}/competitor-info`, auth.accessToken],
    fetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error
  };
}
