import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from '../../api';
import { useCompetitorInfo } from '../../api/projects';
import Error from '../../components/Error';
import CompanyInformationHeader from '../../components/Company/CompanyInformationHeader';
import { Grid, makeStyles } from '@material-ui/core';
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons';
import Loading from '../Loading';

const useStyles = makeStyles(() => ({
  rowContainer: {
    marginTop: '10px',
    paddingBottom: '100px',
    width: '90%',
    maxWidth: '90%',
    marginRight: '-8px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  }
}));

export default function CompanyContent() {
  const classes = useStyles();
  const { projectId } = useParams();
  const { project, isLoading, isError } = useProject(projectId);
  const { data, dataIsLoading, dataIsError } = useCompetitorInfo(projectId);
  const [showCompetitors, setShowCompetitors] = useState(true);

  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  if (dataIsLoading) return <Loading />;
  if (dataIsError) return <Error error={isError} />;

  const company = project.companyInfo;
  const competitors = data
    ? JSON.parse(JSON.stringify(data)).map((comp) => {
        const ob = comp.companyInfo;
        ob.url = comp.domain;
        return ob;
      })
    : [];

  if (!company) return 'No company info available';

  return (
    <Grid className={classes.rowContainer} container item>
      <Grid container md={12}>
        <Grid item md={12} sm={12} container direction="row">
          <Grid item>
            <p className={classes.title}>Competitors</p>
          </Grid>
          <Grid item>
            <div
              style={{
                marginTop: '40px',
                borderRadius: '50%',
                border: '2px #3C21BA solid',
                color: '#3C21BA',
                width: '27px',
                height: '27px'
              }}
              onClick={() => setShowCompetitors(!showCompetitors)}>
              {showCompetitors ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
            </div>
          </Grid>
        </Grid>
        {!dataIsLoading && data && showCompetitors ? (
          <Grid item container direction="row" md={12} spacing={2}>
            {competitors.map((comp) => {
              return (
                <CompanyInformationHeader
                  key={comp.id}
                  company={comp}
                  project={project}
                  variant="card"
                />
              );
            })}
          </Grid>
        ) : showCompetitors ? (
          <p>Loading...</p>
        ) : null}
      </Grid>
    </Grid>
  );
}
