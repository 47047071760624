import React from 'react';
import { Grid, Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 30,
    textAlign: 'center',
    color: '#3C21BA'
  },
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    float: 'right',
    position: 'relative',
    top: '-45px'
  },
  contentContainerx: {
    padding: '0 !important'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  childItem: {
    width: '50%',
    padding: 10
  },
  info: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    // minWidth: '376px',
    maxWidth: '376px',
    padding: '40px 58px 51px 58px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  blank: {
    height: '100%',
    boxSizing: 'border-box'
  },
  contentText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#7A8199'
    // maxWidth: '260px'
  }
}));

export default function InfoModal({ text, organisation }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className={classes.container}>
      <Dialog
        className={classes.dialog}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(31, 0, 74, 0.7)' } }}
        PaperProps={{ style: { borderRadius: 10 } }}
        open={open}
        // onClose={handleClose}
        p={8}>
        <MuiDialogContent className={classes.contentContainerx}>
          <div className={classes.contentContainer}>
            <img className={classes.blank} src={'verify.png'} alt={'blank'} />

            <h1 className={classes.heading}>Nearly there..</h1>
            <div className={classes.contentText}>
              <p>
                You&apos;re the first member of the
                <span style={{ color: '#3C21BA' }}>{organisation}</span> organisation.
              </p>

              <p>
                Our team review all new organisations. <br />
                We aim to approve your account within 48 hours.
              </p>

              <p>You&apos;ll receive an email when its ready.</p>
              <p>
                Thanks, <br /> <span style={{ color: '#3C21BA' }}>Team Prism</span>
              </p>
            </div>
          </div>
        </MuiDialogContent>
      </Dialog>
    </Grid>
  );
}
