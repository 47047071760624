import React from 'react';
import {
  Grid,
  Dialog,
  FormControlLabel,
  Button,
  DialogActions,
  FormGroup
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  dialogTitle: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default function SocialModal({ open, setOpen, text, organisation }) {
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['prism_social']);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    if (checked) {
      setCookie('prism_social_warning', true, { path: '/' });
    }

    setOpen(false);
  };

  return (
    <Grid className={classes.container1}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(31, 0, 74, 0.7)' } }}
        PaperProps={{
          style: { borderRadius: 10, maxWidth: '500px' }
        }}
        open={open}
        onClose={handleClose}
        p={8}>
        <MuiDialogTitle className={classes.dialogTitle}>
          Social
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <MuiDialogContent className={classes.contentContainer}>
          <DialogContentText className={classes.contentWrapper}>
            Prism will automatically search for the social accounts of a company, but on occasion
            may not be able to find any. Use the edit social accounts function at the top of the
            page to manually add or edit accounts.
          </DialogContentText>

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                  name="checkedA"
                />
              }
              label="Don't show this again"
            />
          </FormGroup>
        </MuiDialogContent>

        <DialogActions>
          <Button onClick={handleContinue} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
