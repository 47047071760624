const companyInfoRequest = {
  categories: [],
  category_groups: [],
  company_type: '',
  contact_email: '',
  created_at: '',
  description: '',
  exited_on: { value: '', precision: '' },
  facebook: { value: '' },
  founded_on: { value: '', precision: '' },
  founder_identifiers: [],
  funding_stage: '',
  identifier: { uuid: '', value: '', image_id: '', permalink: '', entity_def_id: '' },
  image_url: '',
  ipo_status: '',
  linkedin: { value: '' },
  listed_stock_symbol: '',
  location_group_identifiers: [],
  location_identifiers: [],
  num_articles: 0,
  num_current_positions: 0,
  num_employees_enum: '',
  num_founders: 0,
  num_past_positions: 0,
  operating_status: '',
  permalink_aliases: [],
  phone_number: '',
  rank_delta_d7: 0,
  rank_delta_d30: 0,
  rank_delta_d90: 0,
  rank_org: 0,
  revenue_range: '',
  short_description: '',
  status: '',
  stock_exchange_symbol: '',
  stock_symbol: { uuid: '', value: '', image_id: '', permalink: '', entity_def_id: '' },
  twitter: { value: '' },
  updated_at: '',
  website: { value: '' },
  website_url: '',
  went_public_on: ''
};

export const setDefaultCompanyInfo = (info) => {
  return { ...companyInfoRequest, ...info };
};
