import { Grid, makeStyles, Paper, Typography, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '20px',
    background: 'white',
    padding: '30px',

    '@media print': {
      'break-before': 'page',
      'page-break-before': 'always'
    }
  },
  blocksContainer: {},
  blockContainer: {
    margin: '10px 0',
    '&:last-child $blockInnerContainer': {
      borderBottom: '0'
    }
  },
  block: {
    // backgroundColor: '#F5F6FA',
    borderRadius: '10px',
    marginBottom: '30px'
    // padding: '20px'
  },

  blockInnerContainer: {
    'flex-wrap': 'nowrap',
    borderBottom: '1px solid #eaeaea'
  },

  category: {
    backgroundColor: '#D6D8E0',
    textAlign: 'center',
    color: '#555B71',
    fontWeight: '500',
    borderRadius: '10px',
    fontSize: '16px',
    padding: '10px 0',

    '@media print': {
      fontSize: '12px'
    }
  },
  title: {
    color: '#3c21ba',
    fontSize: '20px',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '20px',
    '@media print': {
      fontSize: '12px'
    }
  },
  description: {
    color: '#7a8199',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '32px',

    '@media print': {
      fontSize: '12px'
    }
  },
  questions: {
    marginTop: '20px',
    '& p': {
      margin: '0',
      paddingLeft: '70px',
      marginTop: '10px',
      position: 'relative',
      '&:before': {
        content: '""',
        backgroundImage: 'url(/img/ui/discussion.svg)',
        width: '30px',
        height: '30px',
        position: 'absolute',
        left: '20px',
        color: '#3c21ba'
      }
    },
    color: '#7a8199',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '32px',

    '@media print': {
      fontSize: '12px'
    }
  },
  mainTitle: {
    fontSize: '26px',
    color: '#ed217c'
  },
  categoryTitle: {
    position: 'relative',
    color: '#3a1f61',
    fontSize: '24px',
    fontWeight: '600',
    margin: '20px 0',
    borderBottom: '2px solid #eaeaea',
    paddingBottom: '10px',
    cursor: 'pointer'
  },
  categoryWrapper: {
    borderRadius: '20px',
    // marginTop: '20px',
    marginBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  flagWrapper: {
    paddingBottom: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconWrapper: {
    flex: 0,
    // width: '50px',
    // marginRight: '20px',
    backgroundColor: '#f7f7fd',
    borderRadius: '15px',
    padding: '20px'
  },
  viewChartButton: {
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    fontSize: 14,
    '&:hover span': { color: '#1976d2' }
  },
  chevronIcon: {
    position: 'absolute',
    right: '20px',
    width: '40px !important',
    height: '40px !important',
    borderRadius: '50%',
    top: '-10px',
    '&.arrow-up': {
      backgroundColor: '#3c21ba',
      color: 'white'
    },
    '&.arrow-down': {
      backgroundColor: '#eeeff2',
      color: '#7a8199'
    }
  }
}));

export default function TargetAssetRedFlags({ flags, ...props }) {
  const classes = useStyles();
  // group the flags by category
  const groupedFlags = flags.reduce((acc, flag) => {
    acc[flag.type] = acc[flag.type] || [];
    acc[flag.type].push(flag);
    return acc;
  }, {});

  const [openCategories, setOpenCategories] = useState(
    Object.keys(groupedFlags).reduce((acc, category) => {
      acc[category] = false;
      return acc;
    }, {})
  );

  return (
    <Grid {...props} className={classes.container} container item direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item md={1} className={classes.iconWrapper}>
            <img src="/img/ui/Flag.svg" />
          </Grid>
          <Grid
            item
            md={11}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 20
            }}>
            <Typography className={classes.mainTitle}>Further Investigation</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column" className={classes.blocksContainer}>
        {Object.entries(groupedFlags).map(([category, categoryFlags]) => (
          <div key={category} className={classes.categoryWrapper}>
            <Typography
              className={classes.categoryTitle}
              // open category on click and close all other categories
              onClick={() => {
                setOpenCategories({
                  ...Object.keys(groupedFlags).reduce((acc, category) => {
                    acc[category] = false;
                    return acc;
                  }, {}),
                  [category]: !openCategories[category]
                });
              }}>
              {category}
              {openCategories[category] ? ( // show chevron up if category is open
                <KeyboardArrowUpIcon
                  className={[classes.chevronIcon, 'arrow-down'].join(' ')}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  className={[classes.chevronIcon, 'arrow-up'].join(' ')}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Typography>
            {categoryFlags.map((flag, index) => (
              <Block
                key={index}
                title={flag.title}
                description={flag.description}
                questions={flag?.questions}
                chart={flag?.chart}
                category={category}
                openCategories={openCategories[category]}
              />
            ))}
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

function Block({ category, title, description, questions, chart, openCategories }) {
  const classes = useStyles();
  let history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openCategories);
  }, [openCategories]);

  return (
    <Grid
      item
      spacing={1}
      direction="column"
      alignItems="center"
      className={classes.blockContainer}>
      <Paper elevation={0} className={classes.block}>
        <div
          style={{
            paddingLeft: '90px'
          }}>
          <Grid item container spacing={0} className={classes.blockInnerContainer}>
            <Grid item className={classes.flagWrapper} md={10}>
              <Typography className={classes.title} onClick={() => setOpen(!open)}>
                {title}
              </Typography>
              {(open || openCategories) && (
                <>
                  <Typography className={classes.description}>{description}</Typography>
                  <Grid container alignItems="center">
                    {questions && (
                      <>
                        <Grid item md={12}>
                          <Typography className={classes.questions}>
                            {questions?.split('\n').map((item, index) => {
                              return <p key={index}>{item}</p>;
                            })}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            {chart && (
              <>
                <Grid
                  item
                  style={{
                    textAlign: 'right',
                    paddingTop: 30
                  }}
                  className={classes.text}
                  md={2}>
                  <Button
                    className={classes.viewChartButton}
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      history.push(chart);
                    }}>
                    <span>VIEW CHART</span>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Paper>
    </Grid>
  );
}
