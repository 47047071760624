import React from 'react';
import { useParams } from 'react-router-dom';
import Error from '../../components/Error';
import PageSummary from '../../components/Company/PageSummary';
import TabMenu from '../../components/Projects/TabMenu';

import { Grid, makeStyles } from '@material-ui/core';
import SocialContent from '../../components/Social/SocialContent';
import Loading from '../../components/Loading';
import TechContent from '../../components/Tech/TechContent';

const useStyles = makeStyles(() => ({
  outer: {
    minWidth: '100%',
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingBottom: '100px',
    paddingTop: '20px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  }
}));

export default function Tech({
  project,
  isLoadingProject,
  isErrorProject,
  pagespeedInsightsData,
  isPagespeedInsightsLoading,
  organisation,
  isLoadingOrganisation,
  siteOptimisationChartData
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  if (isLoadingProject || isPagespeedInsightsLoading) return <Loading />;
  if (isLoadingOrganisation) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;
  const companyForHeader = company;

  return (
    <Grid className={classes.outer} direction="column">
      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={1} project={project} />
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        <PageSummary
          company={companyForHeader}
          project={project}
          tab="Technology"
          content={'tech-page'}
        />

        <TechContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          pagespeedInsightsData={pagespeedInsightsData}
          isPagespeedInsightsLoading={isPagespeedInsightsLoading}
          organisation={organisation}
          siteOptimisationChartData={siteOptimisationChartData}
        />
      </Grid>
    </Grid>
  );
}
