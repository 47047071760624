import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

export default function UpgradeButton({ justifyContent = 'flex-end', text = 'Data Export' }) {
  const history = useHistory();

  return (
    <Grid container justifyContent={justifyContent}>
      <Button
        color="primary"
        startIcon={<LockIcon />}
        variant="contained"
        disableElevation
        onClick={() => {
          history.push('/upgrade');
        }}>
        <span>{text}</span>
      </Button>
    </Grid>
  );
}
