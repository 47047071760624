import React from 'react';
import { useCMS } from '../../api';
import Error from '../../components/Error';

import { Grid, makeStyles } from '@material-ui/core';
import ChartAndDetailContainer from '../../components/Projects/ChartAndDetailContainer';
import ChartContainer from '../../components/Projects/ChartContainer';
import ChartDetail from '../../components/Projects/ChartDetail';
import * as fillTemplate from 'es6-dynamic-template';
import Loading from '../Loading';
import SiteOptimisation from './SiteOptimisation';
import CategoryCoverage from './CategoryCoverage';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  graphContainer: {
    width: '620px',
    height: '408px',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px'
  },

  pageBreakAfter: {
    [`@media print`]: {
      'page-break-after': 'always'
    }
  }
}));

export default function TechContent({
  project,
  isLoadingProject,
  isErrorProject,
  pagespeedInsightsData,
  isPagespeedInsightsLoading,
  organisation,
  siteOptimisationChartData
}) {
  const classes = useStyles();

  const cms = useCMS();

  if (isLoadingProject || isPagespeedInsightsLoading) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const getContentFromCms = (key) => {
    return cms && cms.data && cms?.data[key]?.content;
  };

  const siteOptimisationTemplateString = getContentFromCms('tech-maturity');
  const getSiteOptimisationChartTemplateContent = () => {
    return fillTemplate(siteOptimisationTemplateString, siteOptimisationChartData?.insights);
  };

  const categoryCoverageTemplateString = getContentFromCms('tech-dimensions');
  const categoryCoverageChartTemplateContent = () => {
    return fillTemplate(categoryCoverageTemplateString, siteOptimisationChartData?.insights);
  };

  // const followersVsEngagementsTemplateString = getContentFromCms('social-engagement');
  // const getFollowersVsEngagementsChartTemplateContent = () => {
  //   return fillTemplate(
  //     followersVsEngagementsTemplateString,
  //     followersVsEngagementsChartData?.insights
  //   );
  // };

  return (
    <>
      <div className={classes.pageBreakAfter}>
        {siteOptimisationChartData && siteOptimisationChartData.data && (
          <Grid item md={12} xs={12}>
            <ChartAndDetailContainer>
              <ChartContainer>
                <SiteOptimisation
                  title="Site Optimisation"
                  project={project}
                  dataFetched={project.dataFetched}
                  isPagespeedInsightsLoading={isPagespeedInsightsLoading}
                  pagespeedInsightsData={pagespeedInsightsData}
                  siteOptimisationChartData={siteOptimisationChartData}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={siteOptimisationChartData.score}>
                {cms &&
                  cms.data &&
                  cms.data['tech-maturity'] &&
                  !isPagespeedInsightsLoading &&
                  siteOptimisationChartData?.insights?.targetName &&
                  getSiteOptimisationChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          </Grid>
        )}
      </div>

      <div className={classes.pageBreakAfter}>
        {siteOptimisationChartData && siteOptimisationChartData.data && (
          <Grid item md={12} xs={12}>
            <ChartAndDetailContainer>
              <ChartContainer>
                <CategoryCoverage
                  title="Site Optimisation"
                  project={project}
                  dataFetched={project.dataFetched}
                  isPagespeedInsightsLoading={isPagespeedInsightsLoading}
                  pagespeedInsightsData={pagespeedInsightsData}
                  siteOptimisationChartData={siteOptimisationChartData}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={siteOptimisationChartData.score}>
                {cms &&
                  cms.data &&
                  cms.data['tech-dimensions'] &&
                  !isPagespeedInsightsLoading &&
                  siteOptimisationChartData?.insights?.targetName &&
                  categoryCoverageChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          </Grid>
        )}
      </div>
    </>
  );
}
