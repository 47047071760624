import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCMS, useProject } from '../../api';
import Error from '../../components/Error';
import CompanyInformationHeader from '../../components/Company/CompanyInformationHeader';
import TabMenu from '../../components/Projects/TabMenu';
import { Grid, makeStyles } from '@material-ui/core';
import Loading from '../../components/Loading';
import SummaryPageContent from '../../components/Summary/SummaryPageContent';

const useStyles = makeStyles((theme) => ({
  outer: {
    position: 'relative',
    paddingLeft: '80px',
    [`@media print`]: {
      margin: 0,
      padding: 0,
      top: 0
    }
  },
  container: {
    width: '90%',
    [`@media print`]: {
      margin: 0
    }
  },
  rowContainer: {
    marginTop: '10px'
  },
  title: {
    fontSize: '30px',
    color: '#3C21BA',
    padding: '0 10px'
  }
}));

export default function Summary({
  brandSearchChartData,
  followersVsEngagementsChartData,
  overviewChartData,
  searchTrendsChartData,
  socialAudienceChartData,
  socialbladeScore,
  summaryData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  trafficTrendsChartData,
  trustpilotChartData,
  trustpilotScore,
  websiteEngagementChartData,
  websiteEngagementScore,
  organisation,
  isLoadingOrganisation,
  siteOptimisationChartData
}) {
  if (!searchTrendsChartData.insights) return <Loading />;
  if (!trafficTrendsChartData.insights) return <Loading />;
  if (!overviewChartData || !overviewChartData.series || overviewChartData.series.length === 0) {
    return <Loading />;
  }
  if (
    !trafficSourceBreakdownChartData ||
    !trafficSourceBreakdownChartData.insights ||
    trafficSourceBreakdownChartData.insights.freeShare === undefined
  ) {
    return <Loading />;
  }
  if (
    !trafficByMonthChartData ||
    !trafficByMonthChartData.insights ||
    trafficByMonthChartData?.insights?.targetDecliningTraffic === undefined
  ) {
    return <Loading />;
  }
  if (websiteEngagementScore === undefined) return <Loading />;
  if (!brandSearchChartData.insights) return <Loading />;

  const classes = useStyles();
  const { projectId } = useParams();
  const { project, isLoading, isError } = useProject(projectId);

  if (isLoading) return <Loading />;
  if (isLoadingOrganisation) return <Loading />;
  if (isError) return <Error error={isError} />;

  const company = project.companyInfo;

  if (!company) return 'No company info available';

  return (
    <Grid className={classes.outer}>
      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={0} project={project} />
        </Grid>
      </Grid>

      <Grid className={classes.container} container item>
        <Grid item md={12}>
          <p className={classes.title}>Company</p>
        </Grid>
        {!isLoading ? (
          <CompanyInformationHeader company={company} project={project} match />
        ) : (
          <p>Loading</p>
        )}
      </Grid>

      <SummaryPageContent
        brandSearchChartData={brandSearchChartData}
        followersVsEngagementsChartData={followersVsEngagementsChartData}
        overviewChartData={overviewChartData}
        project={project}
        searchTrendsChartData={searchTrendsChartData}
        socialAudienceChartData={socialAudienceChartData}
        socialbladeScore={socialbladeScore}
        summaryData={summaryData}
        trafficByMonthChartData={trafficByMonthChartData}
        trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
        trafficTrendsChartData={trafficTrendsChartData}
        trustpilotChartData={trustpilotChartData}
        trustpilotScore={trustpilotScore}
        websiteEngagementScore={websiteEngagementScore}
        websiteEngagementChartData={websiteEngagementChartData}
        organisation={organisation}
        siteOptimisationChartData={siteOptimisationChartData}
      />
    </Grid>
  );
}
