import React from 'react';
import { Grid } from '@material-ui/core';
import ChartAndDetailContainer from '../../Projects/ChartAndDetailContainer';
import ChartContainer from '../../Projects/ChartContainer';
import IndustryTraffic from '../IndustryTraffic';
import ChartDetail from '../../Projects/ChartDetail';
import * as fillTemplate from 'es6-dynamic-template';
import Loading from '../../Loading';

export default function IndustryTrafficWrapper({
  project,
  isLoadingProject,
  filters,
  cms,
  trafficTrendsChartData,
  organisation
}) {
  if (isLoadingProject) return <Loading />;

  if (!project) return <Loading />;

  const data = trafficTrendsChartData.data;
  const series = trafficTrendsChartData.series;

  if (!data) return <Loading />;
  if (!series) return <Loading />;
  if (series.length === 0) return <Loading />;
  if (!organisation) return <Loading />;

  const templateString = cms && cms.data && cms?.data['traffic-trend']?.content;

  const getTemplateContent = () => {
    return fillTemplate(templateString, trafficTrendsChartData.insights);
  };

  return (
    <Grid item md={12}>
      <ChartAndDetailContainer reverse={false}>
        <ChartContainer>
          <IndustryTraffic
            title="Traffic Trend"
            subtitle="Last 4 years"
            dataFetched={project.dataFetched}
            project={project}
            filters={filters}
            data={data}
            series={series}
            categories={trafficTrendsChartData.categories}
            organisation={organisation}
          />
        </ChartContainer>

        <ChartDetail score={trafficTrendsChartData.score}>
          {cms && cms.data && cms.data['traffic-trend'] && getTemplateContent()}
        </ChartDetail>
      </ChartAndDetailContainer>
    </Grid>
  );
}
