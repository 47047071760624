import React from 'react';
import { useParams } from 'react-router-dom';
import Error from '../../components/Error';
import PageSummary from '../../components/Company/PageSummary';
import TabMenu from '../../components/Projects/TabMenu';

import { Grid, makeStyles } from '@material-ui/core';
import BrandContent from '../../components/Brand/BrandContent';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  outer: {
    left: '0px',
    overflowY: 'auto',
    minWidth: '100%',
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingBottom: '100px',
    paddingTop: '20px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  }
}));

export default function Brand({
  project,
  isLoadingProject,
  isErrorProject,
  brandSearchesChartData,
  isBrandSearchesLoading,
  trustpilotChartData,
  isTrustPilotLoading,
  organisation,
  isLoadingOrganisation
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  if (isLoadingProject || isBrandSearchesLoading) return <Loading />;
  if (isLoadingOrganisation) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;

  return (
    <Grid className={classes.outer}>
      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={1} project={project} />
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        <PageSummary company={company} project={project} tab="Brand" content={'brand-page'} />

        <BrandContent
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          project={project}
          brandSearchesChartData={brandSearchesChartData}
          isBrandSearchesLoading={isBrandSearchesLoading}
          trustpilotChartData={trustpilotChartData}
          isTrustPilotLoading={isTrustPilotLoading}
          organisation={organisation}
        />
      </Grid>
    </Grid>
  );
}
