import { CssBaseline, AppBar, Drawer, Grid } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CalendarViewDayOutlinedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import SchoolOutlined from '@material-ui/icons/SchoolOutlined';
import PersonIcon from '@mui/icons-material/Person';
import theme from './theme';
import Header from './Header';
import { Link, useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
const drawerWidth = 80;
const appBarHeight = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '@media print': {
      margin: 0
    }
  },
  appBar: {
    height: `${appBarHeight}px`,
    boxShadow: 'none',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: 'white',
    overflow: 'hidden',
    '@media print': {
      display: 'none'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '@media print': {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  rootLink: {
    margin: '1rem auto'
  },
  sideMenu: {
    marginTop: '8rem',
    textAlign: 'center'
  },
  sideMenuLink: {
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    minHeight: '30px',
    display: 'inline-flex',
    padding: '10px',
    color: '#561a8b',
    '& a:active': {
      backgroundColor: 'red'
    }
  },
  logo: {
    margin: '0 auto'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    minWidth: '100%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    height: `calc(100vh - ${appBarHeight}px)`,
    backgroundColor: theme.background,
    paddingLeft: `${drawerWidth}px`,
    '@media print': {
      margin: '0 !important',
      paddingLeft: '0 !important'
    }
  },
  childrenGridContainer: {
    width: '100%',
    minHeight: `calc(100% - ${appBarHeight}px)`
  }
}));

export default function LayoutContainer({ children }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar className={classes.appBar} position="relative">
        <Header />
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left">
        <Link to="/" className={classes.rootLink}>
          <img
            src="/img/prism_short_purple.svg"
            width="36"
            height="40"
            className={classes.logo}
            alt="Logo"
          />
        </Link>
        <div className={classes.sideMenu}>
          <Link to="/" className={classes.sideMenuLink}>
            <Tooltip title="Create report">
              <SearchOutlinedIcon />
            </Tooltip>
          </Link>
          <Link to="/projects" className={classes.sideMenuLink}>
            <Tooltip title="Projects">
              <CalendarViewDayOutlinedIcon />
            </Tooltip>
          </Link>
          <Link to="/advisory-hub" className={classes.sideMenuLink}>
            <Tooltip title="Advisory Hub">
              <PersonIcon />
            </Tooltip>
          </Link>
          <a
            href="https://prism.helpscoutdocs.com/"
            target="_blank"
            rel="noreferrer"
            className={classes.sideMenuLink}>
            <Tooltip title="Knowledge base">
              <img src="/img/ui/help.svg" alt="Help" />
            </Tooltip>
          </a>
          <button
            className={classes.sideMenuLink}
            onClick={() => {
              history.push({
                pathname: window.location.pathname,
                search: '?onboard=true'
              });
            }}>
            <Tooltip title="Tutorial">
              <SchoolOutlined />
            </Tooltip>
          </button>
        </div>{' '}
      </Drawer>
      <Grid container className={classes.content} direction="column" alignItems="stretch">
        <Grid className={classes.childrenGridContainer} item>
          {children}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
