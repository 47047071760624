import React, { useEffect, useState } from 'react';
import { useCMS } from '../../api';
import Error from '../../components/Error';
import Overview from '../../components/CompetitorAnalysis/Overview';
import WebsiteEngagement from '../../components/CompetitorAnalysis/WebsiteEngagement';
import WebsiteTrafficVolumes from '../../components/CompetitorAnalysis/WebsiteTrafficVolumes';
import TrafficSourceBreakdown from '../../components/CompetitorAnalysis/TrafficSourceBreakdown';
import ChartDetail from '../../components/Projects/ChartDetail';
import { Grid, makeStyles } from '@material-ui/core';
import ChartAndDetailContainer from '../../components/Projects/ChartAndDetailContainer';
import ChartContainer from '../../components/Projects/ChartContainer';
import * as fillTemplate from 'es6-dynamic-template';
import Loading from '../Loading';
import MobileTrafficSourceBreakdown from './MobileTrafficSourceBreakdown';
import CombinedTrafficSourceBreakdown from './CombinedTrafficSourceBreakdown';
import isArrayEmpty from '../../utils/isArrayEmpty';
import TrafficSourceSelect from './TrafficSourceSelect';

const useStyles = makeStyles(() => ({
  outer: {
    position: 'absolute',
    top: '80px',
    left: '0px',
    overflowY: 'auto',
    minWidth: '100%',
    paddingLeft: '150px'
  },
  container: {
    marginTop: '40px',
    width: '90%',
    paddingBottom: '100px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  graphContainer: {
    width: '620px',
    height: '408px',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px'
  },

  detailContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    height: '100%'
  },

  pageBreakAfter: {
    [`@media print`]: {
      'page-break-after': 'always'
    }
  },

  platformSelectContainer: {
    textAlign: 'right',
    padding: '10px',

    [`@media print`]: {
      display: 'none'
    }
  }
}));

export default function CompetitorAnalysisContent(props) {
  const {
    projectId,
    project,
    isLoadingProject,
    isErrorProject,
    countries,
    filters,
    applyFilters,
    websiteTrafficVolumes,
    isWebsiteTrafficVolumesLoading,
    overviewChartData,
    trafficSourceBreakdownChartData,
    isTrafficSourceVolumesLoading,
    isMobileTrafficSourceVolumesLoading,
    trafficSourceVolumesData,
    mobileTrafficVolumesData,
    mobileTrafficSourceBreakdownChartData,
    combinedTrafficSourceBreakdownChartData,
    trafficByMonthChartData,
    websiteEngagementChartData,
    isWebsiteEngagementLoading,
    organisation,
    isLoadingOrganisation
  } = props;
  const classes = useStyles();
  const [trafficSourceChartType, setTrafficSourceChartType] = useState('trafficSourceVolumes');
  useEffect(() => {
    if (!isArrayEmpty(mobileTrafficSourceBreakdownChartData.series)) {
      setTrafficSourceChartType('combinedTrafficVolumes');
    }
  }, [mobileTrafficSourceBreakdownChartData.series]);

  const cms = useCMS();

  if (isLoadingProject) return <Loading />;
  if (!cms) return <Loading />;
  if (isLoadingOrganisation) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const overviewChartTemplateString = cms && cms.data && cms?.data['traffic-total']?.content;
  const getOverviewChartTemplateContent = () => {
    return fillTemplate(overviewChartTemplateString, overviewChartData?.insights);
  };

  const trafficSourceBreakdownChartTemplateString =
    cms && cms.data && cms?.data['traffic-by-source']?.content;
  const getTrafficSourceBreakdownChartTemplateContent = () => {
    const chartData =
      Object.keys(combinedTrafficSourceBreakdownChartData.insights).length === 0
        ? trafficSourceBreakdownChartData
        : combinedTrafficSourceBreakdownChartData;
    return fillTemplate(trafficSourceBreakdownChartTemplateString, chartData?.insights);
  };

  const getTrafficSourceScore = () => {
    const chartData = combinedTrafficSourceBreakdownChartData || trafficSourceBreakdownChartData;
    return chartData.score;
  };

  const trafficByMonthChartTemplateString =
    cms && cms.data && cms?.data['traffic-by-month']?.content;
  const getTrafficByMonthChartTemplateContent = () => {
    return fillTemplate(trafficByMonthChartTemplateString, trafficByMonthChartData?.insights);
  };

  const websiteEngagementChartTemplateString =
    cms && cms.data && cms?.data['site-engagement']?.content;
  const getWebsiteEngagementChartTemplateContent = () => {
    return fillTemplate(websiteEngagementChartTemplateString, websiteEngagementChartData?.insights);
  };

  return (
    <>
      {!!countries.length && (
        <Grid item md={12} xs={12} className={classes.pageBreakAfter}>
          <ChartAndDetailContainer>
            <ChartContainer>
              <Overview
                title="Total Traffic"
                project={project}
                countries={countries}
                dataFetched={project.dataFetched}
                websiteTrafficVolumes={websiteTrafficVolumes}
                isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
                overviewChartData={overviewChartData}
                organisation={organisation}
              />
            </ChartContainer>
            <ChartDetail score={overviewChartData.score}>
              {cms &&
                cms.data &&
                cms.data['traffic-total'] &&
                !isWebsiteTrafficVolumesLoading &&
                overviewChartData?.insights?.targetName &&
                getOverviewChartTemplateContent()}
            </ChartDetail>
          </ChartAndDetailContainer>
        </Grid>
      )}

      {!!countries.length && (
        <Grid item md={12} xs={12} className={classes.pageBreakAfter}>
          <ChartAndDetailContainer>
            <ChartContainer>
              <WebsiteTrafficVolumes
                title="Traffic By Month"
                project={project}
                countries={countries}
                dataFetched={project.dataFetched}
                websiteTrafficVolumes={websiteTrafficVolumes}
                isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
                trafficByMonthChartData={trafficByMonthChartData}
                organisation={organisation}
              />
            </ChartContainer>
            <ChartDetail score={trafficByMonthChartData.score}>
              {cms &&
                cms.data &&
                cms.data['traffic-by-month'] &&
                !isWebsiteTrafficVolumesLoading &&
                trafficByMonthChartData?.insights?.targetName &&
                getTrafficByMonthChartTemplateContent()}
            </ChartDetail>
          </ChartAndDetailContainer>
        </Grid>
      )}

      {!!countries.length && (
        <Grid item md={12} xs={12} className={classes.pageBreakAfter}>
          {trafficSourceChartType === 'trafficSourceVolumes' && (
            <ChartAndDetailContainer>
              <ChartContainer>
                <TrafficSourceSelect
                  classes={classes}
                  trafficSourceChartType={trafficSourceChartType}
                  setTrafficSourceChartType={setTrafficSourceChartType}
                  combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
                  mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
                />
                <TrafficSourceBreakdown
                  title="Traffic Source Breakdown"
                  project={project}
                  countries={countries}
                  dataFetched={project.dataFetched}
                  trafficSourceVolumesData={trafficSourceVolumesData}
                  trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
                  isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
                  organisation={organisation}
                />
              </ChartContainer>

              <ChartDetail score={getTrafficSourceScore()}>
                {cms &&
                  cms.data &&
                  cms.data['traffic-by-source'] &&
                  !isTrafficSourceVolumesLoading &&
                  !isMobileTrafficSourceVolumesLoading &&
                  trafficSourceBreakdownChartData?.insights?.targetName &&
                  getTrafficSourceBreakdownChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          )}

          {trafficSourceChartType === 'mobileTrafficVolumes' && (
            <ChartAndDetailContainer>
              <ChartContainer>
                <TrafficSourceSelect
                  classes={classes}
                  trafficSourceChartType={trafficSourceChartType}
                  setTrafficSourceChartType={setTrafficSourceChartType}
                  combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
                  mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
                />
                <MobileTrafficSourceBreakdown
                  title="Mobile Traffic Source Breakdown"
                  project={project}
                  countries={countries}
                  dataFetched={project.dataFetched}
                  mobileTrafficVolumesData={mobileTrafficVolumesData}
                  mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
                  isMobileTrafficSourceVolumesLoading={isMobileTrafficSourceVolumesLoading}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={getTrafficSourceScore()}>
                {cms &&
                  cms.data &&
                  cms.data['traffic-by-source'] &&
                  !isMobileTrafficSourceVolumesLoading &&
                  combinedTrafficSourceBreakdownChartData?.insights?.targetName &&
                  getTrafficSourceBreakdownChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          )}

          {trafficSourceChartType === 'combinedTrafficVolumes' && (
            <ChartAndDetailContainer>
              <ChartContainer>
                <TrafficSourceSelect
                  classes={classes}
                  trafficSourceChartType={trafficSourceChartType}
                  setTrafficSourceChartType={setTrafficSourceChartType}
                  combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
                  mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
                />
                <CombinedTrafficSourceBreakdown
                  title="Mobile Traffic Source Breakdown"
                  project={project}
                  countries={countries}
                  dataFetched={project.dataFetched}
                  mobileTrafficVolumesData={mobileTrafficVolumesData}
                  trafficVolumesData={trafficSourceVolumesData}
                  combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
                  isMobileTrafficSourceVolumesLoading={isMobileTrafficSourceVolumesLoading}
                  isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={getTrafficSourceScore()}>
                {cms &&
                  cms.data &&
                  cms.data['traffic-by-source'] &&
                  !isMobileTrafficSourceVolumesLoading &&
                  combinedTrafficSourceBreakdownChartData?.insights?.targetName &&
                  getTrafficSourceBreakdownChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          )}
        </Grid>
      )}

      {!!countries.length && (
        <Grid item md={12} className={classes.pageBreakAfter}>
          <ChartAndDetailContainer>
            <ChartContainer>
              <WebsiteEngagement
                title="Website Engagement"
                project={project}
                filters={filters}
                countries={countries}
                dataFetched={project.dataFetched}
                websiteEngagementChartData={websiteEngagementChartData}
                isWebsiteEngagementLoading={isWebsiteEngagementLoading}
                organisation={organisation}
              />
            </ChartContainer>
            <ChartDetail score={websiteEngagementChartData.score}>
              {cms &&
                cms.data &&
                cms.data['site-engagement'] &&
                !isWebsiteEngagementLoading &&
                websiteEngagementChartData?.insights?.targetName &&
                getWebsiteEngagementChartTemplateContent()}
            </ChartDetail>
          </ChartAndDetailContainer>
        </Grid>
      )}
    </>
  );
}
