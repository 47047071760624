// Social Audience chart

import React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import moment from 'moment';
import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { format } from 'd3-format';
import { abbreviateNumber } from '../../utils/numbers';
import { mean, sum } from 'lodash';
// import './social.css'
import { CSVLink, CSVDownload } from 'react-csv';
import Loading from '../Loading';
import UpgradeButton from '../Auth/UpgradeButton';
import { useAuth } from '../../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  },

  platformSelectContainer: {
    textAlign: 'right',

    [`@media print`]: {
      display: 'none'
    }
  },
  downloadCSV: {
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    fontSize: 12,
    fontFamily:
      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    '&:hover': { color: '#1976d2' },
    fontWeight: 500,
    lineHeight: 12,
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '5px 8px',
    textDecoration: 'none'
  }
}));

export default function EngagementVsFollowers({
  project,
  dataFetched,
  followersVsEngagementsChartData,
  isSocialbladeLoading,
  organisation
}) {
  // SOCIAL ENGAGEMENT CHART
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;
  if (isSocialbladeLoading) return <Loading />;
  if (!followersVsEngagementsChartData.data) return <Loading />;
  if (isArrayEmpty(followersVsEngagementsChartData.data.socialbladeData)) return <Loading />;

  const [selectedPlatform, setSelectedPlatform] = React.useState('all');

  const handleChangeSelectedPlatform = (event) => {
    setSelectedPlatform(event.target.value);
  };

  // array of objects, each object represents one brand
  // [ {domain, data: [followersTotal, engagementTotal]} ]

  const data = {
    categories: followersVsEngagementsChartData.data.map((d) => d.domain),
    series: followersVsEngagementsChartData.data.map((d) => {
      const brand = d.socialbladeData;
      const facebookFollowers = brand?.facebook?.statistics?.total?.likes || 0;
      const instagramFollowers = brand?.instagram?.statistics?.total?.followers || 0;
      const followers =
        selectedPlatform === 'all'
          ? facebookFollowers + instagramFollowers
          : selectedPlatform === 'facebook'
          ? facebookFollowers
          : instagramFollowers;

      const facebookEngagements =
        (brand?.facebook?.daily && mean(brand.facebook.daily.map((d) => d.talking_about))) || 0;
      const instagramEngagements =
        (brand?.instagram?.statistics?.average?.likes || 0) +
        (brand?.instagram?.statistics?.average?.comments || 0);
      const engagements =
        selectedPlatform === 'all'
          ? facebookEngagements + instagramEngagements
          : selectedPlatform === 'facebook'
          ? facebookEngagements
          : instagramEngagements;

      return {
        name: d.domain,
        data: [[followers, engagements]]
      };
    })
  };

  const classes = useStyles();

  const { series, categories } = data;

  console.table(
    series.map((s) => ({
      domain: s.name,
      x: s.data[0][0],
      y: s.data[0][1]
    }))
  );
  const csvData = series.map((s) => ({
    Domain: s.name,
    Followers: Math.round(s.data[0][0]),
    Engagements: Math.round(s.data[0][1])
  }));

  const maxX = Math.max(...series.map((s) => s.data[0][0]));

  // Round to a specified number of digits after the decimal. 0 means round to
  // integer value. Negative values mean round to digits before decimal.
  function roundDecimal(x, digits = 0) {
    const n = Math.pow(10, digits);
    return Math.ceil(x * n) / n;
  }

  function roundSignif(x, digits = 1) {
    if (digits < 1) {
      throw 'Significant digits must be 1 or greater';
    }

    const scaleFactor = Math.floor(Math.log10(Math.abs(x)));
    return roundDecimal(x, digits - scaleFactor - 1);
  }

  const xAxisMaxValue = roundSignif(maxX, 2);

  if (!(dataFetched && !isArrayEmpty(data))) {
    return (
      <Paper className={classes.graphContainer} elevation={0}>
        {/* <h3 className={classes.graphTitle}>{title}</h3> */}
        <Skeleton width="50%" />
        <Skeleton width="20%" />
        <Skeleton width="30%" />
        <Skeleton width="40%" />
        <Skeleton width="25%" />
      </Paper>
    );
  }

  return (
    <Grid>
      {series && categories && (
        <Paper className={classes.graphContainer} elevation={0} id="social-engagement-chart">
          <div className={classes.platformSelectContainer}>
            <select
              name="aaa"
              id="aaa"
              value={selectedPlatform}
              onChange={handleChangeSelectedPlatform}
              style={{
                zIndex: 1000000,
                position: 'relative'
              }}>
              <option value="all">All</option>
              <option value="facebook">Facebook</option>
              <option value="instagram">Instagram</option>
            </select>
          </div>
          <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
            {orgType === 'Lite' ? (
              <UpgradeButton />
            ) : (
              <CSVLink
                className={classes.downloadCSV}
                data={csvData}
                filename={'social_engagement.csv'}>
                Download CSV
              </CSVLink>
            )}
          </div>

          <Chart
            className="traffic-trend-chart"
            options={{
              grid: {
                yaxis: {
                  lines: {
                    show: false
                  }
                }
              },
              legend: {
                offsetY: 5
              },
              dataLabels: {
                formatter: function (value, opts) {
                  const dp = 0;
                  return abbreviateNumber(value, dp);
                }
              },
              markers: {
                size: 10
              },
              xaxis: {
                lines: {
                  show: false
                },
                title: {
                  text: 'Followers'
                },
                type: 'numeric',
                min: 0,
                max: xAxisMaxValue,
                tickAmount: 10,
                labels: {
                  formatter: function (val) {
                    return format('.2s')(val);
                  }
                }
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  // color: '#78909C',
                  offsetX: 0,
                  offsetY: 0
                },
                title: {
                  text: 'Engagements'
                },
                labels: {
                  formatter: function (value) {
                    const dp = 0;

                    return abbreviateNumber(value, dp);
                  }
                }
              },
              chart: {
                toolbar: {
                  tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                  }
                }
              },
              // yaxis: {
              //   labels: {
              //     // formatter: function (value) {
              //     //   let dp = 0
              //     //
              //     //   return abbreviateNumber(value, dp)
              //     // }
              //   }
              // },
              colors: ['#F67DFF', '#5D2AFF', '#3CC0FF', '#F1C70B', '#4F82FF', '#1F004A']
              // colors: ['#1F004A', '#03CEA4', '#F0C808', '#ED217C', '#5E2BFF', '#F67DFF']
            }}
            series={series}
            type="scatter"
            width="100%"
          />
        </Paper>
      )}
    </Grid>
  );
}
