import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  highlight: {
    color: '#D7B679'
  },
  circle: {
    backgroundColor: '#D7B679',
    color: '#FFF',
    padding: '3px 6px',
    borderRadius: '50%'
  }
}));

export default function CreditsMain() {
  const classes = useStyles();
  return <span />;
}
