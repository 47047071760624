// Trustpilot chart

import React from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classNames from 'classnames';
import { sortBy } from 'lodash';
import { CSVLink, CSVDownload } from 'react-csv';
import Loading from '../Loading';
import UpgradeButton from '../Auth/UpgradeButton';
import { useAuth } from '../../hooks/use-auth';

const colors = {
  red: '#febebe',
  amber: '#fff2e5',
  green: '#e4f9f4'
};

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    width: '100%',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    overflow: 'auto'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  customCell: {
    borderBottom: 'none',
    '& p': {
      margin: 0
    }
  },
  customHeaderCellTitle: {
    minWidth: '100px',
    borderRadius: '5px',
    padding: '10px'
  },
  customHeaderCellTitleGreen: {
    background: colors.green
  },
  customHeaderCellTitleAmber: {
    background: colors.amber
  },
  customHeaderCellTitleRed: {
    background: colors.red
  },
  customTableBody: {
    '& tr > td > p': {
      backgroundColor: '#E8E5EC',
      color: '#1F004A',
      margin: '0px',
      padding: '8px',
      borderRadius: '5px'
    },
    '& tr > th > p': {
      backgroundColor: '#E8E5EC',
      color: '#1F004A',
      margin: '0px',
      padding: '8px',
      borderRadius: '5px'
    }
  },
  customCellGreen: {
    border: '1px solid #c5e0b4'
  },
  customCellAmber: {},
  customCellRed: {
    border: '1px solid #ff7e78'
  },
  downloadCSV: {
    float: 'right',
    marginRight: '20px',
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    fontSize: 12,
    fontFamily:
      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    '&:hover': { color: '#1976d2' },
    fontWeight: 500,
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '5px 8px',
    textDecoration: 'none'
  }
}));

export default function TrustPilot({
  project,
  dataFetched,
  trustpilotChartData,
  isTrustPilotLoading,
  organisation
}) {
  // BRAND CUSTOMER EXPERIENCE CHART
  const { orgType } = organisation;

  if (!project) return <Loading />;
  if (isTrustPilotLoading) return <Loading />;
  if (!trustpilotChartData.data) return <p>No trustpilot data</p>;

  const data = trustpilotChartData.data || [];

  const classes = useStyles();

  function createData(
    domain,
    trustPilotScore,
    noOfReviews,
    profileClaimed,
    askingForReviews,
    negativeReviewsReplyRate,
    daysToReply,
    isValid
  ) {
    const activeManagementScore =
      33 * Number(profileClaimed) +
      33 * Number(askingForReviews) +
      34 * (negativeReviewsReplyRate / (100 * (daysToReply < 1 ? 1 : daysToReply)));

    return {
      domain,
      trustPilotScore,
      noOfReviews,
      profileClaimed,
      askingForReviews,
      negativeReviewsReplyRate,
      daysToReply,
      activeManagementScore,
      isValid
    };
  }

  const rows = data.map((item) => {
    return createData(
      item.domain,
      item.businessUnit?.trustScore,
      item.businessUnit?.numberOfReviews || 0,
      item.businessUnit?.isClaimed,
      item.businessUnit?.activity?.isAskingForReviews,
      item.businessUnit?.activity?.replyBehavior.replyPercentage.toFixed(0),
      item.businessUnit?.activity?.replyBehavior.averageDaysToReply,
      !!item.businessUnit
    );
  });

  const calculateTrustpilotColourClassname = (score) => {
    return score < 3
      ? classes.customHeaderCellTitleRed
      : score < 4
      ? classes.customHeaderCellTitleAmber
      : classes.customHeaderCellTitleGreen;
  };

  const calculateActiveManagementScoreColour = (score) => {
    if (score === undefined) return;
    const roundedScore = score.toFixed(0);

    switch (true) {
      case roundedScore <= 33:
        return classes.customHeaderCellTitleRed;
      case roundedScore <= 66:
        return classes.customHeaderCellTitleAmber;
      default:
        return classes.customHeaderCellTitleGreen;
    }
  };

  const numberWithCommas = (x) => {
    if (x === undefined) return;
    // not safari compatible as it has lookbehind
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

    return x.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
  };

  const csvData = rows.map((row) => {
    return {
      domain: row.domain,
      trustPilotScore: Math.round(Number(row.trustPilotScore)),
      noOfReviews: Math.round(Number(row.noOfReviews)),
      activeManagementScore: Math.round(Number(row.activeManagementScore))
    };
  });

  return (
    <Grid>
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.graphContainer}
          id="brand-customer-experience">
          {orgType === 'Lite' ? (
            <UpgradeButton />
          ) : (
            <CSVLink
              className={classes.downloadCSV}
              data={csvData}
              filename={'customer_experience.csv'}>
              Download CSV
            </CSVLink>
          )}

          {/* <h3 className={classes.graphTitle}>{title}</h3> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.customCell} />
                <TableCell className={classes.customCell} align="center">
                  <Box className={classNames(classes.customHeaderCellTitle)}>
                    <p>Trustpilot Score</p>
                  </Box>
                </TableCell>
                <TableCell className={classes.customCell} align="center">
                  <div className={classNames(classes.customHeaderCellTitle)}>
                    <p># of Reviews</p>
                  </div>
                </TableCell>
                <TableCell className={classes.customCell} align="center">
                  <div className={classNames(classes.customHeaderCellTitle)}>
                    <p>Reputation Management Score</p>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.customTableBody}>
              {sortBy(rows, ['noOfReviews'])
                .reverse()
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.customCell} component="th" scope="row">
                      {row.domain == project.name ? <b>{row.domain}</b> : row.domain}
                    </TableCell>

                    {row.isValid && row.noOfReviews > 0 && (
                      <>
                        <TableCell className={classNames(classes.customCell)} align="center">
                          <div>
                            <p
                              className={classNames(
                                classes.customHeaderCellTitle,
                                calculateTrustpilotColourClassname(row.trustPilotScore)
                              )}>
                              {row.trustPilotScore}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell className={classes.customCell} align="center">
                          <p>{numberWithCommas(row.noOfReviews)}</p>
                        </TableCell>

                        <TableCell className={classes.customCell} align="center">
                          <div>
                            <p
                              className={classNames(
                                classes.customHeaderCellTitle,
                                calculateActiveManagementScoreColour(row.activeManagementScore)
                              )}>
                              {row.activeManagementScore && row.activeManagementScore.toFixed()}
                            </p>
                          </div>
                        </TableCell>
                      </>
                    )}

                    {!(row.isValid && row.noOfReviews > 0) && (
                      <TableCell
                        className={classNames(classes.customCell)}
                        align="left"
                        colSpan={3}>
                        <p
                          className={classNames(
                            classes.customHeaderCellTitle,
                            calculateTrustpilotColourClassname(row.trustPilotScore)
                          )}>
                          No Trustpilot profile found
                        </p>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
