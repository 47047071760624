import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const colors = {
  red: '#febebe',
  amber: '#fff2e5',
  green: '#e4f9f4',
  grey: '#f1f1f1'
};

const textColors = {
  red: '#ee5c5c',
  amber: '#ff8101',
  green: '#27d3af',
  grey: '#9e9e9e'
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%'
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px',
    padding: '0 20px',
    borderRadius: '20px',
    width: '100%'
  },

  scoreContainer: {
    width: '100%',
    margin: '10px 0 10px 20px',
    padding: '20px 0 0 0px'
  },

  scoreBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: (props) => props.color,
    borderColor: (props) => props.color,
    borderRadius: '10px',
    '& div.scoreValue': {
      backgroundColor: 'white',
      padding: '5px 10px',
      marginLeft: '20px',
      fontSize: '1.5em',
      fontWeight: 'bold',
      borderRadius: '5px',
      '& span:first-child': {
        color: (props) => props.textColor
      },
      '& span:last-child': {
        color: 'lightgrey'
      }
    }
  },

  scoreTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: (props) => props.textColor
  }
}));

export default function ChartScore({ score }) {
  const NA = 'N/A';
  if (score === undefined) return <></>;

  const scoreToRender = score !== NA ? (+score).toFixed(0) : NA;

  const calculateColourFromScore = (score) => {
    if (score === undefined) return;
    if (score === null) return colors.grey;

    const roundedScore = (+score).toFixed(0);
    switch (true) {
      case roundedScore <= 33:
        return colors.red;
      case roundedScore <= 66:
        return colors.amber;
      default:
        return colors.green;
    }
  };

  const calculateTextColourFromScore = (score) => {
    if (score === undefined) return;
    if (score === null) return textColors.grey;

    const roundedScore = (+score).toFixed(0);
    switch (true) {
      case roundedScore <= 33:
        return textColors.red;
      case roundedScore <= 66:
        return textColors.amber;
      default:
        return textColors.green;
    }
  };

  const classes = useStyles({
    color: calculateColourFromScore(score),
    textColor: calculateTextColourFromScore(score)
  });

  return (
    <>
      {typeof score !== 'undefined' && (
        <div className={classes.scoreContainer}>
          <div className={classNames(classes.scoreBox)}>
            <span className={classes.scoreTitle}>Score</span>
            <div className={classNames('scoreValue', classes.scoreValue)}>
              {score !== null && (
                <>
                  <span>{scoreToRender}</span>
                  <span>/100</span>
                </>
              )}

              {score === null && (
                <>
                  <span>n/a</span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
