import ExcelJS from 'exceljs';
import { ordinalSuffixOf } from '../../utils/numbers';
import moment from 'moment';
import { saveAs } from 'file-saver';

export async function downloadDataExport(
  project,
  summaryData,
  searchTrendsChartData,
  trafficTrendsChartData,
  overviewChartData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  websiteEngagementChartData,
  brandSearchChartData,
  trustpilotChartData,
  socialAudienceChartData,
  followersVsEngagementsChartData
) {
  const DATE_NUMBER_FORMAT = 'dd/mm/yyyy';

  const cellBlueBackground = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '0000FF' }
  };

  const cellWhiteText = {
    color: { argb: 'FFFFFF' }
  };

  const formatCellBlueBar = (cell) => {
    cell.fill = cellBlueBackground;
    cell.font = cellWhiteText;
  };

  const cellGreyBackground = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'D3D3D3' }
  };

  const cellBlackText = {
    color: { argb: '000000' }
  };

  const formatCellGreyWithBorder = (cell) => {
    cell.fill = cellGreyBackground;
    cell.font = cellBlackText;
  };

  const formatBlueRow = (worksheet, row) => {
    for (let i = 2; i <= 52; i++) {
      formatCellBlueBar(worksheet.getRow(row).getCell(i));
    }
  };

  const formatTitle = (cell) => {
    cell.font = {
      bold: true
    };
  };

  // Data prep
  const { name } = project;

  const workbook = new ExcelJS.Workbook();
  const contentsWorksheet = workbook.addWorksheet('Contents');

  // Widths
  contentsWorksheet.getColumn('A').width = 2;
  contentsWorksheet.getColumn('B').width = 15;
  contentsWorksheet.getColumn('C').width = 15;

  const contentsPageTitleCell = contentsWorksheet.getCell('B2');
  contentsPageTitleCell.value = 'Prism Data Export';
  formatTitle(contentsPageTitleCell);

  contentsWorksheet.getCell('B5').value = 'Project Name';
  contentsWorksheet.getCell('B6').value = 'Project ID';
  contentsWorksheet.getCell('B7').value = 'Created at';
  contentsWorksheet.getCell('B8').value = 'Location';
  contentsWorksheet.getCell('B9').value = 'Target';
  contentsWorksheet.getCell('B10').value = 'Competitors';

  contentsWorksheet.getCell('C5').value = name;
  contentsWorksheet.getCell('C6').value = project.id;
  contentsWorksheet.getCell('C7').value = moment(project.createdAt).format('DD/MM/YYYY');
  contentsWorksheet.getCell('C8').value = project.countries[0];
  contentsWorksheet.getCell('C9').value = project.domain;
  contentsWorksheet.getCell('C10').value = project.competitors.join(', ');

  // Summary Worksheet
  const summaryWorksheet = workbook.addWorksheet('Summary');

  summaryWorksheet.getColumn('A').width = 2;
  summaryWorksheet.getColumn('B').width = 20;
  summaryWorksheet.getColumn('C').width = 20;

  formatBlueRow(summaryWorksheet, 5);

  const summaryPageTitleCell = summaryWorksheet.getCell('B2');
  summaryPageTitleCell.value = 'Summary Page Data Export';
  formatTitle(summaryPageTitleCell);

  summaryWorksheet.getCell('B5').value = 'Summary'; // set to blue

  summaryWorksheet.getCell('B7').value = 'Market Indicators';

  summaryWorksheet.getCell('B9').value = 'Market Growth Score';
  summaryWorksheet.getCell('C9').value = summaryData.insights.marketScore; // get value

  summaryWorksheet.getCell('B11').value = 'Competitive Benchmarking';

  summaryWorksheet.getCell('B13').value = 'Website';
  summaryWorksheet.getCell('C13').value = 'Score';
  summaryWorksheet.getCell('D13').value = 'Rank';

  summaryData?.insights.rankedAssets.forEach((item, index) => {
    summaryWorksheet.getCell(`B${14 + index}`).value = item.name;
    summaryWorksheet.getCell(`C${14 + index}`).value = item.score.toFixed(0);
    summaryWorksheet.getCell(`D${14 + index}`).value = ordinalSuffixOf(item.rank);
  });

  // Market Worksheet
  const marketWorksheet = workbook.addWorksheet('Market');

  marketWorksheet.getColumn('A').width = 2;
  marketWorksheet.getColumn('B').width = 20;
  marketWorksheet.getColumn('C').width = 20;

  const marketPageTitleCell = marketWorksheet.getCell('B2');
  marketPageTitleCell.value = 'Market Page Data Export';
  formatTitle(marketPageTitleCell);

  // Market - Search Growth
  marketWorksheet.getCell('B6').value = 'Search Growth'; // blue row

  marketWorksheet.getCell('B8').value = 'Score';
  marketWorksheet.getCell('C8').value = searchTrendsChartData.score.toFixed(0);

  marketWorksheet.getCell('B10').value = 'Date';
  marketWorksheet.getCell('B11').value = 'Market Search Growth';
  marketWorksheet.getCell('B12').value = 'Trendline';

  const [searchGrowthSeriesData, searchGrowthTrendlineData] = searchTrendsChartData.series;
  searchGrowthSeriesData.data.forEach((item, index) => {
    const date = item.x;
    const marketSearchGrowth = item.y;

    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');

    marketWorksheet.getRow(10).getCell(3 + index).numFmt = DATE_NUMBER_FORMAT;
    marketWorksheet.getRow(10).getCell(3 + index).value = formattedDate;
    marketWorksheet.getRow(11).getCell(3 + index).value = marketSearchGrowth;
  });

  searchGrowthTrendlineData.data.forEach((item, index) => {
    marketWorksheet.getRow(12).getCell(3 + index).value = item.y;
  });

  // Set to blue:
  formatBlueRow(marketWorksheet, 6);

  // Market - Traffic Growth
  marketWorksheet.getCell('B15').value = 'Traffic Growth'; // blue row
  marketWorksheet.getCell('B17').value = 'Score';
  marketWorksheet.getCell('C17').value = trafficTrendsChartData.score.toFixed(0);

  marketWorksheet.getCell('B19').value = 'Date';
  marketWorksheet.getCell('B20').value = 'Market Traffic Growth';
  marketWorksheet.getCell('B21').value = 'Trendline';

  const [trafficGrowthSeriesData, trafficGrowthTrendlineData] = trafficTrendsChartData.series;
  const trafficGrowthSeriesDates = trafficTrendsChartData.categories.map((item) =>
    moment(item).format('YYYY-MM')
  );

  trafficGrowthSeriesDates.forEach((item, index) => {
    const FORMAT = 'MM/YYYY';
    const formattedDate = moment(item, 'YYYY-MM').format(FORMAT);
    marketWorksheet.getRow(10).getCell(3 + index).numFmt = FORMAT;
    marketWorksheet.getRow(19).getCell(3 + index).value = formattedDate;
  });

  trafficGrowthSeriesData.data.forEach((item, index) => {
    marketWorksheet.getRow(20).getCell(3 + index).value = item;
  });

  trafficGrowthTrendlineData.data.forEach((item, index) => {
    marketWorksheet.getRow(21).getCell(3 + index).value = item;
  });

  // Set to blue:
  formatBlueRow(marketWorksheet, 15);

  // Performance Worksheet

  const performanceWorksheet = workbook.addWorksheet('Performance');

  // Set blue rows
  formatBlueRow(performanceWorksheet, 5);
  formatBlueRow(performanceWorksheet, 13);
  formatBlueRow(performanceWorksheet, 24);
  formatBlueRow(performanceWorksheet, 35);

  performanceWorksheet.getColumn('A').width = 2;
  performanceWorksheet.getColumn('B').width = 20;
  performanceWorksheet.getColumn('C').width = 20;

  const performancePageTitleCell = performanceWorksheet.getCell('B2');
  performancePageTitleCell.value = 'Performance Page Data Export';
  formatTitle(performancePageTitleCell);

  // Performance - Traffic Share

  performanceWorksheet.getCell('B5').value = 'Traffic Share'; // blue row

  performanceWorksheet.getCell('B7').value = 'Brand';
  performanceWorksheet.getCell('B8').value = 'Traffic';
  performanceWorksheet.getCell('B10').value = 'Score';

  overviewChartData.competitors.forEach((competitor, index) => {
    performanceWorksheet.getRow(7).getCell(3 + index).value = competitor;
  });

  overviewChartData.series[0].data.forEach((item, index) => {
    performanceWorksheet.getRow(8).getCell(3 + index).value = item;
  });

  overviewChartData.insights.scores.forEach((item, index) => {
    performanceWorksheet.getRow(10).getCell(3 + index).value = item.score.toFixed(0);
  });

  // Performance - Traffic Trend (Traffic by Month)

  performanceWorksheet.getCell('B13').value = 'Traffic Trend'; // blue row

  // Add dates on row 15 from column 3
  trafficByMonthChartData.categories.forEach((item, index) => {
    performanceWorksheet.getRow(15).getCell(3 + index).value = item.format('MM/YYYY');
  });

  // Calculate the score column
  const trafficByMonthScoreColumn = 3 + trafficByMonthChartData.categories.length + 1; // Add 1 for a space between the data and the score

  // Cell 16 should be the score column
  performanceWorksheet.getRow(15).getCell(trafficByMonthScoreColumn).value = 'Score';

  trafficByMonthChartData.series.forEach((dataset, rowIndex) => {
    const row = 16 + rowIndex;

    // Add name
    performanceWorksheet.getRow(row).getCell(2).value = dataset.name;

    // Add data - there should be 12 items in the array
    dataset.data.forEach((item, colIndex) => {
      performanceWorksheet.getRow(row).getCell(3 + colIndex).value = item;
    });

    // Add score
    performanceWorksheet.getRow(row).getCell(trafficByMonthScoreColumn).value =
      trafficByMonthChartData.insights.scores[rowIndex].score;
  });

  // Performance - Marketing Efficiency (Traffic Sources)

  performanceWorksheet.getCell('B24').value = 'Marketing Efficiency'; // blue row

  const totalNumberOfTrafficSources = trafficSourceBreakdownChartData.series.length;
  const scoreColumnNumber = 2 + totalNumberOfTrafficSources + 2;

  // Add domains starting at row 27 from col 2
  const trafficSourceBreakdownDomainsRow = 27;
  const trafficSourceBreakdownDomainsCol = 2;

  performanceWorksheet.getRow(26).getCell(scoreColumnNumber).value = 'Score';

  trafficSourceBreakdownChartData.categories.forEach((item, index) => {
    performanceWorksheet
      .getRow(trafficSourceBreakdownDomainsRow + index)
      .getCell(trafficSourceBreakdownDomainsCol).value = item;
  });

  // Add data starting at row 27 from col 3
  trafficSourceBreakdownChartData.series.forEach((dataset, colIndex) => {
    // Add source name
    performanceWorksheet.getRow(26).getCell(3 + colIndex).value = dataset.name;

    // Add data
    dataset.data.forEach((item, rowIndex) => {
      performanceWorksheet.getRow(27 + rowIndex).getCell(3 + colIndex).value = item;
    });
  });

  // Add score
  trafficSourceBreakdownChartData.insights.scores.forEach((item, index) => {
    performanceWorksheet.getRow(27 + index).getCell(scoreColumnNumber).value =
      item.score.toFixed(0);
  });

  // Performance - Website Engagement

  performanceWorksheet.getCell('B35').value = 'Website Engagement'; // blue row

  performanceWorksheet.getCell('B38').value = 'Average visit duration (indexed)';
  performanceWorksheet.getCell('B39').value = 'Average # pages / visit (indexed)';
  performanceWorksheet.getCell('B40').value = 'Bounce rate (indexed)';

  performanceWorksheet.getCell('B42').value = 'Score';

  websiteEngagementChartData.insights.scores.forEach((item, index) => {
    performanceWorksheet.getRow(37).getCell(3 + index).value = item.name;

    // Average visit duration
    performanceWorksheet.getRow(38).getCell(3 + index).value = item.visitDurationScore;

    // Average # pages / visit
    performanceWorksheet.getRow(39).getCell(3 + index).value = item.pagesPerVisitScore;

    // Bounce rate
    performanceWorksheet.getRow(40).getCell(3 + index).value = item.bounceRateScore;

    // Score
    performanceWorksheet.getRow(42).getCell(3 + index).value = item.score.toFixed(0);
  });

  // Brand Worksheet

  const brandWorksheet = workbook.addWorksheet('Brand');

  brandWorksheet.getColumn('A').width = 2;
  brandWorksheet.getColumn('B').width = 20;
  brandWorksheet.getColumn('C').width = 20;

  formatBlueRow(brandWorksheet, 5);
  formatBlueRow(brandWorksheet, 16);

  const brandPageTitleCell = brandWorksheet.getCell('B2');
  brandPageTitleCell.value = 'Brand Page Data Export';
  formatTitle(brandPageTitleCell);

  // Brand - Brand Scale and Velocity

  brandWorksheet.getCell('B5').value = 'Brand Scale and Velocity'; // blue row

  // Add dates on row 7 from column 3
  brandSearchChartData.categories.forEach((item, index) => {
    brandWorksheet.getRow(7).getCell(3 + index).value = moment(item).format('MM/YYYY');
  });

  // Add "Score" header
  const brandScaleAndVelocityScoreCol = 3 + brandSearchChartData.categories.length + 1;
  brandWorksheet.getRow(7).getCell(brandScaleAndVelocityScoreCol).value = 'Score';

  brandSearchChartData.insights.scores.forEach((dataset, rowIndex) => {
    const row = 8 + rowIndex;

    // Add name
    brandWorksheet.getRow(row).getCell(2).value = dataset.name;

    // Add data - there should be 12 items in the array
    dataset.seriesDataItem.data.forEach((item, colIndex) => {
      brandWorksheet.getRow(row).getCell(3 + colIndex).value = +item.y;
    });

    // Add score
    // NB: the score is in the "insights" object, not the "series" object
    // NB: We assume all indexes are equal
    brandWorksheet.getRow(row).getCell(brandScaleAndVelocityScoreCol).value =
      dataset.score !== null ? dataset.score.toFixed(0) : 'null';
  });

  // Brand - Customer Experience (Trustpilot)

  brandWorksheet.getCell('B16').value = 'Customer Experience'; // blue row

  brandWorksheet.getCell('C18').value = 'Trustpilot Score';
  brandWorksheet.getCell('D18').value = 'No of Reviews';
  brandWorksheet.getCell('E18').value = 'Active Management Score';
  brandWorksheet.getCell('G18').value = 'Score';

  trustpilotChartData.insights.scores.forEach((item, rowIndex) => {
    const row = 19 + rowIndex;

    brandWorksheet.getRow(row).getCell(2).value = item.name;
    brandWorksheet.getRow(row).getCell(3).value = item.trustpilotScore;
    brandWorksheet.getRow(row).getCell(4).value = item.numberOfReviews;
    brandWorksheet.getRow(row).getCell(5).value = item.activeManagementScore;
    brandWorksheet.getRow(row).getCell(7).value = item.score;
  });

  // Social Worksheet

  const socialWorksheet = workbook.addWorksheet('Social');

  socialWorksheet.getColumn('A').width = 2;
  socialWorksheet.getColumn('B').width = 20;
  socialWorksheet.getColumn('C').width = 20;

  formatBlueRow(socialWorksheet, 5);
  formatBlueRow(socialWorksheet, 16);

  const socialPageTitleCell = socialWorksheet.getCell('B2');
  socialPageTitleCell.value = 'Social Page Data Export';
  formatTitle(socialPageTitleCell);

  // Social - Social Audience

  socialWorksheet.getCell('B5').value = 'Social Audience'; // blue row

  socialWorksheet.getCell('C7').value = 'Facebook';
  socialWorksheet.getCell('D7').value = 'Twitter';
  socialWorksheet.getCell('E7').value = 'Instagram';
  socialWorksheet.getCell('G7').value = 'Score';

  socialAudienceChartData.categories.forEach((item, rowIndex) => {
    const row = 8 + rowIndex;

    // Name
    socialWorksheet.getRow(row).getCell(2).value = item;

    // Data

    // Facebook
    socialWorksheet.getRow(row).getCell(3).value = socialAudienceChartData.series[0].data[rowIndex];

    // Twitter
    socialWorksheet.getRow(row).getCell(4).value = socialAudienceChartData.series[1].data[rowIndex];

    // Instagram
    socialWorksheet.getRow(row).getCell(5).value = socialAudienceChartData.series[2].data[rowIndex];

    // Score
    socialWorksheet.getRow(row).getCell(7).value =
      socialAudienceChartData.insights.scores[rowIndex].score !== null
        ? socialAudienceChartData.insights.scores[rowIndex].score.toFixed(0)
        : 'null';
  });

  // Social - Social Engagement

  socialWorksheet.getCell('B16').value = 'Social Engagement'; // blue row

  socialWorksheet.getCell('C18').value = 'Followers (all)';
  socialWorksheet.getCell('D18').value = 'Engagements (all)';
  socialWorksheet.getCell('F18').value = 'Score';

  followersVsEngagementsChartData.insights.scores.forEach((item, rowIndex) => {
    const row = 19 + rowIndex;

    socialWorksheet.getRow(row).getCell(2).value = item.name;
    socialWorksheet.getRow(row).getCell(3).value = item.totalFollowers;
    socialWorksheet.getRow(row).getCell(4).value = item.totalEngagements;
    socialWorksheet.getRow(row).getCell(6).value =
      item.score !== null ? item.score.toFixed(0) : 'null';
  });

  // XLSX Generation:

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  saveAs(blob, `Prism Full Data Export ${moment().format('YYYY-MM-DD_hhmmss')}.xlsx`);
}
