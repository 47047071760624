import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Login from '../pages/Login';
import Logout from './Logout';
import axios from 'axios';
import Loading from './Loading';
import { size } from 'lodash';

export const AuthTokenContext = React.createContext({
  accessToken: ''
});

export default function AuthenticationWrapper({ children }) {
  const [accessToken, setAccessToken] = useState();
  const [organisation, setOrganisation] = useState();
  const [organizationDisabled, setOrganizationDisabled] = useState(false);
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const {
    isLoading,
    user,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    logout,
    loginWithRedirect
  } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) return;

      try {
        const res = await getAccessTokenSilently();
        setAccessToken(res);

        console.log('ACCESS TOKEN', accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isAuthenticated]);

  // useEffect(async () => {
  //   const { data } = await axios.get(`${process.env.REACT_APP_URL}/api/organisation`, {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   });
  //
  //   setOrganisation(data);
  // }, [accessToken]);

  if (isLoading) {
    return (
      <div data-testid="auth-loading">
        <Loading />
      </div>
    );
  }

  if (error) {
    console.log('error', error);
    return (
      <div
        data-testid="auth-error"
        style={{
          color: 'rgba(0, 0, 0, 0.87)',
          width: '100%',
          opacity: 1,
          background: '#f5f6fa',
          borderRadius: '10px',
          textTransform: 'none',
          maxWidth: '270px',
          position: 'relative',
          margin: '0 auto',
          padding: '25px',
          textAlign: 'center',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '18px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        }}>
        <img
          src="/img/prism_full_purple.svg"
          className="logoImg"
          style={{ marginBottom: '20px' }}
          alt="logo"
        />
        {error.message}
      </div>
    );
  }

  if (!isAuthenticated && !accessToken) {
    return loginWithRedirect();
  }

  // api requests otherwise requests will fail
  if (!accessToken) {
    return null;
  }

  // if (!organisation) {
  //   return null;
  // }

  const auth = {
    accessToken,
    user,
    logout
    // organisation
  };

  return (
    <>
      <AuthTokenContext.Provider value={auth}>{children}</AuthTokenContext.Provider>
    </>
  );
}
