import React, { useState } from 'react';
import { useCMS } from '../../api';
import Error from '../../components/Error';
import IndustrySearchesWrapper from '../../components/MarketBackground/IndustrySearches/IndustrySearchesWrapper';
import IndustryTrafficWrapper from '../../components/MarketBackground/IndustryTraffic/IndustryTrafficWrapper';
import { makeStyles } from '@material-ui/core';
import Loading from '../Loading';

const useStyles = makeStyles(() => ({
  pageBreakAfter: {
    [`@media print`]: {
      'page-break-after': 'always'
    }
  }
}));

export default function MarketBackgroundContent({
  project,
  isLoadingProject,
  isErrorProject,
  searchTrendsChartData,
  trafficTrendsChartData,
  setSearchTrendsKeywords,
  organisation
}) {
  const classes = useStyles();
  const cms = useCMS();

  const [filters] = useState({});

  if (isLoadingProject) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  return (
    <>
      <div className={classes.pageBreakAfter}>
        <IndustrySearchesWrapper
          project={project}
          filters={filters}
          cms={cms}
          searchTrendsChartData={searchTrendsChartData}
          setSearchTrendsKeywords={setSearchTrendsKeywords}
          organisation={organisation}
        />
      </div>

      <div className={classes.pageBreakAfter}>
        <IndustryTrafficWrapper
          project={project}
          filters={filters}
          cms={cms}
          trafficTrendsChartData={trafficTrendsChartData}
          organisation={organisation}
        />
      </div>
    </>
  );
}
