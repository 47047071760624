// Traffic Trend chart

import React, { useEffect } from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import Chart from 'react-apexcharts';
import Skeleton from '@material-ui/lab/Skeleton';
import { format } from 'd3-format';
import '../CompetitorAnalysis/Overview.css';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
import { useAuth } from '../../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    position: 'relative'
  },
  graphSubtitle: {
    fontSize: '12px'
  }
}));

export default function IndustryTraffic({
  title,
  subtitle,
  dataFetched,
  filters,
  project,
  data,
  series,
  categories,
  cagr,
  organisation
}) {
  // MARKET TRAFFIC GROWTH
  const { orgType } = organisation;
  const classes = useStyles();

  if (dataFetched && data && series.length) {
    return (
      <Paper className={classes.graphContainer} elevation={0} id="traffic-growth-chart">
        <h4 className={classes.graphSubtitle}>{subtitle}</h4>
        {orgType === 'Lite' && <OverlayUpgradeButton top={55} right={25} />}
        <Chart
          className="alpha-graph-legends"
          options={{
            stroke: {
              curve: 'smooth',
              dashArray: [0, 5],
              colors: ['rgba(26, 31, 101, 1)', 'rgba(00, 00, 00, 0.6)']
            },
            dataLabels: {
              enabled: false
            },
            chart: {
              toolbar: {
                tools: {
                  download: true,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true
                }
              }
            },
            xaxis: {
              type: 'datetime',
              categories: categories,
              labels: {
                format: "MMM 'yy"
              }
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return format('.2s')(val);
                }
              }
            },
            colors: ['#1A1F65', '#292d60']
          }}
          series={series.map((s, i) => {
            if (i === 0) s.name = 'Target and competitors';
            return s;
          })}
          type="line"
        />
      </Paper>
    );
  }

  return (
    <Paper className={classes.graphContainer} elevation={0}>
      {/* <h3 className={classes.graphTitle}>{title}</h3> */}
      <Skeleton width="50%" />
      <Skeleton width="20%" />
      <Skeleton width="30%" />
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Paper>
  );
}
