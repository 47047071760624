import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1440,
  xl: 1920
};

const theme = createTheme({
  background: 'red',
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key) => `@media (min-width:${values[key]}px)`
  },
  palette: {
    primary: {
      main: '#3C21BA'
    },
    secondary: {
      main: '#3C21BA'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#F5F6FA'
    }
  },
  typography: {
    fontFamily: [
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

export default theme;
