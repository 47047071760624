import { useParams } from 'react-router-dom';
import { useProject } from '../../api';
import Error from '../../components/Error';
import CompanyInformationHeader from '../../components/Company/CompanyInformationHeader';
import TabMenu from '../../components/Projects/TabMenu';
import { Grid, makeStyles } from '@material-ui/core';
import CompanyContent from '../../components/Company/CompanyContent';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  outer: {
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingBottom: '50px'
  },
  rowContainer: {
    marginTop: '10px',
    paddingBottom: '100px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  }
}));

export default function Company() {
  const classes = useStyles();
  const { projectId } = useParams();
  const { project, isLoading, isError } = useProject(projectId);

  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  const company = project.companyInfo;

  if (!company) return 'No company info available';

  return (
    <Grid className={classes.outer} direction="column">
      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={0} project={project} />
        </Grid>
      </Grid>

      <Grid className={classes.container} item>
        <Grid item md={12}>
          <p className={classes.title}>Company</p>
        </Grid>
        {!isLoading ? (
          <CompanyInformationHeader company={company} project={project} match />
        ) : (
          <p>Loading</p>
        )}
      </Grid>

      <CompanyContent></CompanyContent>
    </Grid>
  );
}
