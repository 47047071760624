// Taken from stackoverflow: https://stackoverflow.com/a/40724354
const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
export const abbreviateNumber = (number, toFixed = 1) => {
  if (number === null || number === false || number === 'N/A') return;
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number.toFixed(toFixed > 0 ? toFixed - 1 : 0);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(toFixed) + suffix;
};

// Also taken from stackoverflow: https://stackoverflow.com/a/13627586/4255067
export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};
