import React, { useEffect, useState } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { abbreviateNumber } from '../../utils/numbers';
import { sortBy } from 'lodash';
import Loading from '../Loading';
import './Overview.css';
import { useAuth } from '../../hooks/use-auth';
import UpgradeButton from '../Auth/UpgradeButton';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
const useStyles = makeStyles(() => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function Overview({
  title,
  project,
  dataFetched,
  websiteTrafficVolumes,
  isWebsiteTrafficVolumesLoading,
  overviewChartData,
  organisation
}) {
  // PERFORMANCE - TRAFFIC SHARE CHART
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;

  const [chartSeries, setChartSeries] = useState([]);

  const data = websiteTrafficVolumes;

  const isLoading = isWebsiteTrafficVolumesLoading;

  const { series, competitors } = overviewChartData;

  const classes = useStyles();

  useEffect(() => {
    if (!series || series.length === 0) return;

    const transformedSeries = series.slice().map((s) => {
      const data = s.data.slice().map((y, idx) => {
        return {
          x: competitors[idx],
          y: y
        };
      });

      const sorted = data;

      const ret = {
        ...s,
        data: sorted
      };

      return ret;
    });

    setChartSeries(transformedSeries);
  }, [series]);

  if (isLoading) return <Loading />;
  if (!series || series.length === 0) return <Loading />;
  if (chartSeries.length === 0) return <Loading />;

  if (!(dataFetched && !isArrayEmpty(data) && data && competitors.length)) {
    return (
      <Paper className={classes.graphContainer} elevation={0}>
        <Skeleton width="50%" />
        <Skeleton width="20%" />
        <Skeleton width="30%" />
        <Skeleton width="40%" />
        <Skeleton width="25%" />
      </Paper>
    );
  }

  return (
    <Grid>
      {series && competitors && (
        <Paper className={classes.graphContainer} elevation={0} id="traffic-share-chart">
          {orgType === 'Lite' && <OverlayUpgradeButton />}
          <Chart
            options={{
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  dataLabels: {
                    enabled: true,
                    position: 'top', // top, center, bottom
                    offsetY: -200,
                    style: {
                      marginTop: '-200px',
                      color: '#000'
                    }
                  }
                }
              },
              dataLabels: {
                enabled: true,
                offsetY: -30,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 'bold',
                  colors: ['#000']
                },
                formatter: function (value, opts) {
                  let dp = 0;
                  if (value < 10000000) {
                    dp = 1;
                  }
                  return abbreviateNumber(value, dp);
                }
              },
              xaxis: {
                type: 'category',
                labels: {
                  style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                    colors: ['#000'],
                    cssClass: 'labelChart'
                  }
                }
              },
              yaxis: {
                min: 0,
                tickAmount: 5,
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    let dp = 0;
                    if (value < 10000000) {
                      dp = 1;
                    }
                    return abbreviateNumber(value, dp);
                  }
                }
              },
              chart: {
                toolbar: {
                  tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                  }
                }
              },
              colors: [
                function ({ value, seriesIndex, dataPointIndex, w }) {
                  switch (dataPointIndex) {
                    case 0:
                      return '#F67DFF';
                    case 1:
                      return '#5D2AFF';
                    case 2:
                      return '#3CC0FF';
                    case 3:
                      return '#F1C70B';
                    case 4:
                      return '#4F82FF';
                    case 5:
                      return '#1F004A';
                  }
                }
              ]
            }}
            series={chartSeries}
            type="bar"
            width="100%"
          />
        </Paper>
      )}
    </Grid>
  );
}
