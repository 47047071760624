import React from 'react';
import { useCMS } from '../../api';
import Error from '../../components/Error';
import CompanyInformationHeader from '../../components/Company/CompanyInformationHeader';
import PageSummary from '../../components/Company/PageSummary';
import TabMenu from '../../components/Projects/TabMenu';
import { Grid, makeStyles } from '@material-ui/core';
import CompetitorAnalysisContent from '../../components/CompetitorAnalysis/CompetitorAnalysisContent';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  outer: {
    minWidth: '100%',
    paddingLeft: '80px'
  },
  container: {
    width: '90%',
    paddingBottom: '100px',
    paddingTop: '20px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  graphContainer: {
    width: '620px',
    height: '408px',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px'
  },

  detailContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    height: '100%'
  }
}));

export default function CompetitorAnalysis(props) {
  const {
    projectId,
    project,
    isLoadingProject,
    isErrorProject,
    countries,
    filters,
    applyFilters,
    websiteTrafficVolumes,
    isWebsiteTrafficVolumesLoading,
    overviewChartData,
    trafficSourceBreakdownChartData,
    isTrafficSourceVolumesLoading,
    trafficSourceVolumesData,
    isMobileTrafficSourceVolumesLoading,
    mobileTrafficVolumesData,
    mobileTrafficSourceBreakdownChartData,
    combinedTrafficSourceBreakdownChartData,
    trafficByMonthChartData,
    websiteEngagementChartData,
    isWebsiteEngagementLoading,
    organisation,
    isLoadingOrganisation
  } = props;
  const classes = useStyles();

  const cms = useCMS();

  if (isLoadingProject) return <Loading />;
  if (!cms) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;
  const companyForHeader = company;

  return (
    <Grid className={classes.outer} direction="column">
      <Grid className={classes.tabMenu}>
        <Grid container className={classes.tabMenuContainer}>
          <TabMenu projectId={projectId} index={2} project={project} />
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        {/* <CompanyInformationHeader company={company} project={project} /> */}
        <PageSummary
          company={companyForHeader}
          project={project}
          tab="Performance"
          content={'performance-page'}
        />

        <CompetitorAnalysisContent
          projectId={projectId}
          project={project}
          isLoadingProject={isLoadingProject}
          isErrorProject={isErrorProject}
          countries={countries}
          filters={filters}
          applyFilters={applyFilters}
          websiteTrafficVolumes={websiteTrafficVolumes}
          isWebsiteTrafficVolumesLoading={isWebsiteTrafficVolumesLoading}
          overviewChartData={overviewChartData}
          trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
          isTrafficSourceVolumesLoading={isTrafficSourceVolumesLoading}
          trafficSourceVolumesData={trafficSourceVolumesData}
          isMobileTrafficSourceVolumesLoading={isMobileTrafficSourceVolumesLoading}
          mobileTrafficVolumesData={mobileTrafficVolumesData}
          mobileTrafficSourceBreakdownChartData={mobileTrafficSourceBreakdownChartData}
          combinedTrafficSourceBreakdownChartData={combinedTrafficSourceBreakdownChartData}
          trafficByMonthChartData={trafficByMonthChartData}
          websiteEngagementChartData={websiteEngagementChartData}
          isWebsiteEngagementLoading={isWebsiteEngagementLoading}
          organisation={organisation}
          isLoadingOrganisation={isLoadingOrganisation}
        />
      </Grid>
    </Grid>
  );
}
