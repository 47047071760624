import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Checkbox, DialogActions, DialogContent } from '@material-ui/core';

export default function TermsDialog({ open = false, setOpen, handleOk }) {
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = (e, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent dividers>
        <div>
          <Checkbox
            value="checkedA"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Checkbox A' }}
          />
          I agree to the{' '}
          <a
            href="https://prism.helpscoutdocs.com/article/14-terms"
            target="_blank"
            rel="noreferrer">
            terms of service
          </a>{' '}
          and{' '}
          <a
            href="https://prism.helpscoutdocs.com/article/15-privacy"
            target="_blank"
            rel="noreferrer">
            privacy policy
          </a>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOk} color="primary" disabled={!checked}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
