import { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@material-ui/core/Grid';
import LoggedInUser from '../../components/LoggedInUser';
import CreditsHeader from '../../components/Credits/CreditsHeader';
import axios from 'axios';
import TermsDialog from '../Auth/TermsDialog';
import { makeStyles } from '@material-ui/core';
import InfoModal from '../../components/InfoModal';
import OnBoardModal from '../../components/OnBoardModal';
import UniversalModal from '../../components/UniversalModal';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    marginTop: '-25px',
    padding: '20px',
    background: '#d9d3f3',
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));

export const getUserMetadata = async (isAuthenticated, getAccessTokenSilently, user) => {
  const domain = process.env.REACT_APP_AUTH0_APIEXPLORER;
  // const domain = 'palladium-thunder.eu.auth0.com';

  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    });

    if (!isAuthenticated) return;
    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const { user_metadata } = await metadataResponse.json();

    return user_metadata;
  } catch (e) {
    console.log(e.message);
  }
};

export const getUserCredits = async (isAuthenticated, getAccessTokenSilently, company) => {
  const domain = process.env.REACT_APP_AUTH0_APIEXPLORER;
  // const domain = 'palladium-thunder.eu.auth0.com';

  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`
    });

    if (!isAuthenticated) return;

    const credits = axios.get(`${process.env.REACT_APP_URL}/api/credits?name=${company}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return credits;
  } catch (e) {
    console.log(e.message);
  }
};

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();

  const [userFetched, setUserFetched] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [credits, setCredits] = useState(0);
  const [tier, setTier] = useState('');
  const [onBoard, setOnboard] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['onBoard']);

  const updateUserMetadata = async () => {
    // eslint-disable-next-line no-undef
    gtag('event', 'terms_accepted', {
      event_category: 'Onboard',
      event_label: 'terms_accepted'
    });

    const domain = process.env.REACT_APP_AUTH0_APIEXPLORER;
    // const domain = 'palladium-thunder.eu.auth0.com';

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: 'read:current_user update:current_user_metadata'
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: JSON.stringify({
          user_metadata: {
            terms_accepted: true
          }
        })
      });

      setTermsDialogOpen(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(async () => {
    if (!isLoading) {
      await getUserMetadata(isAuthenticated, getAccessTokenSilently, user).then((res) => {
        setUserMetadata(res);
        setUserFetched(true);
      });
    }
    return () => {
      setUserFetched(userFetched);
      setUserMetadata(userMetadata);
    };
  }, [user]);

  useEffect(async () => {
    if (!userMetadata) return;

    if (!!userMetadata.terms_accepted === false) {
      setTermsDialogOpen(true);
    }
  }, [userMetadata]);

  useEffect(async () => {
    if (!userMetadata) return;

    // eslint-disable-next-line no-undef
    gtag('config', process.env.REACT_APP_GTM_ID, {
      user_id: user.email
    });

    // eslint-disable-next-line no-undef
    gtag('set', 'user_properties', {
      prism_id: user.email,
      organisation: userMetadata.company
    });
  }, [userMetadata]);

  // Get the credits
  useEffect(async () => {
    if (credits) return;

    if (!isLoading) {
      await getUserCredits(isAuthenticated, getAccessTokenSilently, userMetadata?.company)
        .then((res) => {
          setCredits(+res.data.credits);
          setTier(res.data.tier);
        })
        .catch((e) => console.error(e));
    }
  }, [userMetadata]);

  useEffect(() => {
    const value = queryString.parse(location.search);
    const error = value.error;
    const error_description = value.error_description;

    if (error) {
      alert(error_description);
      logout({ returnTo: window.location.origin });
    }
  }, []);

  useEffect(() => {
    const value = queryString.parse(location.search);
    const onboard = value.onboard;
    setOnboard(onboard);
  }, [location.search]);

  useEffect(() => {
    if (userMetadata && userMetadata?.org_approval && !termsDialogOpen && !cookies['onBoard']) {
      setCookie('onBoard', true, { path: '/' });
    }
  }, [userMetadata]);

  const projectSetupScreenMatch = useRouteMatch('/search/:target');
  const isProjectSetupScreen = projectSetupScreenMatch && projectSetupScreenMatch.isExact;

  const projectScreen = useRouteMatch('/projects/:id');
  const isProjectScreen = projectScreen && projectScreen.params && projectScreen.params.id;

  const hideFeedbackBanner = isProjectSetupScreen || isProjectScreen;

  return (
    <>
      <TermsDialog
        open={termsDialogOpen}
        setOpen={setTermsDialogOpen}
        handleOk={updateUserMetadata}
      />

      {!userMetadata?.org_approval && userFetched && !termsDialogOpen && (
        <InfoModal organisation={' ' + (userMetadata?.company || '')} />
      )}

      {(cookies['onBoard'] == 'true' || onBoard === 'true') && (
        <OnBoardModal organisation={' ' + (userMetadata?.company || '')} />
      )}

      <UniversalModal />

      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          {isAuthenticated && userFetched && credits !== undefined && (
            <CreditsHeader credits={credits} />
          )}
        </Grid>
        <Grid item>aaa</Grid>
        <Grid item>vvv</Grid>
        <Grid item>
          {isAuthenticated && userFetched && (
            <LoggedInUser user={user} metadata={userMetadata} tier={tier} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
