import createTrend from 'trendline';
import { sum } from 'lodash';

export const abbreviateNumber = (number) => {};

export const groupByMonth = (xy) => {
  return xy.reduce((acc, item) => {
    const month = item.x.format('MMMM');
    if (!acc[month]) {
      acc[month] = item.y;
    } else {
      acc[month] += item.y;
    }
    return acc;
  }, {});
};

export const getTrendSeries = (dataForTrendCalculation) => {
  const trend = createTrend(dataForTrendCalculation, 'x', 'y');

  const trendSeries = {
    name: 'Trendline',
    data: dataForTrendCalculation.map((item, index) => {
      return trend.calcY(index + 1);
    })
  };

  return trendSeries;
};

export const getTrendLineSlope = (values) => {
  return values.slice(-1)[0] - values.slice(0, 1)[0] > 0 ? 'up' : 'down';
};

export const getCagrValue = (yValues, noOfMonths = 12, noOfYears = 4) => {
  const NUMBER_OF_YEARS = noOfYears - 1;
  const lastSum = sum(yValues.slice(-noOfMonths)); // y4
  const firstSum = sum(yValues.slice(0, noOfMonths)); // y1

  const left = lastSum > 0 && firstSum > 0 ? lastSum / firstSum : 0;
  return (left ** (1 / NUMBER_OF_YEARS) - 1) * 100;
};

// Returns the month name with the highest average total
export const getMonthHighAvg = (totalsByMonth) => {
  return Object.keys(totalsByMonth).reduce((a, b) => (totalsByMonth[a] > totalsByMonth[b] ? a : b));
};

// Returns the month name with the lowest average total
export const getMonthLowAvg = (totalsByMonth) => {
  return Object.keys(totalsByMonth).reduce((a, b) => (totalsByMonth[a] < totalsByMonth[b] ? a : b));
};

// Returns score based on CAGR value
// NOTE - the cagrValue is the percentage, so 27.45 rather than 0.2745
// so we need to divide by 100
export const getCagrScore = (cagrValue) => {
  const k = 13.2658843482053;
  const cagrForCalculation = +cagrValue / 100;
  const score = 100 / (1 + Math.exp(-k * (+cagrForCalculation - 0.05)));
  return score;
};
