import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { abbreviateNumber } from '../utils/numbers';
import Tooltip from '@material-ui/core/Tooltip';
import { WarningTriangle } from './WarningTriangle';

export function Traffic({ traffic }) {
  const useStyles = makeStyles((theme) => ({
    warning: {
      color: 'orange'
    },
    warningIconContainer: {
      marginLeft: '5px'
    }
  }));

  const classes = useStyles();
  const noTrafficData = traffic === 'N/A';
  const lowTraffic = noTrafficData || traffic < 60000;

  return (
    <Box
      style={{
        display: 'flex'
      }}>
      <span className={classNames(lowTraffic && classes.warning)}>
        {noTrafficData ? 'n/a' : abbreviateNumber(traffic, 1)}
      </span>
      {lowTraffic && (
        <Tooltip
          placement="left-end"
          title={
            <>
              <div>
                Low annual traffic in selected region,
                <br />
                performance data may be less accurate.
              </div>
            </>
          }>
          <WarningTriangle />
        </Tooltip>
      )}
    </Box>
  );
}
