import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: '100%',
    height: '90vh'
  },
  inner: {
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  searchBoxLogo: {
    height: '30px'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '2%'
  },
  heading: {
    color: '#394056',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '20px'
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none'
  },
  margin: {
    margin: theme.spacing(2)
  },
  inputs: {
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    animationDuration: '550ms'
  },
  circle: {
    strokeLinecap: 'round'
  },
  error: {
    borderRadius: '10px',
    marginBottom: '10px'
  }
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <CircularProgress />
    </Grid>
  );
}
