// Traffic by month chart

import React, { useEffect } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import moment from 'moment';
import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { format } from 'd3-format';
import Loading from '../Loading';
import './Overview.css';
import UpgradeButton from '../Auth/UpgradeButton';
import { useAuth } from '../../hooks/use-auth';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
const useStyles = makeStyles((theme) => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function websiteTrafficVolumes({
  title,
  project,
  countries,
  dataFetched,
  websiteTrafficVolumes,
  isWebsiteTrafficVolumesLoading,
  trafficByMonthChartData,
  organisation
}) {
  // PERFORMANCE - TRAFFIC TREND CHART
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;

  const data = websiteTrafficVolumes;
  const isLoading = isWebsiteTrafficVolumesLoading;

  const classes = useStyles();

  const { series, categories } = trafficByMonthChartData;

  if (isLoading) return <Loading />;

  if (dataFetched && !isArrayEmpty(data)) {
    return (
      <Grid>
        {series && categories && (
          <Paper className={classes.graphContainer} elevation={0} id="traffic-trend-chart">
            {orgType === 'Lite' && <OverlayUpgradeButton />}
            <Chart
              className="traffic-trend-chart"
              options={{
                stroke: {
                  curve: 'smooth'
                },
                xaxis: {
                  categories,
                  labels: {
                    formatter: function (val) {
                      return moment(val).format('MMM - YY');
                    }
                  }
                },
                chart: {
                  toolbar: {
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: false
                    }
                  }
                },
                yaxis: {
                  labels: {
                    formatter: (val) => {
                      return format('.2s')(val);
                    }
                  }
                },
                colors: ['#F67DFF', '#5D2AFF', '#3CC0FF', '#F1C70B', '#4F82FF', '#1F004A']
              }}
              series={series}
              type="line"
              width="100%"
            />
          </Paper>
        )}
      </Grid>
    );
  }

  return (
    <Paper className={classes.graphContainer} elevation={0} id="traffic-trend-chart">
      {/* <h3 className={classes.graphTitle}>{title}</h3> */}
      <Skeleton width="50%" />
      <Skeleton width="20%" />
      <Skeleton width="30%" />
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Paper>
  );
}
