import {
  Box,
  Button,
  Chip,
  Container as MuiContainer,
  Divider,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';

import { useEffect, useState } from 'react';

import Container from '../components/Container';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  currentPlanButton: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    background: '#3C21BA',
    opacity: 0.2,
    color: 'white',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&': {
      pointerEvents: 'none'
    },
    '&:hover': {
      pointerEvents: 'none'
    }
  },
  upgradePlanButton: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    background: '#3C21BA',
    opacity: 1,
    color: 'white',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  divider: {
    margin: '15px 0 !important'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '2%',
    marginLeft: '40px'
  },
  tierPaper: {
    boxShadow: '0px 4px 20px rgba(60, 33, 186, 0.05)',
    padding: '10px',
    width: '30%',
    borderRadius: '10px'
  },
  tierGridContainer: {
    minWidth: '100%',
    padding: '10px'
  },
  popularChip: {
    backgroundColor: 'lightgreen',
    color: 'white',
    borderRadius: '5px',
    padding: '5px 10px'
  },
  needMoreContainer: {
    textAlign: 'center',
    color: 'grey',
    textDecoration: 'underline',
    marginTop: '15px'
  },
  tableTitle: {
    color: 'purple',
    fontSize: '20px'
  },
  sectionWrapper: {
    padding: '50px'
  },
  features: {
    color: '#3C21BA',
    fontSize: '20px',
    lineHeight: '24px'
  },
  contentHeading: {
    paddingRight: '30px'
  },
  rowFeatures: {},
  removeMargin: {
    margin: 0,
    borderBottom: '1px solid #E5E5E5',
    '&:first-of-type': {
      borderTop: '1px solid #E5E5E5'
    },
    '&:last-of-type': {
      borderBottom: '0px'
    }
  },
  topBorder: {
    width: 'calc(100% - 24px)'
  }
}));

export const getUserMetadata = async (isAuthenticated, getAccessTokenSilently, user) => {
  const domain = process.env.REACT_APP_AUTH0_APIEXPLORER;
  // const domain = 'palladium-thunder.eu.auth0.com';

  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    });

    if (!isAuthenticated) return;
    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const { user_metadata } = await metadataResponse.json();

    return user_metadata;
  } catch (e) {
    console.log(e.message);
  }
};

export const getUserCredits = async (isAuthenticated, getAccessTokenSilently, company) => {
  const domain = process.env.REACT_APP_AUTH0_APIEXPLORER;
  // const domain = 'palladium-thunder.eu.auth0.com';

  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`
    });

    if (!isAuthenticated) return;

    const credits = axios.get(`${process.env.REACT_APP_URL}/api/credits?name=${company}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return credits;
  } catch (e) {
    console.log(e.message);
  }
};

export default function Upgrade() {
  const classes = useStyles();
  const history = useHistory();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [credits, setCredits] = useState(0);
  const [userFetched, setUserFetched] = useState(false);
  const [tier, setTier] = useState('');

  const rows = [
    {
      features:
        'Insight from all topic areas, including: \n Market, Performance, Brand, Social and Tech',
      lite: true,
      premium: true,
      enterprise: true
    },
    {
      features: 'Access to future topic areas',
      lite: true,
      premium: true,
      enterprise: true
    },
    {
      features: 'Support from a dedicated Customer Success Manager',
      lite: false,
      premium: true,
      enterprise: true
    },
    {
      features: 'Full data export and download in Microsoft Excel format',
      lite: false,
      premium: true,
      enterprise: true
    },
    {
      features: 'Enhanced project setup with extra metrics to help select the best competitors',
      lite: false,
      premium: true,
      enterprise: true
    },
    {
      features: 'Customize insights and reports',
      lite: false,
      premium: true,
      enterprise: true
    },
    {
      features: 'API Access',
      lite: false,
      premium: false,
      enterprise: 'Variable'
    }
  ];

  const upgrade = 'Upgrade';
  const downgrade = 'Downgrade';

  const modalTitleDowngrade = 'Request Downgrade';
  const modalContentDowngrade =
    'Click the button below, a member of the Prism team will be in touch via email about your options for downgrade.';
  const modalButtonDowngrade = 'Request';

  const modalTitleUpgrade = 'Request Upgrade';
  const modalContentUpgrade =
    'Click the button below, a member of the Prism team will be in touch via email about your options for upgrade.';
  const modalButtonUpgrade = 'Request';

  useEffect(async () => {
    if (!isLoading) {
      await getUserMetadata(isAuthenticated, getAccessTokenSilently, user).then((res) => {
        setUserMetadata(res);
        setUserFetched(true);
      });
    }
    return () => {
      setUserFetched(userFetched);
      setUserMetadata(userMetadata);
    };
  }, [user]);

  // Get the credits
  useEffect(async () => {
    if (credits) return;

    if (!isLoading) {
      await getUserCredits(isAuthenticated, getAccessTokenSilently, userMetadata?.company)
        .then((res) => {
          setCredits(+res.data.credits);
          setTier(res.data.tier);
        })
        .catch((e) => console.error(e));
    }
  }, [userMetadata]);

  return (
    <div>
      <Container className={classes.sectionWrapper}>
        <Grid item xs={12} sm={3} md={4} lg={4} xl={4} className={classes.contentHeading}>
          <Box fontSize={20} fontWeight="bold" my={4} color="#3C21BA" lineHeight="24px">
            Pricing
          </Box>
          <Box my={2} fontSize={16} color="#1F004A" lineHeight="24px">
            New organisations begin with a free trial of Premium (month of registering + 2 calendar
            months).
          </Box>
          <Box fontSize={16} color="#7A8199" lineHeight="24px">
            All projects, credits and pricing are shared by your organisation. User sign up is free.
          </Box>
        </Grid>
        <Grid item container xs={12} md={8} justifyContent="space-between">
          {/* Lite  */}
          <Paper className={classes.tierPaper} elevation={2}>
            <Grid
              className={classes.tierGridContainer}
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ width: '100%' }}>
              <Box fontSize={20} my={4} style={{ color: '#3c21ba' }}>
                Lite
              </Box>

              <Divider component="div" my={4} className={classes.dividers} />

              <Box my={2}>
                <span style={{ fontSize: '28px', color: '#3c21ba' }}>£0</span>
                <span style={{ color: '#7A8199' }}>/ Per annum</span>
              </Box>

              <Divider />

              <Box my={2}>
                <div style={{ color: '#3c21ba' }}>4</div>
                <div style={{ color: '#7A8199' }}>Reports Per Month</div>
              </Box>

              <Box my={2}>
                {tier == 'Lite' ? (
                  <Button
                    className={classes.currentPlanButton}
                    variant="contained"
                    color="white"
                    disableElevation>
                    Current Plan
                  </Button>
                ) : (
                  <Button
                    className={classes.upgradePlanButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(e) =>
                      history.push({
                        pathname: window.location.pathname,
                        search:
                          tier == 'Premium' || tier == 'Enterprise' || tier == 'Free Trial'
                            ? `?modal=show&title=${modalTitleDowngrade}&content=${modalContentDowngrade}&button=${modalButtonDowngrade}&variant=${downgrade}&tier=Lite`
                            : `?modal=show&title=${modalTitleUpgrade}&content=${modalContentUpgrade}&button=${modalButtonUpgrade}&variant=${upgrade}&tier=Lite`
                      })
                    }>
                    Request{' '}
                    {tier == 'Premium' || tier == 'Enterprise' || tier == 'Free Trial'
                      ? 'Downgrade'
                      : 'Upgrade'}
                  </Button>
                )}
              </Box>
            </Grid>
          </Paper>

          {/* Premium */}
          <Paper className={classes.tierPaper} elevation={2}>
            <Grid className={classes.tierGridContainer} item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box fontSize={20} my={4}>
                <Grid container justifyContent="space-between" style={{ width: '100%' }}>
                  <span style={{ color: '#3c21ba' }}>Premium</span>

                  <Chip
                    className={classes.popularChip}
                    label="Popular"
                    size="small"
                    style={{ backgroundColor: '#03cea4', color: 'white' }}
                  />
                </Grid>
              </Box>

              <Divider />

              <Box my={2}>
                <span style={{ fontSize: '28px', color: '#3c21ba' }}>£12k</span>
                <span style={{ color: '#7A8199' }}>/ Per annum</span>
              </Box>

              <Divider />

              <Box my={2}>
                <div style={{ color: '#3c21ba' }}>10</div>
                <div style={{ color: '#7A8199' }}>Reports Per Month</div>
              </Box>

              <Box my={2}>
                {tier == 'Premium' ? (
                  <Button
                    className={classes.currentPlanButton}
                    variant="contained"
                    color="white"
                    disableElevation>
                    Current Plan
                  </Button>
                ) : (
                  <Button
                    className={classes.upgradePlanButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(e) =>
                      history.push({
                        pathname: window.location.pathname,
                        search:
                          tier == 'Enterprise'
                            ? `?modal=show&title=${modalTitleDowngrade}&content=${modalContentDowngrade}&button=${modalButtonDowngrade}&variant=${downgrade}&tier=Premium`
                            : `?modal=show&title=${modalTitleUpgrade}&content=${modalContentUpgrade}&button=${modalButtonUpgrade}&variant=${upgrade}&tier=Premium`
                      })
                    }>
                    Request {tier == 'Enterprise' ? 'Downgrade' : 'Upgrade'}
                  </Button>
                )}
              </Box>
            </Grid>
          </Paper>

          {/* Enterprise */}
          <Paper className={classes.tierPaper} elevation={2}>
            <Grid className={classes.tierGridContainer} item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box fontSize={20} my={4} style={{ color: '#3c21ba' }}>
                Enterprise
              </Box>

              <Divider />

              <Box my={2}>
                <span style={{ fontSize: '28px', color: '#3c21ba' }}>Variable</span>
              </Box>

              <Divider />

              <Box my={2}>
                <div style={{ color: '#3c21ba' }}>Variable</div>
                <div style={{ color: '#7A8199' }}>Reports Per Month</div>
              </Box>

              <Box my={2}>
                {tier == 'Enterprise' ? (
                  <Button
                    className={classes.currentPlanButton}
                    variant="contained"
                    color="white"
                    disableElevation>
                    Current Plan
                  </Button>
                ) : (
                  <Button
                    className={classes.upgradePlanButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(e) =>
                      history.push({
                        pathname: window.location.pathname,
                        search: `?modal=show&title=${modalTitleUpgrade}&content=${modalContentUpgrade}&button=${modalButtonUpgrade}&variant=${upgrade}&tier=Enterprise`
                      })
                    }>
                    Request Upgrade
                  </Button>
                )}
              </Box>
            </Grid>
          </Paper>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4}>
                <MuiContainer className={classes.needMoreContainer}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) =>
                      history.push({
                        pathname: window.location.pathname,
                        search: `?modal=show&title=${modalTitleUpgrade}&content=${modalContentUpgrade}&button=${modalButtonUpgrade}&variant=${upgrade}`
                      })
                    }>
                    Need more?
                  </span>
                </MuiContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Box fontSize={24} fontWeight="bold" my={4} className={classes.features}>
              Features
            </Box>
          </Grid>
        </Grid>
        <div className={classes.topBorder}>
          {rows.map((row) => (
            <>
              <Grid container spacing={3} className={classes.removeMargin}>
                <Grid item xs={12} sm={3} md={4} lg={4} xl={4} className={classes.rowFeatures}>
                  <Box my={4} color="#7A8199" fontSize="16px" fontWeight="400" lineHeight="19px">
                    {row.features}
                  </Box>
                </Grid>
                <Grid container xs={12} sm={8} md={8} lg={8} xl={8} className={classes.rowFeatures}>
                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <Box fontSize={20} fontWeight="bold" my={4} textAlign="center">
                      <img
                        src={row.lite ? '/plus.png' : '/cross.png'}
                        alt={row.lite ? 'tick' : 'cross'}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <Box fontSize={20} fontWeight="bold" my={4} textAlign="center">
                      <img
                        src={row.premium ? '/plus.png' : '/cross.png'}
                        alt={row.premium ? 'tick' : 'cross'}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <Box fontSize={20} fontWeight="bold" my={4} textAlign="center">
                      {row.enterprise === true ? (
                        <img
                          src={row.enterprise ? '/plus.png' : '/cross.png'}
                          alt={row.enterprise ? 'tick' : 'cross'}
                        />
                      ) : (
                        row.enterprise
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </div>
      </Container>
    </div>
  );
}
