import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Grid,
  Dialog,
  Typography,
  FormControlLabel,
  Button,
  DialogActions,
  FormGroup,
  TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCMS } from '../api/cms';
import { red } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DialogContentText from '@material-ui/core/DialogContentText';
import { AuthTokenContext } from './AuthenticationWrapper';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  // heading: {
  //   fontFamily: 'Inter',
  //   fontStyle: 'normal',
  //   fontWeight: 400,
  //   fontSize: 30,
  //   textAlign: 'center',
  //   color: '#3C21BA'
  // },
  root: {
    margin: 0
    // padding: theme.spacing(2)
  },
  dialogTitle: {},
  // paper: {
  //   // minWidth: '376px',
  //   maxWidth: '376px',
  //   padding: '40px 58px 51px 58px'
  // },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
  // contentText: {
  //   fontFamily: 'Inter',
  //   fontStyle: 'normal',
  //   fontWeight: '400',
  //   fontSize: '14px',
  //   lineHeight: '22px',
  //   textAlign: 'center',
  //   color: '#7A8199'
  //   // maxWidth: '260px'
  // }
}));

export default function SocialAccountsModal({ open, setOpen, socialAudienceChartData, projectId }) {
  const auth = useContext(AuthTokenContext);
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['prism_social']);
  const [checked, setChecked] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  if (!socialAudienceChartData) return null;
  if (!socialAudienceChartData.data) return null;
  if (socialAudienceChartData.data.length === 0) return null;

  const { data } = socialAudienceChartData;

  const [newSocialAccounts, setNewSocialAccounts] = useState(
    data.map((d) => ({
      domain: d.domain,
      tags: d.tags,
      changed: false
    }))
  );

  const anySocialAccountsChanged = newSocialAccounts.some((d) => d.changed);

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeField = (e, d, type) => {
    const value = e.target.value;
    setNewSocialAccounts((prev) =>
      prev.map((d2) => {
        if (d2.domain === d.domain) {
          return {
            ...d2,
            tags: {
              ...d.tags,
              [type]: value
            },
            changed: true
          };
        }
        return d2;
      })
    );
  };

  const handleContinue = async () => {
    if (anySocialAccountsChanged) {
      setError(false);
      setSuccess(false);
      setSubmitting(true);

      try {
        await axios.post(
          `${process.env.REACT_APP_URL}/api/projects/${projectId}/update-socialblade`,
          newSocialAccounts,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`
            }
          }
        );

        setSubmitting(false);
        setSuccess(true);
        setTimeout(() => window.location.reload(), 1000);
      } catch (error) {
        setSubmitting(false);
        setError(true);
        setTimeout(() => setError(false), 2000);
      }
    }
  };

  console.log('Tags', newSocialAccounts);

  return (
    <Grid className={classes.container1}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(255,251,251,0.7)' } }}
        PaperProps={{
          style: { borderRadius: 10, maxWidth: '500px' }
        }}
        open={open}
        onClose={handleClose}
        p={8}>
        <MuiDialogTitle className={classes.dialogTitle}>
          Social accounts
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <MuiDialogContent className={classes.contentContainer}>
          <DialogContentText className={classes.contentWrapper}>
            Add or edit accounts for each company to update the social insights.
          </DialogContentText>

          {submitting && (
            <Alert severity="info" style={{ marginTop: 10 }}>
              Updating social data...
            </Alert>
          )}

          {success && (
            <Alert severity="success" style={{ marginTop: 10 }}>
              Successfully updated social data. Reloading page now...
            </Alert>
          )}

          {error && (
            <Alert severity="error" style={{ marginTop: 10 }}>
              There was a problem.
            </Alert>
          )}

          <div>
            {newSocialAccounts.map((d) => {
              return (
                <Accordion key={d.domain}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{d.domain}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container direction="column">
                      <Grid>
                        {/*<Typography>Facebook | </Typography>*/}
                        <TextField
                          id="standard-basic"
                          label="Facebook"
                          defaultValue={d.tags.facebook}
                          onChange={(e) => onChangeField(e, d, 'facebook')}
                        />
                      </Grid>

                      <Grid>
                        {/*<Typography>Twitter | {d.tags.twitter}</Typography>*/}
                        <TextField
                          id="standard-basic"
                          label="Twitter"
                          defaultValue={d.tags.twitter}
                          onChange={(e) => onChangeField(e, d, 'twitter')}
                        />
                      </Grid>

                      <Grid>
                        {/*<Typography>Instagram | {d.tags.instagram}</Typography>*/}
                        <TextField
                          id="standard-basic"
                          label="Instagram"
                          defaultValue={d.tags.instagram}
                          onChange={(e) => onChangeField(e, d, 'instagram')}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </MuiDialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleContinue} color="primary" disabled={!anySocialAccountsChanged}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
