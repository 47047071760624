import { Grid, Paper, makeStyles, Chip, Button } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthTokenContext } from '../AuthenticationWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#DFBE7C',
    // margin: '30px auto'
    height: '100%'
  },
  creditCircleBorder: {
    backgroundColor: 'rgba(60, 33, 186, 0.1)',
    // border: '2px solid #DFBE7C',
    borderRadius: '20%',
    // marginRight: '5px',
    padding: '5px',
    width: '18px',
    minWidth: '18px',
    textAlign: 'center'
  },
  credits: {
    display: 'inline-block',
    width: '18px',
    minWidth: '18px',
    color: '#3C21BA',
    fontWeight: 'bold'
  },
  reportsRemainingContainer: {
    marginLeft: '15px'
  },
  reportsRemaining: {
    color: '#3C21BA'
  },
  thisMonth: {
    color: '#7A8199'
  }
}));

export default function CreditsHeader({ credits }) {
  const classes = useStyles();

  if (credits !== undefined) {
    return (
      <Grid
        container
        className={classes.root}
        alignContent="center"
        justifyContent="flex-end"
        alignItems="center">
        <Grid item>
          <span className={classes.creditCircleBorder}>
            <div className={classes.credits}>{credits}</div>
          </span>
        </Grid>

        <Grid
          className={classes.reportsRemainingContainer}
          item
          pt={8}
          style={{ marginTop: '5px', color: 'grey' }}>
          <div className={classes.reportsRemaining}>Reports remaining</div>
          <div className={classes.thisMonth}>This month</div>
        </Grid>
      </Grid>
    );
  }

  return <>0 Credits</>;
}
