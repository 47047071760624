import { makeStyles, Paper } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import ChartScore from '../ChartScore';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%'
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px',
    padding: '0 20px',
    borderRadius: '20px',
    width: '100%'
  }
}));

export default function ChartDetail(props) {
  const score = props.score;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper elevation={0} className={classes.paper}>
        <div>
          <ReactMarkdown>{props.children}</ReactMarkdown>
        </div>
      </Paper>

      <ChartScore score={score} />
    </div>
  );
}
