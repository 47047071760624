import React, { useEffect, useState } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Chart from 'react-apexcharts';

import isArrayEmpty from '../../utils/isArrayEmpty';
import { abbreviateNumber } from '../../utils/numbers';
import { sortBy } from 'lodash';
import Loading from '../Loading';
import { useAuth } from '../../hooks/use-auth';
import UpgradeButton from '../Auth/UpgradeButton';
import OverlayUpgradeButton from '../Auth/OverlayUpgradeButton';
const useStyles = makeStyles(() => ({
  graphContainer: {
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    position: 'relative'
  },
  graphTitle: {
    color: '#3C21BA'
  }
}));

export default function SiteOptimisation({
  title,
  project,
  dataFetched,
  isPagespeedInsightsLoading,
  pagespeedInsightsData,
  siteOptimisationChartData,
  organisation
}) {
  const { orgType } = organisation;
  if (!project) return <p>No project defined</p>;

  const [chartSeries, setChartSeries] = useState([]);

  const data = pagespeedInsightsData;

  const isLoading = isPagespeedInsightsLoading;

  const classes = useStyles();

  useEffect(() => {
    if (!data || data.length === 0) return;

    const transformedSeries = [
      {
        data: data
          .filter((d) => d.overall && d.performance && d.accessibility && d.seo)
          .map((d) => {
            return {
              x: d.domain,
              y: d.overall
            };
          })
      }
    ];

    setChartSeries(transformedSeries);
  }, [data]);

  if (isLoading) return <Loading />;
  if (chartSeries.length === 0) return <Loading />;

  if (!(dataFetched && !isArrayEmpty(data) && data)) {
    return (
      <Paper className={classes.graphContainer} elevation={0}>
        <Skeleton width="50%" />
        <Skeleton width="20%" />
        <Skeleton width="30%" />
        <Skeleton width="40%" />
        <Skeleton width="25%" />
      </Paper>
    );
  }

  return (
    <Grid>
      {chartSeries && (
        <Paper className={classes.graphContainer} elevation={0} id="technology-maturity-chart">
          {orgType === 'Lite' && <OverlayUpgradeButton />}
          <Chart
            options={{
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  dataLabels: {
                    enabled: true,
                    position: 'top', // top, center, bottom
                    offsetY: -200,
                    style: {
                      marginTop: '-200px',
                      color: '#000'
                    }
                  }
                }
              },
              dataLabels: {
                enabled: true,
                offsetY: -30,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 'bold',
                  colors: ['#000']
                },
                formatter: function (value, opts) {
                  let dp = 0;
                  if (value < 10000000) {
                    dp = 1;
                  }
                  return abbreviateNumber(value, dp);
                }
              },
              xaxis: {
                type: 'category',
                labels: {
                  style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                    colors: ['#000'],
                    cssClass: 'labelChart'
                  }
                }
              },
              yaxis: {
                min: 0,
                tickAmount: 5,
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    let dp = 0;
                    if (value < 10000000) {
                      dp = 1;
                    }
                    return abbreviateNumber(value, dp);
                  }
                }
              },
              chart: {
                toolbar: {
                  tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                  }
                }
              },
              colors: [
                function ({ value, seriesIndex, dataPointIndex, w }) {
                  switch (dataPointIndex) {
                    case 0:
                      return '#F67DFF';
                    case 1:
                      return '#5D2AFF';
                    case 2:
                      return '#3CC0FF';
                    case 3:
                      return '#F1C70B';
                    case 4:
                      return '#4F82FF';
                    case 5:
                      return '#1F004A';
                  }
                }
              ]
            }}
            series={chartSeries}
            type="bar"
            width="100%"
          />
        </Paper>
      )}
    </Grid>
  );
}
