import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Dialog, Button } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import queryString from 'query-string';
import { useQueryState } from '../utils/setQueryState';
import { useHistory } from 'react-router-dom';
// const Mail = require("@sendgrid/mail");
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { AuthTokenContext } from './AuthenticationWrapper';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 30,
    textAlign: 'center',
    color: '#3C21BA'
  },
  root: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    padding: 0
  },
  container: {
    float: 'right',
    position: 'relative',
    top: '-45px'
  },
  contentContainer: {
    padding: '0 !important',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    '& h1': {
      marginTop: 0
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%'
  },
  col: {
    width: '50%',
    minWidth: '385px'
  },
  childItem: {
    width: '50%',
    padding: 10
  },
  info: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    minWidth: '376px',
    maxWidth: '376px',
    padding: '40px 58px 51px 58px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  blank: {
    height: '100%',
    boxSizing: 'border-box'
  },
  upgradePlanButton: {
    borderRadius: '10px',
    textTransform: 'none',
    background: '#3C21BA',
    opacity: 1,
    color: 'white',
    padding: '12px 34px',
    marginTop: '30px'
  },
  modalContent: {
    textAlign: 'center',
    lineHeight: '1.8'
  }
}));
const Mail = null;

export default function UniversalModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useQueryState('modal');
  const [title, setTitle] = useQueryState('title');
  const [button, setButton] = useQueryState('button');
  const [content, setContent] = useQueryState('content');
  const [variant, setVariant] = useQueryState('variant');
  const [tier, setTier] = useQueryState('tier');
  const history = useHistory();
  const { user, isAuthenticated, loading, getAccessTokenSilently } = useAuth0();
  const auth = useContext(AuthTokenContext);

  const sendEmail = async (type, user) => {
    const titleString = type == 'upgrade' ? 'User requested upgrade' : 'User requested downgrade';

    var body = {
      user: user,
      subject: titleString,
      type: type + ' request from tier ' + tier,
      date: new Date().toLocaleString()
    };

    await axios.post(
      `${process.env.REACT_APP_URL}/api/upgradeEmail`,
      {
        payload: body
      },
      {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      }
    );
  };

  const handleSubmit = () => {
    if (variant == 'Upgrade') {
      sendEmail('upgrade', user.name);
      history.push({
        pathname: window.location.pathname,
        search: `?modal=show&title=Upgrade request sent!&content=We will come back to you shortly.&button=Close`
      });
    }
    if (variant == 'Downgrade') {
      sendEmail('downgrade', user.name);
      history.push({
        pathname: window.location.pathname,
        search: `?modal=show&title=Upgrade request sent!&content=We will come back to you shortly.&button=Close`
      });
    }
  };

  const handleClose = (redirect, url) => {
    if (redirect && url == 'backdropClick') {
      setOpen(!open);
      setModal('');
      return;
    }
    setOpen(!open);
    setModal('');

    if (redirect) {
      history.push(url);
    }
  };

  const replaceJSX = (str, find, replace) => {
    return str?.split(find)?.flatMap((item) => [item, replace]);
  };

  useEffect(() => {
    if (modal) {
      setOpen(true);
    }
  }, [modal]);

  return (
    <Grid className={classes.container}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(31, 0, 74, 0.7)' } }}
        PaperProps={{
          style: {
            borderRadius: 10,
            padding: 50,
            minHeight: '40px',
            maxWidth: '600px'
          }
        }}
        open={open}
        onClose={button !== 'Return to Search' ? handleClose : null}
        p={8}>
        <MuiDialogTitle disableTypography className={classes.root}>
          {button !== 'Return to Search' && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </MuiDialogTitle>
        <MuiDialogContent className={classes.contentContainer}>
          <h1>{title}</h1>
          <div className={classes.modalContent}>{replaceJSX(content, '<br>', <br />)}</div>
          {button !== '' && button && (
            <Button
              className={classes.upgradePlanButton}
              variant="contained"
              color="primary"
              disableElevation
              onClick={(e) => {
                button == 'Close' ? handleClose() : handleSubmit(e, true);
                button == 'Return to Search' ? handleClose(true, '/') : null;
              }}>
              {button}
            </Button>
          )}
        </MuiDialogContent>
      </Dialog>
    </Grid>
  );
}
