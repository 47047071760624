import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  makeStyles,
  withStyles,
  InputBase,
  InputLabel,
  TextField,
  FormControl,
  fade,
  CircularProgress,
  Box,
  Button,
  Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import isURL from 'validator/lib/isURL';
import { AuthTokenContext } from '../components/AuthenticationWrapper';
import parseUri from '../utils/url';

const searchAPI = `${process.env.REACT_APP_URL}/api/search`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '90vh'
  },
  inner: {
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  searchBoxLogo: {
    height: '30px'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '2%'
  },
  heading: {
    color: '#394056',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '20px'
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none'
  },
  margin: {
    margin: theme.spacing(2)
  },
  inputs: {
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    animationDuration: '550ms'
  },
  circle: {
    strokeLinecap: 'round'
  },
  error: {
    borderRadius: '10px',
    marginBottom: '10px'
  }
}));

const SearchInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: '10px',
    position: 'relative',
    backgroundColor: '#F5F6FA',
    border: 'none',
    fontSize: '16px',
    width: '325px',
    padding: '10px 12px',
    textAlign: 'center',
    color: '#3C21BA',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&::placeholder': {
      color: '#7A8199'
    },
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

export default function Search() {
  const history = useHistory();
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isInputError, setInputError] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  const auth = useContext(AuthTokenContext);

  const handleSearch = () => {
    if (!auth.accessToken) return;
    axios
      .post(
        searchAPI,
        {
          url: urlValue
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      )
      .then((response) => {
        if (response.data) {
          const { target } = response.data;
          history.push(`/search/${target}`);
        }
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(error.message);
        setIsSubmitting(false);
      });
  };

  const checkValidation = (str) => {
    if (str === '') return;

    if (!isURL(str, { requireProtocol: false })) {
      setError(true);
      setErrorMsg('Domain is invalid');
      setIsSubmitting(false);
    } else {
      handleSearch();
    }
  };

  const handleChange = (e) => {
    const uri = parseUri(e.target.value);

    setUrlValue(uri.host);
  };

  const handleSubmit = (e, isClicked) => {
    if (e.code === 'Enter' || isClicked) {
      const value = urlValue;
      if (value.length > 1) {
        setError(false);
        setIsSubmitting(true);
        checkValidation(value);
      } else {
        setError(true);
        setErrorMsg('Please enter their website');
      }
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Paper className={classes.inner} elevation={0}>
        <img src="/img/prism_full_purple.svg" className={classes.searchBoxLogo} />
        <FormControl className={classes.margin}>
          <Grid container direction="column">
            <Grid className={classes.heading} item>
              Search for a business
            </Grid>
            {isError && (
              <Grid item mdm={12}>
                <Alert className={classes.error} severity="error">
                  {errorMsg}
                </Alert>
              </Grid>
            )}
            <Grid item className={classes.inputs}>
              <Grid container direction="column">
                <Grid item>
                  <SearchInput
                    id="search-input"
                    role="search"
                    placeholder="Enter their website"
                    onChange={handleChange}
                    onKeyDown={handleSubmit}
                    autoFocus
                    autoComplete="off"
                  />
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(e) => handleSubmit(e, true)}>
                    Search
                  </Button>
                </Grid>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.spinner}
                    disableShrink
                    variant="indeterminate"
                    data-testid="loading"
                    size={20}
                    thickness={5}
                    classes={{
                      circle: classes.circle
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </Paper>
    </Grid>
  );
}
