import React from 'react';
import { Grid } from '@material-ui/core';
import ChartAndDetailContainer from '../../Projects/ChartAndDetailContainer';
import ChartContainer from '../../Projects/ChartContainer';
import IndustrySearches from '../IndustrySearches';
import ChartDetail from '../../Projects/ChartDetail';
import * as fillTemplate from 'es6-dynamic-template';
import Loading from '../../Loading';

export default function IndustrySearchesWrapper({
  project,
  isLoadingProject,
  filters,
  cms,
  searchTrendsChartData,
  setSearchTrendsKeywords,
  organisation
}) {
  if (isLoadingProject) return <Loading />;
  if (!cms || !cms.data || !cms.data['search-trend']) return <Loading />;
  if (!project) return <Loading />;

  const data = searchTrendsChartData.data;

  const series = searchTrendsChartData.series;

  if (!data) return <div />;
  if (!series) return <div />;
  if (series.length === 0) return <div />;

  const templateString = cms && cms.data && cms?.data['search-trend']?.content;

  const getTemplateContent = () => {
    return fillTemplate(templateString, searchTrendsChartData.insights);
  };

  if (data && series && series.length > 0 && searchTrendsChartData) {
    return (
      <Grid item md={12}>
        <ChartAndDetailContainer>
          <ChartContainer>
            <IndustrySearches
              title="Search Trend"
              subtitle="Last 4 years"
              dataFetched={project.dataFetched}
              project={project}
              filters={filters}
              data={data}
              series={series}
              categories={searchTrendsChartData.categories}
              keywords={searchTrendsChartData.keywords}
              setSearchTrendsKeywords={setSearchTrendsKeywords}
              organisation={organisation}
            />
          </ChartContainer>

          <ChartDetail score={searchTrendsChartData.score}>
            {cms && cms.data && cms.data['search-trend'] && getTemplateContent()}
          </ChartDetail>
        </ChartAndDetailContainer>
      </Grid>
    );
  }
}
