import React from 'react';
import { Button, Typography, Grid, makeStyles } from '@material-ui/core';
import Grey from '@material-ui/core/colors/grey';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '90vh'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '2px',
    textAlign: 'center'
  },
  button: {
    borderRadius: '2px'
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

function Login() {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={6} xs={12} className={classes.container}>
        <Typography variant="h1" style={{ marginBottom: '50px', textAlign: 'center' }}>
          Prism
        </Typography>
        <Typography variant="subtitle1" style={{ marginBottom: '50px', textAlign: 'center' }}>
          by Palladium Digital
        </Typography>
        <Button
          onClick={() => loginWithRedirect()}
          variant="contained"
          color="primary"
          size="large"
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
}

export default Login;
