import React, { useState, useEffect, useRef } from 'react';
import { Grid, Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCMS } from '../api/cms';
import { red } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 30,
    textAlign: 'center',
    color: '#3C21BA'
  },
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    float: 'right',
    position: 'relative',
    top: '-45px'
  },
  contentContainer: {
    padding: '0 !important',
    overflow: 'hidden'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%'
  },
  col: {
    width: '50%',
    minWidth: '385px'
  },
  childItem: {
    width: '50%',
    padding: 10
  },
  info: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    // minWidth: '376px',
    maxWidth: '376px',
    padding: '40px 58px 51px 58px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  blank: {
    height: '100%',
    boxSizing: 'border-box'
  },
  contentText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#7A8199'
    // maxWidth: '260px'
  },
  slickWrapper: {
    maxWidth: '260px',
    height: '100%'
  },
  contentBox: {
    padding: '30px',
    paddingTop: 0,
    paddingBottom: 0,
    width: '385px',
    overflow: 'hidden'
  },
  insideContent: {
    height: '345px',
    overflowY: 'scroll',
    // padding: '30px',
    '& h1': {
      color: '#3C21BA',
      marginTop: 10
    },
    scrollBarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      // display: 'none'
      width: '2px'
    }
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    height: '10%'
  },
  skipButton: {
    width: '141px',
    height: '47px',
    background: '#BBBFCE',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#FFFFFF',
    borderRadius: '5px',
    border: 0,
    cursor: 'pointer'
  },
  nextButton: {
    width: '141px',
    height: '47px',
    background: '#3C21BA',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#FFFFFF',
    borderRadius: '5px',
    border: 0,
    cursor: 'pointer'
  },
  sliderWrapper: {
    height: '362px'
  },
  imageSlider: {
    height: '382px',
    width: '382px',
    objectFit: 'cover',
    borderRadius: '10px',
    // border: '2px solid #3C21BA',
    marginLeft: '1px'
  },
  '*:focus': {
    outline: '0',
    border: '0'
  }
}));

export default function OnBoardModal({ text, organisation }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [slider1, setSlider1] = useState(true);
  const [slider2, setSlider2] = useState(true);
  const [cmsData, setCmsData] = useState(true);
  const cms = useCMS('welcome/');
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['onBoard']);

  const getContentFromCms = (key) => {
    return cms && cms.data && cms?.data[key]?.content;
  };

  useEffect(() => {
    if (!cms.isLoading) {
      setCmsData(cms.data);
    }
    if (slider1 !== null && typeof slider2?.slickGoTo == 'function') {
      slider2.slickGoTo(0);
    }
  }, [cms]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCookie('onBoard', false, { path: '/' });

    history.push({
      pathname: window.location.pathname,
      search: '?onboard=false'
    });
  };

  const settingsSlider1 = {
    dots: true,
    initialSlide: 0,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false
  };
  const settingsSlider2 = {
    initialSlide: 0,
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false
    // fade: true
  };

  return (
    <Grid className={classes.container}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(31, 0, 74, 0.7)' } }}
        PaperProps={{ style: { borderRadius: 10, maxWidth: 'none', padding: 50, height: '560px' } }}
        open={open}
        onClose={handleClose}
        p={8}>
        <MuiDialogTitle disableTypography className={classes.root}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.contentContainer}>
          <div className={classes.contentWrapper}>
            <div className={[classes.col + ' ' + classes.slickWrapper]} key={cms.isLoading}>
              {!cmsData.isLoading && (
                <Slider
                  {...settingsSlider1}
                  asNavFor={slider2}
                  ref={(slider) => setSlider1(slider)}>
                  {Object.keys(cmsData).map((key, index) => {
                    if (cmsData[key].type == 'image') {
                      return (
                        <div>
                          <img className={classes.imageSlider} src={cmsData[key].url} alt="" />
                        </div>
                      );
                    }
                  })}
                </Slider>
              )}
            </div>
            <div className={[classes.col + ' ' + classes.contentBox]}>
              {!cmsData.isLoading && (
                <Slider
                  className={classes.sliderWrapper}
                  {...settingsSlider2}
                  asNavFor={slider1}
                  ref={(slider) => setSlider2(slider)}>
                  {Object.keys(cmsData).map((key, index) => {
                    if (cmsData[key].type == 'markdown') {
                      return (
                        <div>
                          <div className={classes.insideContent}>
                            <ReactMarkdown>{cmsData[key].content}</ReactMarkdown>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Slider>
              )}
              <div className={classes.buttonWrapper}>
                <button className={classes.skipButton} onClick={handleClose}>
                  Skip
                </button>
                <button
                  className={classes.nextButton}
                  onClick={() => {
                    slider1.slickNext();
                  }}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </MuiDialogContent>
      </Dialog>
    </Grid>
  );
}
