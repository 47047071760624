import React from 'react';
import FlocklerEmbed from '@flockler/react-flockler-embed';

const LinkedInBox = ({ siteUuid, embedUuid }) => {

  return (
    <div style={{ width: '300px' }}>
      <FlocklerEmbed siteUuid={siteUuid} embedUuid={embedUuid} />
    </div>
  );
};

export default LinkedInBox;
