import { Grid, makeStyles, Paper, Typography, Tooltip } from '@material-ui/core';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import * as fillTemplate from 'es6-dynamic-template';
import React, { useContext, useEffect, useState } from 'react';
import { AuthTokenContext } from '../AuthenticationWrapper';
import axios from 'axios';
import { downloadDataExport } from './DownloadDataExport';
import InfoIcon from '@mui/icons-material/Info';
import { ordinalSuffixOf } from '../../utils/numbers';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColour: '#fff',
    padding: '30px',
    borderRadius: '20px',
    marginBottom: '20px'
  },
  createdDate: {
    color: '#555B71',
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '10px',
    position: 'relative'
    // top: '-3px'
  },
  summaryText: {
    color: '#7A8199',
    fontSize: '16px',
    lineHeight: '24px',
    '& p': {
      margin: '0'
    }
  },
  ctaContainer: {
    paddingTop: '20px'
  },
  ctaText: {
    color: '#7A8199',
    fontSize: '16px',
    lineHeight: '24px'
  },
  button: {
    backgroundColor: '#EFEDF9;',
    borderRadius: '5px',
    color: '#3C21BA',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      color: '#3C21BA',
      backgroundColor: 'RGBA(60, 33, 186, 0.2)'
    }
  },
  icon: {
    color: '#FFF',
    marginRight: '10px'
  },
  addButton: {
    borderRadius: '5px',
    textTransform: 'none'
  },
  mainTitle: {
    color: '#3C21BA',
    fontSize: '20px'
  },
  rankingsSubtext: {
    fontSize: '16px',
    color: '#555b71',
    position: 'relative'
  },
  spanWrapper: {
    position: 'relative',
    color: '#3C21BA',
    backgroundColor: '#f5f6fa',
    display: 'inline-block',
    padding: '12px',
    borderRadius: '10px'
  },
  secondNumber: {
    color: '#d2d2de'
  },
  iconWrapper: {
    flex: 0,
    // width: '50px',
    // marginRight: '20px',
    backgroundColor: '#f7f7fd',
    borderRadius: '15px',
    padding: '20px'
  },
  smallRank: {
    position: 'absolute',
    top: -20,
    right: -20,
    color: 'white',
    backgroundColor: '#3c21ba',
    borderRadius: '20px',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    '&.rank1': {
      color: 'black',
      background: 'linear-gradient(90deg, rgba(224,196,143,1) 0%, rgba(245,226,190,1) 100%)'
    },
    '&.rank2': {
      color: 'black',
      backgroundColor: '#dadada',
      background: 'linear-gradient(90deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%)'
    },
    '&.rank3': {
      backgroundColor: '#aa856c',
      background: 'linear-gradient(90deg, rgba(184,146,120,1) 0%, rgba(160,124,99,1) 100%)',
      color: 'white'
    }
  }
}));

export default function SummaryIntro({
  brandSearchChartData,
  project,
  content,
  followersVsEngagementsChartData,
  overallScore,
  searchTrendsChartData,
  socialAudienceChartData,
  summaryData,
  trafficTrendsChartData,
  overviewChartData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  trustpilotChartData,
  websiteEngagementChartData,
  organisation,
  title,
  icon,
  flags,
  siteOptimisationChartData
}) {
  const history = useHistory();

  const auth = useContext(AuthTokenContext);
  const { orgType } = organisation;
  const classes = useStyles();

  const [countries, setCountries] = useState();

  useEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_URL}/api/countries`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setCountries(response.data);
      });
  }, []);

  const onClickUpgrade = () => {
    history.push('/upgrade');
  };

  function ProjectCreationDate({ date }) {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    const text = `Created ${formattedDate}`;
    return <ExtraInfo text={text} icon="/img/ui/time.svg" />;
  }

  function ExtraInfo({ text, icon }) {
    const classes = useStyles();
    return (
      <Grid container justifyContent="center" alignItems={'center'}>
        <img src={icon} />
        <span className={classes.createdDate}>{text}</span>
      </Grid>
    );
  }

  const onClickDownload = () => {
    downloadDataExport(
      project,
      summaryData,
      searchTrendsChartData,
      trafficTrendsChartData,
      overviewChartData,
      trafficByMonthChartData,
      trafficSourceBreakdownChartData,
      websiteEngagementChartData,
      brandSearchChartData,
      trustpilotChartData,
      socialAudienceChartData,
      followersVsEngagementsChartData,
      siteOptimisationChartData
    );
  };
  return (
    <Grid item md={12}>
      <Paper elevation={0} className={classes.container}>
        <Grid container alignItems="center">
          <Grid item md={1} className={classes.iconWrapper}>
            <img src={icon} />
          </Grid>
          <Grid item md={11}>
            <Grid item>
              <Grid
                item
                md={11}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 20
                }}>
                <Typography
                  className={classes.mainTitle}
                  style={{
                    color: title == 'Further Investigation' ? '#ed217c' : '#3c21ba',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (title === 'Rankings') {
                      const section = document.querySelector('#rankings');
                      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    if (title === 'Further Investigation') {
                      const section = document.querySelector('#flags');
                      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                  }}>
                  {title}
                </Typography>
                <div
                  style={{
                    marginLeft: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '40px',
                    alignItems: 'flex-start'
                  }}>
                  <Tooltip title={content}>
                    <InfoIcon style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </div>
              </Grid>
              {title === 'Rankings' ? (
                <RankingsSubtext
                  marketScore={summaryData?.insights?.marketScore}
                  overallScore={summaryData.insights.overallScore}
                  overallRank={summaryData.insights.overallRank}
                />
              ) : (
                <FlagsSubtext numberOfFlags={flags?.length} />
              )}
            </Grid>
            {/* <ProjectSummaryText
              content={content}
              overallScore={overallScore}
              summaryData={summaryData}
            /> */}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

function ProjectSummaryText({ content, overallScore, summaryData }) {
  const classes = useStyles();

  const templateString = content;
  const getTemplateContent = () => {
    try {
      return fillTemplate(templateString, summaryData.insights);
    } catch (e) {
      console.log('ERROR', e);
    }
  };
  return (
    <div className={classes.summaryText}>
      <ReactMarkdown>{getTemplateContent()}</ReactMarkdown>
    </div>
  );
}

const RankingsSubtext = ({ marketScore, overallRank, overallScore }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ paddingLeft: 20 }}>
      <Grid item md={6} style={{ maxWidth: 400 }}>
        <Typography className={classes.rankingsSubtext}>
          Market growth score{' '}
          <span className={classes.spanWrapper}>
            {marketScore}
            <span className={classes.secondNumber}>/100</span>
          </span>
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Typography className={classes.rankingsSubtext}>
          Target asset score{' '}
          {overallRank && (
            <span className={classes.spanWrapper}>
              {overallScore}
              <span className={classes.secondNumber}>/100</span>
              <span className={[classes.smallRank, 'rank' + overallRank].join(' ')}>
                {ordinalSuffixOf(overallRank)}
              </span>
            </span>
          )}
          {!overallRank && <span className={classes.spanWrapper}>Loading..</span>}
        </Typography>
      </Grid>
    </Grid>
  );
};
const FlagsSubtext = ({ numberOfFlags }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ paddingLeft: 20 }}>
      <Grid item md={6} style={{ maxWidth: 400 }}>
        <Typography className={classes.rankingsSubtext}>{numberOfFlags} Flags</Typography>
      </Grid>
    </Grid>
  );
};
