import { Button, Divider, Grid, Paper, makeStyles, withStyles } from '@material-ui/core';
import axios from 'axios';
import { useContext, useEffect } from 'react';
import { AuthTokenContext } from './AuthenticationWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%',
    height: `calc(100vh - ${80}px)`
  },
  root: {
    padding: '40px',
    borderRadius: '20px',
    width: '600px'
  },
  title: {
    color: '#1F004A',
    fontWeight: '400',
    fontSize: '30px'
  },
  paragraph: {
    color: '#7A8199',
    fontSize: '20px',
    textAlign: 'center'
  },
  button: {
    textTransform: 'none'
  }
}));

const CustomButton = withStyles((theme) => ({
  root: {
    padding: '8px 15px',
    textTransform: 'none',
    fontSize: '16px',
    borderRadius: '10px'
  }
}))(Button);

export default function Error({ error }) {
  const { user, isAuthenticated, loading, getAccessTokenSilently } = useAuth0();
  const auth = useContext(AuthTokenContext);
  const classes = useStyles();

  const errorToReport = typeof error === 'string' ? error : error.toString();
  const errorData = {
    error: {
      message: errorToReport,
      url: window.location.href,
      user: user?.email || null
    }
  };

  useEffect(() => {
    // Define an async function to send the ajax request
    const sendRequest = async () => {
      try {
        // Send the ajax request and wait for the response
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/error-report`,
          errorData,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`
            }
          }
        );

        // Handle the response from the server
      } catch (error) {
        // Handle any errors that occurred while sending the request
      }
    };

    // Call the async function
    sendRequest();
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.outer}>
      <Paper elevation={1} className={classes.root}>
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          direction="column"
          spacing={3}>
          <Grid item>
            <h1 className={classes.title}>Oops! Something went wrong.</h1>
          </Grid>
          <Grid item>
            <img src="/img/ui/error.svg" />
          </Grid>
          <Grid item>
            <p className={classes.paragraph}>
              Please try reloading the page. If this happens again, please contact a member of the
              team.
            </p>
          </Grid>
          <Grid item>
            <CustomButton
              href="/"
              variant="contained"
              color="primary"
              disableEvelation
              className={classes.button}>
              Go to Prism home
            </CustomButton>
          </Grid>
          {process.env.NODE_ENV !== 'production' && <DetailedErrors error={error} />}
        </Grid>
      </Paper>
    </Grid>
  );
}

function DetailedErrors({ error }) {
  if (!error) return null;
  return (
    <>
      <h3>
        {error?.name}: {error?.message}
      </h3>

      <Divider variant="middle" />
      {error?.info?.error && <p>{error?.info?.error}</p>}
      {error?.info?.errors &&
        error?.info?.errors.map((error) => (
          <p key={error.value}>
            {error?.msg} - {error?.value}
          </p>
        ))}
    </>
  );
}

function sendErrorToBackend(error) {
  // send error to backend
}
