import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
  Button
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { ordinalSuffixOf } from '../../utils/numbers';
import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'react-tabulator/css/materialize/tabulator_materialize.min.css';
import { ReactTabulator } from 'react-tabulator';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '20px',
    background: 'white',
    padding: '30px'
  },
  blockContainer: {
    margin: '10px',
    '&:not(:last-child) $block $gridContainer': {
      paddingBottom: 20,
      borderBottom: '2px solid #eaeaea',
      borderRadius: 0
    },
    '&:nth-child(1) $rank': {
      background: 'linear-gradient(90deg, rgba(224,196,143,1) 0%, rgba(245,226,190,1) 100%)'
    },
    '&:nth-child(2) $rank': {
      backgroundColor: '#dadada',
      background: 'linear-gradient(90deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%)'
    },
    '&:nth-child(3) $rank': {
      backgroundColor: '#aa856c',
      background: 'linear-gradient(90deg, rgba(184,146,120,1) 0%, rgba(160,124,99,1) 100%)',
      color: 'white'
    }
  },
  rankWrapper: {
    padding: '0px !important',
    maxWidth: 80
    // marginRight: 20
  },
  block: {
    // backgroundColor: '#f6f6fa',#
    padding: '20px'

    // margin: '20px'
  },
  mainCompName: {
    width: '100%',
    backgroundColor: 'transparent'
  },
  main: {
    '& $gridContainer': {
      borderBottom: '0 !important'
    },
    '& $companyName': {
      fontSize: '20px',
      color: '#7a8199',
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  mainTitle: {
    color: '#3C21BA',
    fontSize: '26px'
  },
  title: {
    color: '#4a5475',
    fontSize: '18px',
    fontWeight: '600',
    margin: '20px',
    borderBottom: '2px solid #eaeaea',
    paddingBottom: 15,
    '& span': {
      fontWeight: '300',
      color: '#7a8199'
    }
  },
  subtitle: {
    color: '#7A8199',
    fontSize: '16px',
    fontWeight: '500',
    margin: '20px',
    marginTop: -20
  },
  score: {
    color: '#1F004A',
    fontSize: '20px',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '10px',
    backgroundColor: 'grey',
    '& span:not(.default)': {
      display: 'inline-block',
      marginLeft: 10,
      backgroundColor: 'white',
      padding: 7,
      borderRadius: 7
    },
    '&.good': {
      backgroundColor: '#e4f9f6',
      color: '#3edab9'
    },
    '&.maybe': {
      backgroundColor: '#fff2e5',
      color: '#ff9d37'
    },
    '&.bad': {
      backgroundColor: '#ffcbcc',
      color: '#ff8787'
    },
    '& span.default': {
      color: '#7A8199',
      opacity: 0.5
    }
  },
  companyName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    color: '#1F004A',
    fontWeight: '600',
    paddingLeft: '0px !important',
    maxWidth: 'calc(100% - 80px)',
    flexBasis: 'calc(100% - 80px)',
    padding: 0
  },
  rank: {
    color: '#394056',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '100%',
    padding: '12px',
    width: '50px',
    height: '50px',
    backgroundColor: '#f7f7fd'
  },
  iconWrapper: {
    flex: 0,
    // width: '50px',
    // marginRight: '20px',
    backgroundColor: '#f7f7fd',
    borderRadius: '15px',
    padding: '20px'
  },
  blockWrapper: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '90px'
    }
  },
  gridContainer: {},
  companyNameText: {
    fontSize: '16px',
    fontWeight: '300',
    color: '#7a8199',
    paddingBottom: 0
  },
  tabulatorContainer: {
    position: 'relative',
    [theme.breakpoints.only('lg')]: {
      // '&.twoColumns .tabulator-col': {
      //   maxWidth: '11vw !important'
      // },
      // '&.threeColumns .tabulator-col': {
      //   maxWidth: '11vw !important'
      // },
      '&.fourColumns .tabulator-col': {
        maxWidth: '18.2vw !important'
      },
      '&.fiveColumns .tabulator-col': {
        maxWidth: '15vw !important'
      },
      '&.sixColumns .tabulator-col': {
        maxWidth: '12.1vw !important'
      },
      '&.sevenColumns .tabulator-col': {
        maxWidth: '10.3vw !important'
      }
    },
    margin: '20px',
    '& .tabulator-table': {
      width: 'calc(100% + 2px)'
      // width: '80%',
      // marginLeft: '10%'
    },
    '& .tabulator-cell': {
      fontWeight: 300,
      color: '#4a4a4a'
    },
    '& .tabulator-tree-level-0 > .tabulator-cell': {
      // borderBottom: '2px solid black'
      fontWeight: 800,
      color: '#000000'
    }
  }
}));

const getClass = (score) => {
  if (score >= 66) {
    return 'good';
  } else if (score >= 33) {
    return 'maybe';
  } else if (score > 0) {
    return 'bad';
  }
};

export default function TargetAssetScores({
  summaryData,
  overviewChartData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  websiteEngagementChartData,
  brandSearchChartData,
  trustpilotChartData,
  socialAudienceChartData,
  followersVsEngagementsChartData,
  siteOptimisationChartData,
  ...props
}) {
  const classes = useStyles();

  let columns = [{ title: '', field: 'control', resizable: false }];
  var data = [];

  function testFormatter(cell, formatterParams) {
    cell.getElement().style.textAlign = 'center';
    var bvid = Number(cell.getValue());
    bvid = bvid ? bvid : 'n/a';
    if (bvid >= 66) {
      cell.getElement().style.backgroundColor =
        'rgba(' + (280 - bvid) + ',' + 249 + ',' + 235 + ')';
    } else if (bvid >= 33) {
      cell.getElement().style.backgroundColor = 'rgba(255,' + (190 + bvid) + ',229)';
    } else if (bvid >= 0) {
      cell.getElement().style.backgroundColor = 'rgba(' + (230 + bvid) + ',150,150)';
    } else if (bvid == 'n/a') {
      cell.getElement().style.backgroundColor = '#ececec';
      cell.getElement().style.color = '#7a8199';
    }

    return bvid;
  }

  function replaceWhitespaceWithHyphens(str) {
    return str.replace(/\./g, '-');
  }

  let overallObj = { control: 'Overall' };

  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      columns.push({
        title: item.name,
        field: name,
        formatter: testFormatter,
        resizable: false
      });
      overallObj[name] = item.score.toFixed(0);
    });

  data.push(overallObj);

  let trafficShareScores = {};
  overviewChartData?.insights &&
    overviewChartData?.insights.scores &&
    overviewChartData?.insights.scores.length &&
    overviewChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      trafficShareScores[name] = item?.score.toFixed(0);
    });

  let trafficTrendScores = {};
  trafficByMonthChartData?.insights &&
    trafficByMonthChartData?.insights.scores &&
    trafficByMonthChartData?.insights.scores.length &&
    trafficByMonthChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      trafficTrendScores[name] = item?.score.toFixed(0);
    });

  let marketingEfficiencyScores = {};
  trafficSourceBreakdownChartData?.insights &&
    trafficSourceBreakdownChartData?.insights.scores &&
    trafficSourceBreakdownChartData?.insights.scores.length &&
    trafficSourceBreakdownChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      marketingEfficiencyScores[name] = item?.score.toFixed(0);
    });

  let engagementStrengthScores = {};
  websiteEngagementChartData?.insights &&
    websiteEngagementChartData?.insights.scores &&
    websiteEngagementChartData?.insights.scores.length &&
    websiteEngagementChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      engagementStrengthScores[name] = item?.score.toFixed(0);
    });

  let performanceAvg = {};
  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      const averageResult =
        (Number(trafficShareScores[name]) +
          Number(trafficTrendScores[name]) +
          Number(marketingEfficiencyScores[name]) +
          Number(engagementStrengthScores[name])) /
        4;
      performanceAvg[name] = averageResult.toFixed(0);
    });

  let performanceObj = { control: 'Performance', ...performanceAvg };

  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      // performanceObj['index' + index] = item.score.toFixed(2);
      performanceObj['_children'] = [
        {
          control: 'Traffic Share',
          ...trafficShareScores
        },
        {
          control: 'Traffic Trend',
          ...trafficTrendScores
        },
        {
          control: 'Marketing Efficiency',
          ...marketingEfficiencyScores
        },
        {
          control: 'Engagement Strength',
          ...engagementStrengthScores
        }
      ];
    });

  data.push(performanceObj);

  let brandScaleAndVelocityScores = {};
  brandSearchChartData?.insights &&
    brandSearchChartData?.insights.scores &&
    brandSearchChartData?.insights.scores.length &&
    brandSearchChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      brandScaleAndVelocityScores[name] = item?.score?.toFixed(0);
    });

  let brandCustomerExperienceScores = {};
  trustpilotChartData?.insights &&
    trustpilotChartData?.insights.scores &&
    trustpilotChartData?.insights.scores.length &&
    trustpilotChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      brandCustomerExperienceScores[name] = item?.score?.toFixed(0);
    });

  let brandAvg = {};
  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      const averageResult =
        (Number(brandScaleAndVelocityScores[name]) + Number(brandCustomerExperienceScores[name])) /
        2;
      brandAvg[name] = averageResult?.toFixed(0);
    });

  let brandObj = { control: 'Brand', ...brandAvg };

  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      // brandObj['index' + index] = item.score.toFixed(2);
      brandObj['_children'] = [
        {
          control: 'Brand Scale and Velocity',
          ...brandScaleAndVelocityScores
        },
        {
          control: 'Customer Experience',
          ...brandCustomerExperienceScores
        }
      ];
    });

  data.push(brandObj);

  let socialAudienceScores = {};
  socialAudienceChartData?.insights &&
    socialAudienceChartData?.insights.scores &&
    socialAudienceChartData?.insights.scores.length &&
    socialAudienceChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      socialAudienceScores[name] = item?.score?.toFixed(0);
    });

  let socialEngagementScores = {};
  followersVsEngagementsChartData?.insights &&
    followersVsEngagementsChartData?.insights.scores &&
    followersVsEngagementsChartData?.insights.scores.length &&
    followersVsEngagementsChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      socialEngagementScores[name] = item.score ? item?.score?.toFixed(0) : '0';
    });

  let socialAvg = {};
  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      const averageResult =
        (Number(socialAudienceScores[name]) + Number(socialEngagementScores[name])) / 2;
      socialAvg[name] = averageResult?.toFixed(0);
    });

  let socialObj = { control: 'Social', ...socialAvg };

  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      // socialObj['index' + index] = item.score.toFixed(2);
      socialObj['_children'] = [
        {
          control: 'Social Audience',
          ...socialAudienceScores
        },
        {
          control: 'Social Engagement',
          ...socialEngagementScores
        }
      ];
    });

  data.push(socialObj);

  let technologyMaturityScores = {};
  siteOptimisationChartData?.insights &&
    siteOptimisationChartData?.insights.scores &&
    siteOptimisationChartData?.insights.scores.length &&
    siteOptimisationChartData?.insights?.scores.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      technologyMaturityScores[name] = item.score ? item?.score?.toFixed(0) : '0';
    });

  let technologyAvg = {};
  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      const name = replaceWhitespaceWithHyphens(item.name);
      const averageResult =
        (Number(technologyMaturityScores[name]) + Number(technologyMaturityScores[name])) / 2;
      technologyAvg[name] = averageResult?.toFixed(0);
    });

  let technologyObj = { control: 'Technology', ...technologyAvg };

  summaryData?.insights &&
    summaryData?.insights.rankedAssets &&
    summaryData?.insights.rankedAssets.length &&
    summaryData?.insights?.rankedAssets.map((item, index) => {
      // technologyObj['index' + index] = item.score.toFixed(2);
      technologyObj['_children'] = [
        {
          control: 'Technology Maturity',
          ...technologyMaturityScores
        }
        // {
        //   control: 'Capability Dimensions',
        //   ...technologyMaturityScores
        // }
      ];
    });

  data.push(technologyObj);

  // const columns = [
  //   { title: 'Name', field: 'name', width: 200, responsive: 0 }, //never hide this column
  //   { title: 'Location', field: 'location', width: 150 },
  //   { title: 'Gender', field: 'gender', width: 150, responsive: 2 }, //hide this column first
  //   { title: 'Favourite Color', field: 'col', width: 150 },
  //   { title: 'Date Of Birth', field: 'dob', hozAlign: 'center', sorter: 'date', width: 150 }
  // ];

  const config = {
    dataTree: true,
    dataTreeStartExpanded: false,
    layout: 'fitColumns',
    minHeight: 300,
    layoutColumnsOnNewData: true
  };

  let columnClass = '';
  if (columns.length === 2) {
    columnClass = 'twoColumns';
  } else if (columns.length === 3) {
    columnClass = 'threeColumns';
  } else if (columns.length === 4) {
    columnClass = 'fourColumns';
  } else if (columns.length === 5) {
    columnClass = 'fiveColumns';
  } else if (columns.length === 6) {
    columnClass = 'sixColumns';
  } else if (columns.length === 7) {
    columnClass = 'sevenColumns';
  }

  return (
    <Grid {...props} className={classes.container} container item direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item md={1} className={classes.iconWrapper}>
            <img src="/img/ui/Ranking.svg" />
          </Grid>
          <Grid item md={11}>
            <Grid
              item
              md={11}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 20
              }}>
              <Typography className={classes.mainTitle}>Rankings</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.blockWrapper}>
        <Grid item>
          <Typography className={classes.title}>Market Indicators</Typography>
        </Grid>
        {/* <Grid item container spacing={3} alignItems="center">
        <Grid item className={classes.companyName} md={12}>
          Market Growth Score
          <Paper
            elevation={0}
            className={[classes.score, getClass(summaryData.insights.marketScore)]}>
            Score{' '}
            <span>
              {summaryData.insights.marketScore}
              <span className="default">/100</span>
            </span>
          </Paper>
        </Grid>
      </Grid> */}
        <Block
          companyName="Market Growth Score"
          score={summaryData.insights.marketScore}
          className={classes.main}
          isMain
        />

        <Grid item style={{ marginTop: 20 }}>
          <Typography className={classes.title}>
            Competitive Benchmarking <span>- Competitor score for all diligence areas</span>
          </Typography>
        </Grid>
        <Grid item container direction="column">
          {summaryData?.insights &&
            summaryData?.insights.rankedAssets &&
            summaryData?.insights.rankedAssets.length &&
            summaryData?.insights?.rankedAssets.map((item, index) => (
              <Block
                key={index}
                companyName={item.name}
                score={item?.score?.toFixed(0)}
                rank={ordinalSuffixOf(item.rank)}
              />
            ))}
        </Grid>
        {data.length > 0 && (
          <div style={{ marginTop: 40 }}>
            <Grid item>
              <Typography className={classes.title}>Scores by Section</Typography>
            </Grid>

            <div className={`${classes.tabulatorContainer} ${columnClass}`}>
              <ReactTabulator columns={columns} options={config} data={data} />
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
}

function Block({ companyName, score, isMain, rank }) {
  const classes = useStyles();

  return (
    <Grid
      item
      spacing={1}
      direction="column"
      alignItems="center"
      className={classes.blockContainer}>
      <Paper
        elevation={0}
        className={`${classes.block} ${isMain ? classes.main : ''}`}
        style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid item container spacing={3} alignItems="center" className={classes.gridContainer}>
          {rank && (
            <Grid item md={1} className={classes.rankWrapper}>
              <Paper elevation={0} className={classes.rank}>
                {rank}
              </Paper>
            </Grid>
          )}
          <Grid
            item
            className={classes.companyName}
            md={`${isMain ? 12 : 11}`}
            style={{ paddingTop: 0, paddingBottom: 0 }}>
            <span className={classes.companyNameText}>{companyName}</span>
            <Paper elevation={0} className={[classes.score, getClass(score)]}>
              Score{' '}
              <span>
                {score}
                <span className="default">/100</span>
              </span>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
