import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grabbingDataFooter: {
    height: '70px',
    color: 'white',
    backgroundColor: '#3C21BA',
    fontSize: '16px',
    zIndex: 999,
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: '80px'
  },
  spinner: {
    animationDuration: '550ms',
    color: 'white'
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

export default function GrabbingDataFooter() {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.grabbingDataFooter}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container item md={6} justify="center" alignItems="center">
        <Grid item md={1}>
          <CircularProgress
            className={classes.spinner}
            disableShrink
            variant="indeterminate"
            size={35}
            thickness={5}
            classes={{
              circle: classes.circle
            }}
          />
        </Grid>
        <Grid item md={11}>
          <p>
            <strong>Grabbing data…</strong> This process usually takes about 24 hours. We’ll notify
            you when we’re ready.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}
