import { makeStyles } from '@material-ui/core';

export function WarningTriangle() {
  const useStyles = makeStyles((theme) => ({
    warningIconContainer: {
      marginRight: '5px'
    }
  }));

  const classes = useStyles();

  return (
    <span className={classes.warningIconContainer}>
      <img src="/img/ui/warning.png" alt="Warning Icon" height={20} />
    </span>
  );
}
