import { Grid, makeStyles, Button } from '@material-ui/core';
import SummaryIntro from '../Projects/SummaryIntro';
import TargetAssetScores from './TargetAssetScores';
import TargetAssetRedFlags from './TargetAssetRedFlags';
import { calculateFlags } from '../../utils/flags';
import { useCMS } from '../../api';
import { useRef } from 'react';
import { downloadDataExport } from '../Projects/DownloadDataExport';
import GetAppIcon from '@material-ui/icons/GetApp';
import UpgradeButton from '../Auth/UpgradeButton';
import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import { AuthTokenContext } from '../AuthenticationWrapper';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    marginTop: '10px',

    '@media print': {
      display: 'block',
      flexDirection: 'column',
      flexWrap: 'nowrap'
    }
  },
  title: {
    fontSize: '30px',
    color: '#3C21BA',
    padding: '0 10px'
  },

  summaryPageContentContainer: {
    '@media print': {
      margin: 0,
      padding: 0,
      maxWidth: '80%'
    }
  },

  redFlagsContainer: {
    '@media print': {
      'break-before': 'page',
      'page-break-before': 'always'
    }
  },
  viewChartButton: {
    backgroundColor: '#ebe8f8',
    color: '#3c21ba',
    fontSize: 14,
    '&:hover span': { color: '#1976d2' }
  },

  advisoryHubCtaContainer: {
    border: '1px solid #3c21ba',
    borderRadius: '20px',
    padding: '30px',
    color: '#3c21ba'
  },

  advisoryHubText: {
    fontSize: '20px'
  },

  advisoryHubCta: {
    textTransform: 'none !important'
  }
}));

export default function SummaryPageContent({
  brandSearchChartData,
  followersVsEngagementsChartData,
  overviewChartData,
  project,
  searchTrendsChartData,
  socialAudienceChartData,
  socialbladeScore,
  summaryData,
  trafficByMonthChartData,
  trafficSourceBreakdownChartData,
  trafficTrendsChartData,
  trustpilotChartData,
  trustpilotScore,
  websiteEngagementScore,
  websiteEngagementChartData,
  organisation,
  siteOptimisationChartData
}) {
  const history = useHistory();
  const [countries, setCountries] = useState();
  const classes = useStyles();
  const auth = useContext(AuthTokenContext);
  const cms = useCMS();
  const cmsContentRankings = cms && cms.data && cms?.data['rankings-summary']?.content;
  const cmsContentFlags = cms && cms.data && cms?.data['flags-summary']?.content;
  const flagsRef = useRef(null);
  const rankingsRef = useRef(null);

  function AdvisoryHubCta() {
    const classes = useStyles();

    return (
      <Grid
        container
        md={12}
        sm={12}
        justifyContent="space-between"
        alignItems="center"
        className={classes.advisoryHubCtaContainer}
        style={{ width: '90%', margin: 0, paddingRight: 20 }}>
        <Grid item className={classes.advisoryHubText}>
          <div style={{ display: 'flex' }}>Find out how Palladium could assist you further</div>
        </Grid>

        <Grid item>
          <Button
            className={classes.advisoryHubCta}
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              history.push('/advisory-hub');
            }}>
            <span>Learn more</span>
          </Button>
        </Grid>
      </Grid>
    );
  }

  const flags = calculateFlags({
    brandSearchChartData,
    followersVsEngagementsChartData,
    overviewChartData,
    searchTrendsChartData,
    socialAudienceChartData,
    socialbladeScore,
    summaryData,
    trafficByMonthChartData,
    trafficSourceBreakdownChartData,
    trafficTrendsChartData,
    trustpilotScore,
    websiteEngagementScore,
    siteOptimisationChartData
  });

  summaryData.insights.redFlagCount = flags.length;

  // summary-ranking
  // summary-flags

  const onClickDownload = () => {
    downloadDataExport(
      project,
      summaryData,
      searchTrendsChartData,
      trafficTrendsChartData,
      overviewChartData,
      trafficByMonthChartData,
      trafficSourceBreakdownChartData,
      websiteEngagementChartData,
      brandSearchChartData,
      trustpilotChartData,
      socialAudienceChartData,
      followersVsEngagementsChartData,
      siteOptimisationChartData
    );
  };

  function ProjectCreationDate({ date }) {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    const text = `Created ${formattedDate}`;
    return <ExtraInfo text={text} icon="/img/ui/time.svg" />;
  }

  function ExtraInfo({ text, icon }) {
    const classes = useStyles();
    return (
      <Grid container justifyContent="center" alignItems={'center'}>
        <img src={icon} style={{ marginRight: 10 }} />
        <span className={classes.createdDate}>{text}</span>
      </Grid>
    );
  }

  useEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_URL}/api/countries`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        setCountries(response.data);
      });
  }, []);

  const { orgType } = organisation;

  return (
    <div className={classes.summaryPageContentContainer}>
      <Grid className={classes.rowContainer} container item style={{ width: '90%' }}>
        <Grid item sm={4} md={6} lg={6} xl={6}>
          <p className={classes.title}>Summary</p>
        </Grid>
        <Grid
          item
          sm={7}
          md={6}
          lg={6}
          xl={6}
          style={{
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap'
          }}>
          <div>
            <ProjectCreationDate date={project.createdAt} />
          </div>
          <div>
            {project && project?.countries?.length && countries && countries.length && (
              <ExtraInfo
                text={countries.find((c) => c.code === project?.countries[0]).name}
                icon="/img/ui/globe.svg"
              />
            )}
          </div>
          <div>
            {orgType === 'Lite' ? (
              <UpgradeButton />
            ) : (
              <Button
                className={classes.viewChartButton}
                endIcon={<GetAppIcon />}
                color="secondary"
                disableElevation
                onClick={onClickDownload}>
                <span>Full Data Export</span>
              </Button>
            )}
          </div>
        </Grid>
        <Grid item md={12} sm={12}>
          <>
            {cmsContentRankings && (
              <SummaryIntro
                brandSearchChartData={brandSearchChartData}
                content={cmsContentRankings}
                followersVsEngagementsChartData={followersVsEngagementsChartData}
                overallScore={summaryData.insights.overallScore}
                overviewChartData={overviewChartData}
                project={project}
                summaryData={summaryData}
                searchTrendsChartData={searchTrendsChartData}
                socialAudienceChartData={socialAudienceChartData}
                trafficTrendsChartData={trafficTrendsChartData}
                trafficByMonthChartData={trafficByMonthChartData}
                trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
                trustpilotChartData={trustpilotChartData}
                websiteEngagementChartData={websiteEngagementChartData}
                organisation={organisation}
                siteOptimisationChartData={siteOptimisationChartData}
                title="Rankings"
                icon="/img/ui/Ranking.svg"
              />
            )}
            {cmsContentFlags && (
              <SummaryIntro
                brandSearchChartData={brandSearchChartData}
                content={cmsContentFlags}
                followersVsEngagementsChartData={followersVsEngagementsChartData}
                overallScore={summaryData.insights.overallScore}
                overviewChartData={overviewChartData}
                project={project}
                summaryData={summaryData}
                searchTrendsChartData={searchTrendsChartData}
                socialAudienceChartData={socialAudienceChartData}
                trafficTrendsChartData={trafficTrendsChartData}
                trafficByMonthChartData={trafficByMonthChartData}
                trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
                trustpilotChartData={trustpilotChartData}
                websiteEngagementChartData={websiteEngagementChartData}
                organisation={organisation}
                title="Further Investigation"
                icon="/img/ui/Flag.svg"
                flags={flags}
                siteOptimisationChartData={siteOptimisationChartData}
              />
            )}
          </>
        </Grid>
      </Grid>

      <AdvisoryHubCta />

      <Grid item md={12} sm={12}>
        <p className={classes.title}>Detailed Summary</p>
      </Grid>
      <Grid
        className={classes.rowContainer}
        container
        item
        spacing={2}
        md={12}
        style={{ width: '90%', margin: 0, paddingRight: 20 }}>
        <Grid container item md={12} sm={12} style={{ padding: 0 }}>
          <TargetAssetScores
            overviewChartData={overviewChartData}
            trafficByMonthChartData={trafficByMonthChartData}
            trafficSourceBreakdownChartData={trafficSourceBreakdownChartData}
            websiteEngagementChartData={websiteEngagementChartData}
            brandSearchChartData={brandSearchChartData}
            trustpilotChartData={trustpilotChartData}
            socialAudienceChartData={socialAudienceChartData}
            followersVsEngagementsChartData={followersVsEngagementsChartData}
            siteOptimisationChartData={siteOptimisationChartData}
            id="rankings"
            summaryData={summaryData}
          />
        </Grid>
        <Grid
          container
          item
          md={12}
          sm={12}
          className={classes.redFlagsContainer}
          style={{ padding: 0, marginTop: 10 }}>
          <TargetAssetRedFlags id="flags" flags={flags} />
        </Grid>
      </Grid>

      <div style={{ margin: '2rem' }}></div>

      <AdvisoryHubCta />

      <div style={{ margin: '2rem' }}></div>
      <div style={{ margin: '2rem' }}></div>
      <div style={{ margin: '2rem' }}>&nbsp;</div>
    </div>
  );
}
