import React from 'react';
import { useParams } from 'react-router-dom';
import { useCMS } from '../../api';
import Error from '../../components/Error';

import { Grid, makeStyles } from '@material-ui/core';
import ChartAndDetailContainer from '../../components/Projects/ChartAndDetailContainer';
import ChartContainer from '../../components/Projects/ChartContainer';
import ChartDetail from '../../components/Projects/ChartDetail';
import * as fillTemplate from 'es6-dynamic-template';
import SocialAudience from '../../components/Social/SocialAudience';
import EngagementVsFollowers from '../../components/Social/EngagementVsFollowers';
import Loading from '../Loading';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  graphContainer: {
    width: '620px',
    height: '408px',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px'
  },

  pageBreakAfter: {
    [`@media print`]: {
      'page-break-after': 'always'
    }
  }
}));

export default function SocialContent({
  project,
  isLoadingProject,
  isErrorProject,
  socialAudienceChartData,
  isSocialbladeLoading,
  followersVsEngagementsChartData,
  organisation
}) {
  const classes = useStyles();

  const cms = useCMS();

  if (isLoadingProject || isSocialbladeLoading) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const getContentFromCms = (key) => {
    return cms && cms.data && cms?.data[key]?.content;
  };

  const socialAudienceTemplateString = getContentFromCms('social-audience');
  const getSocialAudienceChartTemplateContent = () => {
    return fillTemplate(socialAudienceTemplateString, socialAudienceChartData?.insights);
  };

  const followersVsEngagementsTemplateString = getContentFromCms('social-engagement');
  const getFollowersVsEngagementsChartTemplateContent = () => {
    return fillTemplate(
      followersVsEngagementsTemplateString,
      followersVsEngagementsChartData?.insights
    );
  };

  return (
    <>
      <div className={classes.pageBreakAfter}>
        {socialAudienceChartData && socialAudienceChartData.data && (
          <Grid item md={12} xs={12}>
            <ChartAndDetailContainer>
              <ChartContainer>
                <SocialAudience
                  title="Social Audience"
                  project={project}
                  dataFetched={project.dataFetched}
                  isSocialbladeLoading={isSocialbladeLoading}
                  socialAudienceChartData={socialAudienceChartData}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={socialAudienceChartData.score}>
                {cms &&
                  cms.data &&
                  cms.data['social-audience'] &&
                  !isSocialbladeLoading &&
                  socialAudienceChartData?.insights?.targetName &&
                  getSocialAudienceChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          </Grid>
        )}
      </div>

      <div className={classes.pageBreakAfter}>
        {followersVsEngagementsChartData && followersVsEngagementsChartData.data && (
          <Grid item md={12} xs={12}>
            <ChartAndDetailContainer>
              <ChartContainer>
                <EngagementVsFollowers
                  title="Social Engagement"
                  project={project}
                  dataFetched={project.dataFetched}
                  isSocialbladeLoading={isSocialbladeLoading}
                  followersVsEngagementsChartData={followersVsEngagementsChartData}
                  organisation={organisation}
                />
              </ChartContainer>
              <ChartDetail score={followersVsEngagementsChartData.score}>
                {cms &&
                  cms.data &&
                  cms.data['social-engagement'] &&
                  !isSocialbladeLoading &&
                  followersVsEngagementsChartData?.insights?.targetName &&
                  getFollowersVsEngagementsChartTemplateContent()}
              </ChartDetail>
            </ChartAndDetailContainer>
          </Grid>
        )}
      </div>
    </>
  );
}
