import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh'
  },
  searchBoxLogo: {
    height: '30px'
  },

  date: {
    fontSize: '18px',
    marginTop: '10px'
  },

  projectTitle: {
    fontSize: '30px',
    marginTop: '100px'
  }
}));

export default function CoverPage({ project }) {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <img src="/img/prism_full_purple.svg" className={classes.searchBoxLogo} />

        <div className={classes.projectTitle}>{project.domain}</div>

        <div className={classes.date}>{new Date().toLocaleDateString()}</div>
      </Grid>
    </>
  );
}
