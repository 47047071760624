import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '@media print': {
      fontSize: '12px',
      maxWidth: '85%'
    }
  }
}));

export default function ChartContainer(props) {
  const classes = useStyles();

  return <div className={classes.container + ' chart-container'}>{props.children}</div>;
}
