import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    height: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 25px',
    '&:hover': {
      cursor: 'pointer'
    },
    '& img': {
      width: '20px',
      height: '20px'
    }
  }
}));

function Logout() {
  const { logout } = useAuth0();
  const classes = useStyles();
  return (
    <a className={classes.link} onClick={() => logout({ returnTo: window.location.origin })}>
      <img src="/img/ui/logout.svg" alt="Log out" />
    </a>
  );
}

export default Logout;
