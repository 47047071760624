import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCMS } from '../../api';
import Error from '../../components/Error';
import CompanyInformationHeader from '../../components/Company/CompanyInformationHeader';
import TabMenu from '../../components/Projects/TabMenu';
import GrabbingDataFooter from '../../components/Projects/GrabbingDataFooter';

import { Grid, makeStyles } from '@material-ui/core';
import { setDefaultCompanyInfo } from '../../utils/setDefaultCompanyInfo';
import ChartAndDetailContainer from '../../components/Projects/ChartAndDetailContainer';
import ChartContainer from '../../components/Projects/ChartContainer';
import WebsiteTrafficVolumes from '../../components/CompetitorAnalysis/WebsiteTrafficVolumes';
import ChartDetail from '../../components/Projects/ChartDetail';
import BrandSearches from '../../components/Brand/BrandSearches';
import * as fillTemplate from 'es6-dynamic-template';
import TrustPilot from '../../components/Brand/TrustPilot';
import Loading from '../Loading';

const useStyles = makeStyles(() => ({
  outer: {
    position: 'absolute',
    top: '80px',
    left: '0px',
    overflowY: 'auto',
    minWidth: '100%',
    paddingLeft: '150px'
  },
  container: {
    marginTop: '40px',
    width: '90%',
    paddingBottom: '100px'
  },
  title: {
    fontSize: '30px',
    color: '#1F004A',
    padding: '0 10px'
  },
  graphTitle: {
    color: '#3C21BA'
  },
  graphContainer: {
    width: '620px',
    height: '408px',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px'
  },

  pageBreakAfter: {
    [`@media print`]: {
      'page-break-after': 'always'
    }
  }
}));

export default function BrandContent({
  project,
  isLoadingProject,
  isErrorProject,
  brandSearchesChartData,
  isBrandSearchesLoading,
  trustpilotChartData,
  isTrustPilotLoading,
  organisation
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  const cms = useCMS();

  const [filters] = useState({});

  if (isLoadingProject || isBrandSearchesLoading) return <Loading />;
  if (isErrorProject) return <Error error={isErrorProject} />;

  const company = project?.companyInfo;
  const companyForHeader = company;

  const getContentFromCms = (key) => {
    return cms && cms.data && cms?.data[key]?.content;
  };

  const templateString = cms && cms.data && cms?.data['brand-search']?.content;
  const getBrandSearchChartTemplateContent = () => {
    return fillTemplate(templateString, brandSearchesChartData?.insights);
  };

  const customerExperienceTemplateString = getContentFromCms('customer-experience');
  const getCustomerExperienceChartTemplateContent = () => {
    return fillTemplate(customerExperienceTemplateString, trustpilotChartData?.insights);
  };

  return (
    <>
      {!isBrandSearchesLoading && brandSearchesChartData && (
        <Grid item md={12} xs={12} className={classes.pageBreakAfter}>
          <ChartAndDetailContainer>
            <ChartContainer>
              <BrandSearches
                title="Brand Searches"
                project={project}
                dataFetched={project.dataFetched}
                isBrandSearchesLoading={isBrandSearchesLoading}
                brandSearchesChartData={brandSearchesChartData}
                organisation={organisation}
              />
            </ChartContainer>
            <ChartDetail score={brandSearchesChartData.score}>
              {cms &&
                cms.data &&
                cms.data['brand-search'] &&
                !isBrandSearchesLoading &&
                brandSearchesChartData?.insights?.targetName &&
                getBrandSearchChartTemplateContent()}
            </ChartDetail>
          </ChartAndDetailContainer>
        </Grid>
      )}

      {trustpilotChartData.data && (
        <Grid item md={12} xs={12} className={classes.pageBreakAfter}>
          <ChartAndDetailContainer>
            <ChartContainer>
              <TrustPilot
                title="Customer Experience"
                project={project}
                dataFetched={project.dataFetched}
                isTrustPilotLoading={isTrustPilotLoading}
                trustpilotChartData={trustpilotChartData}
                organisation={organisation}
              />
            </ChartContainer>
            <ChartDetail score={trustpilotChartData.score}>
              {cms &&
                cms.data &&
                cms.data['customer-experience'] &&
                !isTrustPilotLoading &&
                trustpilotChartData?.insights?.targetName &&
                getCustomerExperienceChartTemplateContent()}
            </ChartDetail>
          </ChartAndDetailContainer>
        </Grid>
      )}
    </>
  );
}
