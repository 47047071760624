import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { CustomSwitch } from './Switch';
import { AuthTokenContext } from './AuthenticationWrapper';
import AlertDialog from './AlertDialog';
import { TrafficNumber } from './TrafficNumber';
import Loading from './Loading';
import { useAuth } from '../hooks/use-auth';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'hidden'
  },
  block: {
    backgroundColor: '#FAFAFA',
    padding: '3px',
    margin: '3px',
    borderRadius: '10px'
  },
  trafficNumber: {
    marginRight: '20px'
  },
  title: {
    color: '#3C21BA',
    fontWeight: 600
  },
  dimmed: {
    opacity: 0.4
  }
}));

export default function CompetitorsList({
  competitors,
  setCompetitors,
  target,
  selectedCompetitorsCount,
  handleSelectedCompetitorsChange,
  maxSelected,
  handleCompetitorToggle,
  country,
  orgType
}) {
  const classes = useStyles();
  const auth = useContext(AuthTokenContext);
  const [loading, setLoading] = useState(true);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/search/${target}/${country}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        const competitorsDataFromServer = response.data?.similarSitesResult?.similar_sites || [];

        const competitorsData = competitorsDataFromServer.map((i) => {
          i.selected = false;
          return i;
        });

        // Preselect the first competitor

        if (competitorsData.length) {
          competitorsData[0].selected = true;
        }

        setCompetitors(competitorsData);
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   console.log(competitors);
  //   console.log(loading);
  //   if (!competitors.length && !loading) {
  //     const title = 'Insufficient online presence';

  //     const content = `Please check the website is correct. Prism requires the target’s website to have several thousand users a year to run.

  //   Website used: ${target}`;

  //     // Buttons: Show option to ‘Return to Search’ page only.Do not have the close button, or cancel button. (the link will take them to the logged -in homepage).

  //     history.push({
  //       pathname: window.location.pathname,
  //       search: `?modal=show&title=${title}&content=${content}&button=Return to Search&variant=search`
  //     });
  //   }
  // }, [competitors, loading]);

  if (loading) {
    return <Loading />;
  }

  if (!competitors) {
    return <div>No competitors found</div>;
  }

  return (
    <Grid container className={classes.container}>
      <AlertDialog open={showAlertDialog} handleClose={() => setShowAlertDialog(false)}>
        You can only add up to 5 competitors.
      </AlertDialog>

      {competitors.length ? (
        <>
          {competitors.map((competitor, index) => (
            <Block
              key={competitor.url + '_' + country}
              index={index}
              first={index === 0}
              competitor={competitor}
              selected={competitor.selected}
              handleSelectedCompetitorsChange={handleSelectedCompetitorsChange}
              maxSelected={maxSelected}
              selectedCompetitorsCount={selectedCompetitorsCount}
              handleCompetitorToggle={handleCompetitorToggle}
              setShowAlertDialog={setShowAlertDialog}
              country={country}
              auth={auth}
              orgType={orgType}
            />
          ))}
        </>
      ) : (
        <div>
          There are no suggested competitors for your target URL. Please check the target website is
          correct, and if so add your own competitors below.
        </div>
      )}
    </Grid>
  );
}

function Block({
  selected,
  index,
  competitor,
  selectedCompetitorsCount,
  maxSelected,
  handleCompetitorToggle,
  setShowAlertDialog,
  country,
  auth,
  orgType
}) {
  const classes = useStyles();

  const handleChange = (e, competitor) => {
    if (selectedCompetitorsCount >= maxSelected && e.target.checked) {
      setShowAlertDialog(true);
      return;
    }

    handleCompetitorToggle(e, competitor);
  };

  const ExternalLink = ({ link }) => (
    <IconButton
      href={`https://${link}`}
      target="_blank"
      variant="contained"
      color="primary"
      size="small"
      disableElevation>
      <LaunchOutlinedIcon />
    </IconButton>
  );
  return (
    <Grid container className={selected ? null : classes.dimmed}>
      <Grid container className={classes.block} spacing={3} alignItems="center">
        <Grid item md={2}>
          <a href={`https://${competitor.url}`} target="_blank" rel="noopener noreferrer">
            <img
              src={`https://icons.duckduckgo.com/ip3/${competitor.url}.ico`}
              width="32"
              height="32"
            />
          </a>
        </Grid>
        <Grid item md={5}>
          <Box className={classes.title}>{competitor.url}</Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <ExternalLink link={competitor.url} />
          </Box>
        </Grid>
        <Grid item md={1} className={classes.trafficNumber}>
          {orgType === 'Lite' && (
            <Tooltip title="Upgrade to view annual traffic">
              <LockIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/upgrade');
                }}></LockIcon>
            </Tooltip>
          )}
          {orgType != 'Lite' && (
            <TrafficNumber auth={auth} index={index} domain={competitor.url} country={country} />
          )}
        </Grid>
        <Grid item className={classes.switch} md={1}>
          <CustomSwitch
            checked={selected}
            onChange={(e) => handleChange(e, competitor)}
            value={competitor.url}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
