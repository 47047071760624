import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Search from './pages/Search';
import Results from './pages/Results';
import ProjectsList from './pages/Projects/ProjectsList';
import Container from './components/Layout/Container';
import AuthenticationWrapper from './components/AuthenticationWrapper';
import Error from './components/Error';
import ProjectRouteContainer from './pages/Projects/ProjectRouteContainer';
import Upgrade from './pages/Upgrade';
import { useState, useEffect } from 'react';
import AdvisoryHub from './pages/AdvisoryHub';

function ErrorFallback({ error }) {
  return <Error error={error} />;
}

const App = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (isMobile) {
      alert('This screen format is not yet supported. We recommend using 1280px width or above.');
    }
  }, [isMobile]);

  return (
    <AuthenticationWrapper>
      <BrowserRouter history={history}>
        <Container>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Switch>
              <Route exact path="/" render={(props) => <Search {...props} />} />
              <Route
                path="/search/:target"
                name="project_setup_screen"
                render={(props) => <Results {...props} />}
              />
              <Route
                path="/advisory-hub"
                name="advisory_hub"
                render={(props) => <AdvisoryHub {...props} />}
              />
              <Route exact path="/projects" render={(props) => <ProjectsList {...props} />} />
              <Route
                path="/projects/:id"
                render={(props) => <ProjectRouteContainer {...props} />}
              />
              <Route path="/upgrade" render={(props) => <Upgrade {...props} />} />
              <Route component={Error} />
            </Switch>
          </ErrorBoundary>
        </Container>
      </BrowserRouter>
    </AuthenticationWrapper>
  );
};

export default App;
