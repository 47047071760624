import useSWR from 'swr';
import { AuthTokenContext } from '../components/AuthenticationWrapper';
import { useContext } from 'react';

//const fetcher = (...args) => fetch(...args).then(res => res.json())
const fetcher = async (url, token) => {
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const options = {
  revalidateOnFocus: false,
  shouldRetryOnError: false
};

export function useCredits(name) {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/credits?name=${name}`, auth.accessToken],
    fetcher,
    options
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}

export function useOrganisation() {
  const auth = useContext(AuthTokenContext);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_URL}/api/organisation`, auth.accessToken],
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  };
}
